@font-face {
  font-family: "AvenirNextLTPro-Medium";
  src: url("./AvenirNextLTPro-Medium.eot");
  src: url("./AvenirNextLTPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("./AvenirNextLTPro-Medium.svg#AvenirNextLTPro-Medium") format("svg"),
    url("./AvenirNextLTPro-Medium.ttf") format("truetype"),
    url("./AvenirNextLTPro-Medium.woff") format("woff"),
    url("./AvenirNextLTPro-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

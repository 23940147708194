@import "../../../styles/variables";
@import "../../../styles/mixins";

.admin-tabs.team-tabs {
  .section-header {
    margin-top: 10px;
  }

  .avatar.avatar-team {
    background-color: transparent;
  }

  table {
    tbody {
      td {
        span {
          color: $WHITE_TEN;
          // padding: 0px 12px;
        }

        .badge {
          padding: 4px 13px;
          border-radius: 14.5px;
          font-weight: 500;
          font-size: 10px;
          display: inline-flex;
          text-transform: capitalize !important;

          &.badge-primary {
            color: #686e78;
            background: #e5e5e5;
          }

          &.badge-secondary {
            background: #d1fae5;
            color: #065f46 !important;
          }

          &.badge-info {
            background-color: rgba(14, 121, 182, 0.3);
            color: #0e79b6;
          }
        }
      }
    }
  }
}

.team-header {
  .section-header {
    padding: 12px 22px;

    @include responsive(767px) {
      padding: 15px;
    }

    a {
      padding: 10px 16px;
      background: $PRIMARY_BUTTON_BACKGROUND;
      border-radius: 4px;
      color: $WHITE !important;
      @include flexExactCenter(flex);
    }
  }
}

.team-reports {
  .section-header {
    a {
      display: none;
    }
  }
}

.modal {
  .add-team.modal-dialog {
    @include responsive(767px) {
      transform: translate(0%, 5%) !important;
    }
  }
}

.add-team {
  &.modal-dialog {
    max-width: 650px;
  }

  a {
    color: $BLUE;
  }

  .select-members {
    @include flexAlignItemCenter(flex);
    flex-wrap: wrap;

    label {
      @include ellipsis();
      width: 100px;
      margin-bottom: 0px;
    }
  }

  .avatar {
    margin-right: 9px;
  }

  input {
    height: 46px;
    margin-bottom: 16px;
  }

  .card {
    width: 32.5%;
    margin: 0px 5px 10px 0px;
    border: 1px solid $GREY_THREE;

    &:last-child {
      margin-right: 0px;
    }

    @include responsive(767px) {
      width: 100%;
      margin-right: 0px;
    }

    .avatar-label {
      font-size: 12px;
      height: auto;
      margin-bottom: 0px;
      padding: 12px 15px;
      justify-content: space-between;
      border-radius: 3px;
    }

    .btn {
      &.big-width {
        min-width: 150px;
      }
    }
  }
}

.form-header {
  margin-bottom: 8px;
  @include flexAlignCenter(flex);

  h5 {
    margin: 0px;

    span {
      font-weight: 100;
    }
  }
}

.add-member {
  background: $WHITE_THREE;
  border: 1px solid $WHITE_THREE;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: $PRIMARY_TEXT_COLOR;
}

.d-flex-team {
  max-width: 100%;
  @include flexAlignCenter(flex);

  h4 {
    margin-bottom: 0px;
  }

  a {
    color: $PRIMARY_BUTTON_BACKGROUND;
    font-size: 14px;
  }
}

.basic-info {
  label {
    font-size: 14px;
    margin-bottom: 0px;
  }

  h6 {
    font-size: 16px;
    margin-bottom: 0px;

    &.active {
      color: $GREEN_ONE;
    }
  }
}

.action {
  a {
    color: $PRIMARY_BUTTON_BACKGROUND;
  }
}

.teams-report-main {
  .section-header {
    margin-top: 32px;
  }

  table {
    margin-bottom: 0px;
  }

  .pagination-container {
    padding-top: 32px;
  }

  .avatar.avatar-team {
    background-color: transparent;
  }
}

.btn-close {
  img {
    margin: 0px;
  }
}

.for-position-multiselect {
  .no-data-found {
    height: 200px;
    font-size: 15px;
  }
}

.membersBadge {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #d4d4d4;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  margin-left: 5px;
}

@import "../../../styles/variables";
@import "../../../styles/mixins";

.widget-card {
  width: 25%;
  padding: 24px 20px;
  background-color: $WHITE;
  flex-direction: column;
  border-bottom: 1px solid $WHITE_TEN;
  border-right: 1px solid $WHITE_TEN;
  @include flexExactCenter(flex);
  min-height: 160px;

  &:first-child {
    border-left: 0px;
  }

  &:last-child {
    border-right: 0px;
  }

  @include responsive(991px) {
    width: 100%;
  }

  h2 {
    font-weight: 800;
    font-size: 25px;
    color: $PRIMARY_TEXT_COLOR;
  }

  label {
    opacity: 0.6;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $PRIMARY_TEXT_COLOR;
    text-align: center;
  }
}

.cards-flex {
  @include flexAlignItemCenter(flex);
  flex-wrap: wrap;

  .widget-card {
    width: 50%;

    @include responsive(480px) {
      width: 100%;
    }
  }

  h5 {
    @include ellipsis();
    padding: 0px 15px;
  }
}

.cards-flex-activity {
  @include flexAlignItemCenter(flex);

  @include responsive(767px) {
    flex-wrap: wrap;
  }

  h2 {
    text-align: center;
    width: 100%;

    @include ellipsis();
  }

  .widget-card {
    h2 {
      font-size: 16px;
    }
  }
}

.companyWidget {
  .contentGrid {
    width: 60% !important;
  }
}

@import "../../../styles/variables";
@import "../../../styles/mixins";

.previous-page {
  margin-bottom: 16px;
  // width: 100%;

  img {
    margin-right: 10px;
  }

  a {
    color: $PRIMARY_BUTTON_BACKGROUND;
  }
}

.edit-user-form {
  margin-top: 32px;
  border-radius: 4px;
  background-color: $WHITE;
  border: 1px solid $WHITE_TEN;

  a {
    color: $BLUE;
  }
}

.add-user-form {
  margin-top: 32px;
  border-radius: 4px;
  max-width: 100%;
  background-color: $WHITE;
  border: 1px solid $WHITE_TEN;

  a {
    color: $BLUE;
  }
}

.user-info-main {
  @include flexAlignCenter(flex);
  padding: 16px 22px;
  border-bottom: 1px solid $WHITE_TEN;

  @include responsive(767px) {
    flex-wrap: wrap;
  }
}

.user-info {
  @include flexAlignItemCenter(flex);

  @include responsive(375px) {
    margin-bottom: 20px;
  }
}

.user-basic-form {
  h5 {
    margin-bottom: 15px;
  }

  label {
    font-size: 12px;
    color: $PRIMARY_TEXT_COLOR;
    margin-bottom: 3px;
  }

  textarea {
    height: 70px;
    overflow-y: auto;
  }

  .roles {
    cursor: pointer;
    -webkit-appearance: none;
    background-image: url("../../../assets/images/select-arrow.svg");
    background-repeat: no-repeat;
    background-position: center right 10px;
  }

  .btn-secondary {
    background-color: $PRIMARY_TEXT_COLOR;
  }
}

.form-divider {
  padding: 24px 22px;
  border-bottom: 1px solid $WHITE_TEN;

  h5 {
    font-size: 16px;
    margin: 0px;
  }
}

.form-actions {
  @include flexAlignCenter(flex);
  justify-content: flex-end;

  .btn-primary {
    &:hover {
      background-color: $PRIMARY_BUTTON_BACKGROUND;
      color: $WHITE;
    }
  }
}

.for-position-multiselect {
  position: relative;
}

.role {
  span {
    font-weight: 500;
    color: $WHITE_NINE;
  }
}

.table {
  tbody {
    td.actions {
      a {
        font-weight: 500;
        font-size: 14px;
        color: $PRIMARY_BUTTON_BACKGROUND;
      }
    }
  }
}

.conditions-terminate {
  background: #f7f8f9;
  border-radius: 4px;
  div {
    align-items: center;
    color: #172b4d;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 0px 8px;
    margin-left: 7%;
    padding-top: 4%;
    padding-bottom: 2%;

    input[type="checkbox"] {
      margin-top: 3px;
    }
    h5 {
      top: calc(50% - 20px / 2);
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-left: 1%;
    }

    ul {
      margin-left: 3.9%;
      margin-top: 2%;
      font-size: 14px;
      line-height: 20px;

      li {
        span {
          color: #4eacc6;
        }
      }
    }
  }
}

.recruiter-to-terminate {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #33475b;
}

.recruiter-to-terminate-name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #33475b;
}

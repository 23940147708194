@import "../styles/variables";
@import "../styles/mixins";

.radio-delete {
  @include flexAlignItemCenter(flex);
  margin: 15px 0px;
}

.linkedin-form-container {
  .form-group {
    margin-bottom: 5px;
  }
}
.logs-tab {
  border: 1px solid $WHITE_TEN;

  .user-basic-form {
    padding: 22px;
  }

  .nav-tabs {
    border-bottom: 1px solid $WHITE_TEN;
    padding: 0px 21px 0px 21px;
    background: $WHITE;

    @include responsive(360px) {
      padding: 0px 15px;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1408px) {
      padding: 0px;
    }

    .nav-link {
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      color: $WHITE_NINE;
      border: 0px;
      border-bottom: 4px solid transparent;
      margin-right: 24px;
      padding: 20px 15px;

      &:last-child {
        margin-right: 0px;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1408px) {
        font-size: 14px;
      }

      @include responsive(360px) {
        margin-right: 10px;
        padding: 10px 5px;
      }

      &:hover {
        border-color: transparent;
      }

      &.active {
        color: $PRIMARY_BUTTON_BACKGROUND;
        border-bottom: 4px solid$PRIMARY_BUTTON_BACKGROUND;
      }
    }
  }

  .notes-flex {
    @include flexAlignCenter(flex);
    border-bottom: 1px solid $WHITE_TEN;
    margin-bottom: 12px;
    padding-bottom: 12px;

    &:last-child {
      border-bottom: 0px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}

.heading-flex {
  @include flexAlignCenter(flex);
  margin-bottom: 32px;

  @include responsive(375px) {
    flex-direction: column;
  }

  .btn-secondary {
    background-color: $PRIMARY_TEXT_COLOR;
  }

  h5 {
    margin: 0px;
    font-size: 16px;

    @include responsive(375px) {
      margin-bottom: 15px;
    }
  }
}

.clamp-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.agreement-request-wrap{
  font-weight: 400;
  & > span{
    color: #295bb1;
    font-weight: 400;
  }
}

.box-shadow{
  box-shadow: $BOX_SHADOW;
}

.billing-tabs {
  @include responsiveMobile(600px) {
    justify-content: center;
  }
}
@import "../../../styles/variables";
@import "../../../styles/mixins";

.multiselect {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
  width: calc(100% - 30px);
  border: 1px solid #f7f8f9;
  border-radius: 4px;
  background: $WHITE;
  padding: 8px 0px 16px;
  position: absolute;
  z-index: 99;
  height: 250px;
  overflow-y: auto;
  display: none;

  &.toggle {
    display: block;
  }
}

.checkbox-label {
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 23px;
  color: $BLUE_NINE;
  label{
    width: 100% !important;
  }
  // ss
  @include flexAlignItemCenter(flex);

  &:hover {
    // background: $WHITE_ALPHA;
  }

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
    width: 100%;

    &:before {
      content: "";
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid $GRAY_SEVEN;
      box-shadow: 0 1px 2px $BOX_SHADOW_ONE,
        inset 0px -15px 10px -12px $BOX_SHADOW_ONE;
      padding: 7px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 14px;
    }
  }

  input:checked + label {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 7px;
      width: 5px;
      height: 12px;
      border: solid $WHITE;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &:before {
      background-color: $GREEN_ONE;
      border: 2px solid $GREEN_ONE;
    }
  }
}

.is-checked {
  background: $WHITE_ALPHA;
}

.multiselect-actions {
  text-align: right;
  border-top: 1px solid $WHITE_TEN;
  margin-top: 14px;
  padding: 16px 16px 0px;

  .btn.btn-primary {
    background: $PRIMARY_TEXT_COLOR;
    border: 1px solid $PRIMARY_TEXT_COLOR;
    min-width: 100px;
    font-size: 12px;
    height: 40px;

    &:hover {
      color: $WHITE;
    }
  }
}

.btn.btn-primary {
  &:disabled {
    box-shadow: none !important;
  }
}

.candidate-name {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #2e95b4;
}

.at-the-rate {
  color: #96a1ac;
}

.resume-download-coloring {
  color: #2e95b4;
  cursor: pointer;
}

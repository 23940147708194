@import "../../styles/variables";
@import "../../styles/mixins";
.search-filter {
  @include flexAlignCenter(flex);
  flex-wrap: wrap;
  gap: 20px;
  .input-group {
    
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #d1d5db;

    @include responsive(767px) {
      margin-bottom: 15px;
    }

    input {
      background-color: transparent;
      padding-left: 0px;
      color: #6b7280;
      border: 0px;

      &::placeholder {
        color: #6b7280;
      }

      &:focus {
        outline: none;
        border-color: none;
        box-shadow: none;
      }
    }

    @include responsive(767px) {
      max-width: 100%;
    }

    .input-group-text {
      background: transparent;
      border: 0px;
    }

    .input-group-prepend {
      margin: 0px;
    }
  }

  .search-by-company {
    font-size: 16px;
    border: 0px;
    font-weight: 400;
    font-size: 16px;
    color: #6b7280;

    &:focus {
      box-shadow: none;
    }
  }
}

.searchFilterTable {
  img {
    width: 13px;
  }

  .searchFieldWidth {
    width: 200px;
  }

  .input-group {
    width: 200px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #d1d5db;

    &:hover {
      box-shadow: none;
    }

    input {
      background-color: transparent;
      padding-left: 0px;
      color: #6b7280;
      font-size: 12px;

      &::placeholder {
        color: #6b7280;
      }
    }

    @include responsive(767px) {
      max-width: 200px;
      width: 200px;
    }

    .input-group-text {
      background: transparent;
      border: 0px;
    }

    .input-group-prepend {
      margin: 0px;
    }
  }

  .search-by-company {
    font-size: 16px;
    border: 0px;
    font-weight: 400;
    font-size: 16px;
    color: #6b7280;

    &:focus {
      box-shadow: none;
    }
  }
}

.filter-status {
  @include flexAlignItemCenter(flex);

  @include responsive(767px) {
    // margin-top: 24px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  select {
    padding-right: 30px;
  }
}

.filter-by {
  @include flexAlignCenter(flex);
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  @include responsive(767px) {
    width: 100%;
    flex-wrap: wrap;
  }
  .ml-3 {
    margin-left: 0 !important;
  }
  .form-group {
    margin: 0px;
  }
  label {
    font-weight: 500;
    color: $PRIMARY_TEXT_COLOR;
    margin: 0 1rem 0 0;
    font-size: 13px;

    @include responsive(767px) {
      margin: 0 10px 0 0;
      font-size: 13px;
    }
  }
}

.btn-secondary.add-new-domain {
  background-color: $PRIMARY_TEXT_COLOR;
  color: $WHITE;
}

.create-company {
  .card {
    border-radius: 0px;
  }
  .card-body {
    border-bottom: 1px solid $WHITE_TEN;
  }
}

.advertised-location {
  .card {
    border-radius: 0px;
    margin-top: 10px;
  }
  .card-body {
    border-bottom: 1px solid $WHITE_TEN;
  }
}

.admin-tabs.team-tabs {
  .activity-card {
    margin-bottom: 24px;
    // border: 1px solid $WHITE_TEN;
    .section-header {
      margin-top: 0px;
      border-bottom: 1px solid $WHITE_TEN;
      padding: 24px 22px;
    }
    .card {
      border-radius: 0px;
      border: 0px;
    }
  }
}

.notes-flex {
  @include flexAlignCenter(flex);
  .added-by {
    color: $WHITE_NINE;
  }
}

.notes-address {
  @include flexAlignCenter(flex);
  flex-wrap: wrap;
  h6 {
    font-weight: 500;
    font-size: 14px;
    color: $PRIMARY_TEXT_COLOR;
    margin: 0px;
    &:last-child {
      @include responsive(414px) {
        margin-bottom: 15px;
      }
    }
  }
  .address-grid {
    width: 300px;
    padding-right: 10px;
    @include responsive(767px) {
      width: 100%;
    }
  }
}

.address-card {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    border: 1px solid #308d3f;
    // cursor: pointer;
  }
  &.active {
    border: 1px solid #308d3f;
  }
}

.card-inner-padding {
  padding: 22px;
}

.address-action {
  @include flexAlignItemCenter(flex);
  justify-content: flex-end;
  padding: 0px 22px 22px;
}

.filter-select {
  display: flex;
  align-items: center;
  gap: 10px;
  @media only screen and (min-width: 768px) and (max-width: 1408px) {
    margin-top: 20px;
  }
  @include responsive(767px) {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
  }
  select {
    padding-right: 30px;
  }
  .form-group {
    margin: 0px;
    @include responsive(767px) {
      margin: 15px 0px 0px;
      width: 100%;
    }
  }
  .form-group-heading {
    display: none;
  }
  button {
    @include responsive(767px) {
      margin: 15px 0px 0px !important;
    }
  }
}

.sharee-card-main {
  padding: 22px;
  position: relative;

  .share-owner-cards {
    @include flexAlignCenter(flex);
    flex-wrap: wrap;
  }

  .sharee-card {
    margin-bottom: 20px;
    width: 32%;

    @media only screen and (max-width: 1440px) {
      width: 100%;
    }
    .card-body {
      padding: 15px !important;
    }
    h6 {
      color: $PRIMARY_TEXT_COLOR;
      margin-bottom: 8px;
      @include ellipsis();
      @include responsive(767px) {
        width: 100px;
      }
    }
    label {
      font-weight: 500;
      font-size: 14px;
      color: $PRIMARY_TEXT_COLOR;
      text-transform: capitalize;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      color: $WHITE_NINE;
      margin: 0px;
    }

    .btn-delete {
      background: transparent !important;
      border: 0px !important;
      border: 0px;
      position: absolute;
      right: 0px;
      top: 0px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 10px;

      img {
        margin: 0px;
        width: 25px;
        height: 25px;
      }
    }
  }
}

.add-another {
  padding-top: 24px;
  .btn-reset {
    background: $WHITE_THREE;
    border: 1px solid $WHITE_THREE;
    font-size: 12px;
    color: $PRIMARY_TEXT_COLOR;
  }
}

.form-group-error {
  .form-group {
    margin-bottom: 0px;
  }
}

.for-modal-styling {
  margin: 0px auto !important;
  .ownership-content {
    p {
      font-size: 14px;
      color: $PRIMARY_TEXT_COLOR;
      margin-bottom: 16px;
    }

    ul {
      padding-left: 1rem;
      li {
        font-size: 14px;
        margin-bottom: 16px;
        color: $PRIMARY_TEXT_COLOR;
      }
    }
  }

  .modal-header {
    padding: 18px ​15px;
  }
}

.remove-sharee {
  p {
    font-size: 14px;
    color: $PRIMARY_TEXT_COLOR;
  }
  .modal-footer {
    .btn-Danger {
      background: $RED_THREE;
      border: $RED_THREE;
      color: $WHITE;
      padding: 5px 14px !important;
    }
  }
}

.edit-page {
  color: $PRIMARY_BUTTON_BACKGROUND;
  &:hover {
    color: $PRIMARY_BUTTON_BACKGROUND;
  }
  img {
    margin-right: 12px;
  }
}

.text-field-value {
  background: $WHITE;
  border-radius: 4px;
  padding: 11px 0px;
  ol,
  ul {
    padding-left: 20px;
  }
}

.agreements-notes {
  @include flexAlignCenter(flex);
  @include responsive(360px) {
    flex-direction: column;
    align-items: baseline;
  }
  h6 {
    font-weight: 800;
    font-size: 14px;
    color: $PRIMARY_TEXT_COLOR;
    margin: 0px;
  }
  label {
    font-size: 14px;
    color: $WHITE_NINE;
    margin: 0px;
    span {
      color: $PRIMARY_TEXT_COLOR;
    }
  }
  p {
    color: $WHITE_NINE;
    margin: 0px;
    font-size: 14px;
  }
}

.flex-text-right {
  flex: 1;
  text-align: right;
}

.industry-select-input {
  cursor: pointer;
  background-image: url("../../assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position: center right 10px;
}

.industry-badge {
  @include flexAlignItemCenter(flex);
  flex-wrap: wrap;
  .badge-secondary {
    background: $WHITE_THREE;
    border: 1px solid $WHITE_NINE;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    color: $PRIMARY_TEXT_COLOR;
    padding: 5px 10px;
  }
}

.basic-multi-select {
  margin-bottom: 15px;
}

.company-link {
  padding: 0px 10px;
  height: 55px;
  @include flexAlignItemCenter(flex);
}

.is-primary-label {
  @include flexExactCenter(flex);
  padding-left: 40px !important;

  @include responsive(767px) {
    padding-left: 15px !important;
  }
}

.create-new-company {
  .form-group {
    margin-bottom: 0px;
  }
}

.job-orders-tbody {
  p {
    margin: 0px;
  }

  .form-check-input {
    position: initial;
    margin: 0px;
  }

  .text-info {
    margin-left: 10px;
  }
}

.userAvatarProfile {
  width: 28px;
  height: 28px;
  background: #c4c4c4;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

.userAvatarProfileRelative {
  position: relative;
  right: 8px;
}

.userAvatarNew {
  width: 28px;
  height: 28px;
  background: #dce9ee;
  border: 1px dashed #006c8c;
  border-radius: 50%;
  position: relative;
  right: 15px;
}

.addCompanyUrl {
  .is-primary-label {
    @include responsive(414px) {
      align-items: flex-start;
    }
  }
}

.searchFilterPadding {
  padding: 24px;
  justify-content: initial;

  .input-group {
    margin-right: 20px;
  }
}

.revampDialogAddress {
  h5 {
    margin-bottom: 32px;
  }

  .closeButton {
    position: absolute;
    top: 30px;
    right: 20px;
  }
}

.contactEmailsCard {
  .card-body {
    display: flex;
    align-items: center;
    min-height: 62px;
    justify-content: space-between;
  }

  .agreements-notes {
    width: 100%;
  }
}

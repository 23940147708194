@import "../../../styles/variables";
@import "../../../styles/mixins";

.no-data-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  // border-radius: 5px;
  flex-direction: column;
  background-color: $WHITE;
  font-size: 15px;

  img {
    width: 40px;
    margin-bottom: 15px;
  }
}

.no-data-found-interseller-company {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  flex-direction: column;
  background-color: $WHITE;
  font-size: 15px;

  img {
    width: 40px;
    margin-bottom: 15px;
  }
}

.company-no-found {
  margin: 5px;

  img {
    margin-bottom: 12px;
    cursor: pointer;
    padding-right: 5px;
    float: right;
  }
}

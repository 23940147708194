.formula {
  color: #308d3f;
}

.readonly-rate {
  height: 46px;
  background-color: #f7f8f9;
  border: 1px solid #e5e9f8;

  label {
    padding-top: 12px;
  }
}

.recruiter-profit {
  background: #f7f8f9;
  border: 1px solid #e5e9f8;
  box-sizing: border-box;
  padding-top: 12px;
  width: 170px;
  height: 46px;
}

@import "../../../styles/variables";
@import "../../../styles/mixins";
.contacts-card-flex {
  @include flexAlignItemCenter(flex);
  flex-wrap: wrap;
  .contact-card {
    padding: 26px 15px;
    border-right: 1px solid $WHITE_TEN;
    text-align: center;
    width: 14.28%;

    &:hover{
      box-shadow: 2px 3px 8px 8px rgba(#0000, 0.1);
      cursor: pointer;
        }

     &.active{
      border: 1px solid #e6a21c;;
      cursor: default;
      background-color: #f3f3f3;

      
     }
    @include responsive(767px) {
      width: 100%;
      border-right: 0px;
      border-bottom: 1px solid $WHITE_TEN;
    }
    @include responsive(992px) {
      width: 50%;
      border-bottom: 1px solid $WHITE_TEN;
    }
    h5 {
      font-weight: 800;
      font-size: 16px;
      color: $PRIMARY_TEXT_COLOR;
      margin-bottom: 4px;
     
    }
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: $PRIMARY_TEXT_COLOR;
      opacity: 0.6;
      &:hover{
        cursor: pointer;
          }
          &.active{
            color: #e6a21c;
            cursor: default;
            background-color: #f3f3f3;
           }
    }
  }
}

.contacts-wrapper {
  margin-bottom: 22px;
  background: $WHITE;
  border: 1px solid $WHITE_TEN;
  border-radius: 4px;
}

.no-contact-added {
  width: 100%;
  height: 418px;
  @include flexExactCenter(flex);
  flex-direction: column;
  background: $WHITE;
  border: 1px solid $WHITE_TEN;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 25px 15px;
  img {
    margin-bottom: 40px;
  }
  h5 {
    font-weight: bold;
    margin-bottom: 8px;
  }
  p {
    font-weight: 800;
    font-size: 16px;
    color: $WHITE_NINE;
    margin-bottom: 64px;
    text-align: center;
  }
  button.btn-primary {
    margin-right: 16px;
    @include responsive(767px) {
      margin: 0px auto;
      margin-bottom: 15px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}

.gray-button.btn-primary {
  min-width: 140px;
  min-height: 35px;
  background: $WHITE_THREE;
  border: 1px solid $WHITE_THREE;
  border-radius: 5px;
  font-size: 14px;
  color: $PRIMARY_TEXT_COLOR;
}

.graydark-button.btn-primary {
  background-color:#E6E9F7;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: #696E77;
}

.contacts-actions {
  display: flex;
  align-items: center;
  justify-content: center;

  @include responsive(1024px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  h5 {
    margin-right: 10px;
    margin-top: 12px;
  }
}

.contacts-sequence-tab {
  .user-basic-form {
    padding: 22px;
  }
  .tab-pane {
    padding-top: 32px;
  }
  .nav-tabs {
    border-bottom: 1px solid $WHITE_TEN;
    padding: 0px;
    background: transparent;
    box-shadow: none;
    @include responsive(360px) {
      padding: 0px 15px;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1408px) {
      padding: 0px;
    }
    .nav-link {
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      color: $WHITE_NINE;
      border: 0px;
      border-bottom: 4px solid transparent;
      margin-right: 24px;
      padding: 20px 15px;
      &:last-child {
        margin-right: 0px;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1408px) {
        font-size: 14px;
      }
      @include responsive(360px) {
        margin-right: 10px;
        padding: 10px 5px;
      }
      &:hover {
        border-color: transparent;
      }
      &.active {
        color: $PRIMARY_BUTTON_BACKGROUND;
        border-bottom: 4px solid$PRIMARY_BUTTON_BACKGROUND;
        background-color: transparent;
      }
    }
  }
  .notes-flex {
    @include flexAlignCenter(flex);
    border-bottom: 1px solid $WHITE_TEN;
    margin-bottom: 12px;
    padding-bottom: 12px;
    &:last-child {
      border-bottom: 0px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}

.contact-dialog-header {
  padding: 20px;
  border-bottom: 1px solid #f0f1f2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  h5 {
    font-weight: 500;
    font-size: 20px;
    color: #172b4d;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    color: #2e95b4;
    margin-bottom: 8px;
  }
}

.contact-info {
  @include flexAlignCenter(flex);
}

.sb-avatar__image,
.sb-avatar__text {
  width: 90px !important;
  height: 90px !important;
}

.contact-avatar {
  width: 90px;
  height: 90px;
  margin-right: 24px;
  border-radius: 50%;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.contact-dialog-body {
  padding: 32px 20px;
  .steps-pipeline {
    @include flexAlignItemCenter(flex);
  }
  .steps-badge {
    width: 36px;
    height: 36px;
    background: #f7f8f9;
    border-radius: 4px;
    font-size: 16px;
    color: #33475b;
    @include flexExactCenter(flex);
    position: relative;
    margin-right: 16px;
    &::after {
      width: 16px;
      height: 4px;
      display: block;
      background: #f7f8f9;
      content: "";
      position: absolute;
      left: 36px;
    }
    &:last-child {
      margin-right: 0px;
      &::after {
        display: none;
      }
    }
  }
}

.dateRangeButton {
  button {
    @include responsive(767px) {
      width: 100%;
    }
  }
}

.date-range-today-contacts {
  position: absolute;
  padding: 15px;
  max-width: 520px;
  width: 100%;
  left: initial;
  top: 142px;
  right: 32px;
  background: #ffffff;
  box-shadow: $BOX_SHADOW;
  border-radius: 4px;
  z-index: 999;

  @include responsive(599px) {
    max-width: 90%;
    right: 15px;
    top: 34%;
  }

  .form-control {
    width: 100%;
  }
  & img {
    padding-top: 25px;
  }
  button {
    color: white;
  }
}

.button-close {
  border: 1px solid #eaa827;
  border-radius: 4px;
  min-width: 111px !important;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.empty-preview-steps {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  min-height: 50vh;
  box-shadow: none;
  padding: 2rem;
  margin: 2rem;
  h5 {
    color: rgb(36, 62, 109);
    font: 600 22px soleil, sans-serif;
    text-align: left;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-top: 1.5rem;
  }
  p {
    color: rgb(122, 133, 159);
    font: 300 15px soleil, sans-serif;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-top: 0.5rem;
    text-align: center;
  }
}

.update-contact-sequence {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #172b4d;
  border-bottom: 1px solid #f0f1f2;
  height: 45px;
}

.edit-contact-manually-footer {
  display: flex;
  float: right;
}

.danger-steps-counts {
  @include flexExactCenter(flex);
  width: 40px;
  height: 36px;
  background: #f7f8f9;
  border-radius: 4px;
  font-size: 16px;
  color: #33475b;
  position: relative;
  margin-right: 16px;
  &:first-child {
    width: 18px;
    color: white;
    background-color: red;
  }
  &:last-child {
    width: 18px;
    color: white;
    background-color: #33475b;
  }
}

.csv-index-h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #33475b;
}

.file-upload-csv-index {
  cursor: pointer;
  width: 100%;
  background: #f7fdff;
  border: 1px dashed #2e95b4;
  border-radius: 4px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    text-align: center;
  }
}

.file-name-csv-index {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.file-name-csv-index.text-truncate {
  line-height: 28px;
  max-width: 78px;
}

.textTruncateWidth {
  max-width: 200px;
}
.textTruncateWidthFileName {
  width: 30em;
}

.textTruncateName {
  width: 150px;
}

.textTruncateRecruiter {
  min-width: 60px;
  max-width: 200px;
}

.textTruncateWidthEllipsis {
  @include ellipsis();
}

.span-csv-index {
  font-weight: 500;
  font-size: 14px;
  border-radius: 20px;
  width: 94px;
  height: 29px;
  background: #f7f8f9;
  color: #96a1ac;
  margin-left: 10px;
  @include flexExactCenter(flex);
}

.span-csv-link {
  font-weight: 400;
  font-size: 12px;
  border-radius: 20px;
  width: 150px;
  height: 29px;
  background: #eaa827;
  margin-left: 10px;
  cursor: pointer;
  @include flexExactCenter(flex);
  a {
    color: #f7f8f9;
  }
  a:hover {
    color: #f7f8f9;
  }
}

.step2-style-index-csv {
  background: #ffffff;
  border-radius: 4px;
  max-height: 429px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: 2px;
  margin-right: 2px;
  padding-right: 2px;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  .row {
    border-bottom: 1px solid #f0f1f2;
  }
  div {
    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #33475b;
      margin: 0px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #33475b;
      margin: 0px;
    }
  }
}

.step2-header {
  height: 44px;
  background: #f0f1f2;
  @include flexExactCenter(flex);
  h5 {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #33475b;
  }
}

.import-started-csv {
  width: 100%;
  height: 232px;
  background: #eaffed;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  h5 {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 33px;
    color: #308d3f;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #33475b;
  }
}

.section-contact-add {
  .form-group {
    margin: 0px;
  }
  .form-group-heading {
    display: none;
  }
  .css-2b097c-container {
    width: 200px;
  }
}

.step-email-missing {
  h5 {
    width: 100%;
    color: rgb(36, 62, 109);
    font: 600 15px soleil, sans-serif;
    text-align: left;
    padding-top: 0px;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  p {
    font-size: 14px;
    line-height: 21px;
    margin-top: 10px;
    color: #33475b;
  }
}

.contacts-mobile.align-items-center {
  @include responsive(1024px) {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
  }
  .contacts-actions {
    @include responsive(1024px) {
      margin: 15px 0px;
      align-items: center !important;
      justify-content: flex-start;
    }
  }
}

.contacts-actions {
  .gray-button.btn-primary {
    @include responsive(1024px) {
      min-width: 135px;
      margin-bottom: 10px;
    }
  }
}

.import-csv-contacts {
  max-width: 43%;
}

.spinner-center {
  @include flexExactCenter(flex);
  height: 20px;
}

.multiselect-company-add {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
  width: calc(100% - 30px);
  border: 1px solid #f7f8f9;
  border-radius: 4px;
  background: #ffffff;
  padding: 16px 0px 16px;
  position: absolute;
  z-index: 99;
  height: auto;
  max-height: 244px;
  overflow-y: auto;
  display: none;
  &.toggle {
    display: block;
  }
}

.multiselect-company-add-lookups {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
  border: 1px solid #f7f8f9;
  border-radius: 4px;
  background: #ffffff;
  padding: 16px 0px 16px;
  z-index: 99;
  height: auto;
  max-height: 244px;
  overflow-y: auto;
  display: none;
  &.toggle {
    display: block;
  }
}

.multiselect-company-add-actions {
  text-align: right;
  border-top: 1px solid $WHITE_TEN;
  margin-top: 14px;
  padding: 16px 16px 0px;

  .btn.btn-primary {
    background: $PRIMARY_TEXT_COLOR;
    border: 1px solid $PRIMARY_TEXT_COLOR;
    min-width: 100px;
    font-size: 12px;
    height: 40px;
    &:hover {
      color: $WHITE;
    }
  }
}

.status-contact-badge {
  cursor: pointer;
  .errors {
    font-weight: 900;
    color: #e4542b;
  }
  .error-clearing {
    border: 1px solid cornflowerblue;
    padding-top: 2px;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 24px;
  }
}

.company {
  font-weight: bolder;
}

.contactsAsynDropdown {
  margin-right: 20px;
  width: 15rem;

  @include responsive(767px) {
    width: 100%;
    margin: 20px 0px 0px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1099px) {
    width: 20rem;
  }
}

.filterContacts {
  margin: 0px !important;
}

@import '../../styles/variables';
@import '../../styles/mixins';

.heading-border {
  flex-wrap: wrap !important;
}

.candidate-action {
  @include flexAlignCenter(flex);
  border-bottom: 1px solid $WHITE_TEN;
  padding: 0px 0px 22px 0px;

  @include responsive(767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  button {
    @include responsive(767px) {
      margin-top: 15px;
    }
  }

  label {
    font-size: 14px;
    color: $PRIMARY_TEXT_COLOR;
    margin-bottom: 13px;
  }

  h6 {
    font-size: 16px;
    color: $PRIMARY_TEXT_COLOR;
    margin: 0px;
  }

  .candidate-avatar {
    margin-right: 24px;
    width: 61px;
    height: 61px;
    border-radius: 4px;

    @include responsive(599px) {
      margin-bottom: 15px;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.candidate-notes-wrapper {
  padding: 8px 16px;

  .candidate-notes {
    border-bottom: 1px solid $WHITE_TEN;
    padding-bottom: 12px;
    margin-bottom: 12px;
    word-break: break-all;

    &:last-child {
      margin-bottom: 0px;
    }

    h5 {
      font-weight: bold;
      font-size: 14px;
      color: $PRIMARY_TEXT_COLOR;
      margin: 0px;
    }

    p {
      font-size: 16px;
      color: $PRIMARY_TEXT_COLOR;
      margin-bottom: 8px;
    }

    h6 {
      @include flexAlignItemCenter(flex);
      font-weight: 500;
      font-size: 14px;
      color: $WHITE_NINE;
      margin: 0px;

      img {
        margin: 0px 8px;
      }
    }
  }
}

.actions-menu {
  .btn {
    font-size: 16px;
    color: $PRIMARY_BUTTON_BACKGROUND;

    &:hover {
      color: $PRIMARY_BUTTON_BACKGROUND;
    }

    &::after {
      position: relative;
      top: 2px;
      left: 5px;
    }
  }

  .btn-secondary:not(:disabled):not(.disabled).active,
  .btn-secondary:not(:disabled):not(.disabled):active,
  .show > .btn-secondary.dropdown-toggle {
    color: $PRIMARY_BUTTON_BACKGROUND !important;
  }

  .btn-secondary.focus,
  .btn-secondary:focus {
    color: $PRIMARY_BUTTON_BACKGROUND !important;
  }

  .dropdown-menu {
    left: auto !important;
    right: -35px !important;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.25));
    border: 0px;

    .dropdown-item {
      font-size: 14px;
      color: $BLUE_NINE;
      padding: 4px 8px;

      &:focus,
      &:hover {
        box-shadow: none;
        outline: none;
        background: $WHITE_ALPHA;
      }
    }
  }
}

.icon-text-wrap {
  display: flex;
  align-items: flex-start;

  & > img {
    position: relative;
    top: 2px;
  }
}

.btn.edit-candidate {
  background: $PRIMARY_BUTTON_BACKGROUND;
  border: 1px solid $PRIMARY_BUTTON_BACKGROUND;
  position: fixed;
  top: 52px;
  right: 0px;
  border-radius: 8px 0px 0px 8px;
  width: 35px;
  height: 40px;
  padding: 5px 2px 5px 8px;
  z-index: 999;

  &:focus,
  &:hover {
    background: $PRIMARY_BUTTON_BACKGROUND;
    border: 1px solid $PRIMARY_BUTTON_BACKGROUND;
  }
}

.admin-tabs.team-tabs {
  .section-header {
    flex-wrap: wrap;

    @include responsive(599px) {
      justify-content: flex-start;
      display: block;
    }

    button {
      @include responsive(767px) {
        margin-top: 15px;
      }
    }
  }

  .activity-card {
    .section-header.candidate-header {
      padding: 15px 22px;

      @include responsive(767px) {
        padding: 15px;
      }
    }
  }

  .card-body {
    padding: 12px;
  }
}

.resume-details {
  // width: 70%;

  @include responsive(1024px) {
    width: 60%;
  }

  @include responsive(767px) {
    margin-bottom: 15px;
    width: 100%;
  }

  h6 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include responsive(1024px) {
      margin-bottom: 15px !important;
    }
  }
}

.candidate-drawer-resume-grid {
  @include flexAlignCenter(flex);
  background: $WHITE;
  border: 1px solid $WHITE_TEN;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
  flex-wrap: wrap;

  &.active {
    border: 1px solid $GREEN_ONE;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.candidate-resume-grid {
  @include flexAlignCenter(flex);
  flex-wrap: wrap;
  background: $WHITE;
  border: 1px solid $WHITE_TEN;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;

  &.active {
    border: 1px solid $GREEN_ONE;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  h5,
  h6 {
    font-weight: 500;
    font-size: 14px;
    color: $PRIMARY_TEXT_COLOR;
  }

  h6 {
    margin-bottom: 5px;

    img {
      margin: 0px 8px;
    }

    span {
      color: $WHITE_NINE;
    }
  }

  .btn-view {
    font-size: 12px;
    color: $PRIMARY_TEXT_COLOR;
    min-width: 50px;
    background: $WHITE_THREE;
    border-radius: 4px;
    border: 1px solid $WHITE_THREE;
  }
}

.badgeButtonGrid {
  @include responsive(767px) {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  button {
    @include responsive(767px) {
      margin: 15px 0px 0px 0px !important;
    }
  }
}

.application-status {
  width: 100%;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid $WHITE_TEN;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include responsive(767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  label {
    font-weight: 800;
    font-size: 14px;
    color: $PRIMARY_TEXT_COLOR;
    margin-bottom: 8px;
  }

  h5 {
    font-weight: normal;
    font-size: 16px;
    color: $PRIMARY_TEXT_COLOR;
    margin-bottom: 8px;
  }

  h6 {
    @include flexAlignItemCenter(flex);
    font-weight: 500;
    font-size: 14px;
    color: $WHITE_NINE;

    img {
      margin: 0px 8px;
    }
  }

  h4 {
    font-weight: normal;
    font-size: 14px;
    color: $GREEN_ONE;

    @include responsive(767px) {
      margin: 15px 0px 0px;
    }

    img {
      margin-right: 8px;
    }
  }
}

.read-more-link {
  width: 100%;
  bottom: 0px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  // padding: 12px;
  padding-left: 0px;
  border: none;
  // height: 48px;
  color: $PRIMARY_BUTTON_BACKGROUND;
  position: absolute;
  font-size: 14px;
}

.cand-status-column {
  padding-left: 5px !important;
}

.own-controller-flex {
  display: flex;
  align-items: center;

  span {
    cursor: pointer;
    color: $PRIMARY_BUTTON_BACKGROUND;
  }
}

.resume-preview.btn-secondary {
  background-color: #e9e9e9;
}

.subtitleText {
  font-weight: 400;
  font-size: 11px;
  color: #686e78;
}

.subtitleTextWork {
  font-size: 10px;
  padding-left: 10px;
  text-transform: capitalize;
}

#686E78 .userBadgeInfo {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;

  @include responsive(767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  h5 {
    margin: 0px 16px 0px 0px;
  }

  label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 7px;
    color: #686e78;
    margin-bottom: 3px;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    color: #9ba1a8;
    margin: 0px;
  }
}

.userBadge {
  font-weight: 500;
  font-size: 20px;
  color: #006c8c;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 17px;
  background: rgba(0, 108, 140, 0.1);
  border: 1px solid rgba(0, 108, 140, 0.4);

  @include responsive(767px) {
    margin-bottom: 15px;
  }
}

.actionsDropdownSpacing {
  @include responsive(767px) {
    margin-bottom: 15px;
  }
}

.actionsDropdown {
  @include responsive(767px) {
    margin-top: 20px;
  }

  .btn {
    width: 102px;
    height: 33px;
    font-weight: 500;
    font-size: 14px;
    color: #424242;
    background-color: #ffffff;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    padding: 0px;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      color: initial !important;
      border-color: initial !important;
      background-color: transparent !important;
    }
  }

  .dropdown-toggle::after {
    margin-left: 12px;
    border: 0px;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../assets/images/dropdown-arrow-new.svg');
  }
}

.candidateHeader {
  margin-bottom: 32px;
  @include flexAlignCenter(flex);

  @include responsive(767px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

// tabs

.CandidateTabsWrapper {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;

  .relocate-allowed {
    .form-check {
      @include responsive(767px) {
        width: 100%;
      }
    }
  }

  .heading-border {
    @include responsive(1024px) {
      padding: 0px;
    }
  }

  .nav-tabs {
    border-bottom: 1px solid #e5e7eb;
    padding: 0px 43px;

    @include responsive(1199px) {
      padding: 0px 15px;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      border: 0;
      max-width: 90%;
    }

    a {
      font-weight: 500;
      font-size: 14px;
      color: #6b7280;
      padding: 15px 0px;
      margin-right: 40px;
      border-bottom: 2px solid transparent;

      @include responsive(1199px) {
        margin-right: 15px;
        white-space: nowrap;
      }

      &:hover {
        border-color: transparent;
      }

      &.active,
      &:hover {
        color: #1f2937;
        border-color: transparent;
        border-bottom: 2px solid #1f2937;
      }
    }
  }

  .tab-pane-padding {
    padding: 39px 43px;

    @include responsive(1024px) {
      padding: 23px 15px;
    }
  }

  &.CandidateSidebarTabs {
    .nav-tabs a {
      font-weight: 500;
      font-size: 12px;
      color: #6b7280;
      padding: 15px 0px;
      margin: 0 10px !important;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.CandidateTabsWrapper {
  h5 {
    font-weight: bold;
    font-size: 12px;
    color: #272727;
    // margin-bottom: 24px;
  }

  label {
    font-weight: 400;
    font-size: 12px;
    color: #686e78;
    margin: 0;
  }

  h6 {
    font-weight: 500;
    font-size: 12px;
    color: #424242;
    margin: 0px;
  }

  a {
    font-weight: 500;
    font-size: 12px;
    color: #0e79b6;
  }

  .dividerDot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0px 10px;
    background: #0e79b6;
  }

  .contentGrid {
    width: 70%;

    @include responsive(1279px) {
      width: 65% !important;
    }

    @include responsive(767px) {
      width: 100% !important;
    }
  }
}

.contentGrid {
  width: 70%;
  word-break: break-all;

  @include responsive(1279px) {
    width: 65% !important;
  }

  @include responsive(767px) {
    width: 100% !important;
  }
}

.summarySection {
  margin: 30px 0px;

  p {
    font-weight: 400;
    font-size: 12px;
    color: #686e78;
    margin-bottom: 0px;
  }

  a,
  button {
    font-weight: 500;
    font-size: 12px;
    color: #0e79b6;

    img {
      margin-left: 6px;
    }
  }

  button {
    font-weight: 500;
    font-size: 12px;
    color: #0e79b6;

    img {
      margin-left: 6px;
    }
  }
}

.skillsSection {
  margin-bottom: 25px;
}

.socialMedia {
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #f0f1f2;
    border-radius: 2px;
    padding: 5px;
    margin-right: 16px;

    &:hover {
      border: 1px solid #f9b220;
    }
  }
}

.folderSection {
  button {
    font-weight: 500;
    font-size: 14px;
    color: #0e79b6;

    img {
      margin-right: 8px;
    }
  }
}

.idealEmployment {
  margin-top: 50px;

  @include responsive(767px) {
    margin-top: 29px;
  }
}

.resumeHeader {
  padding: 24px 43px;
  background-color: #fff;

  @include responsive(1024px) {
    padding: 20px 16px;
  }
}

.resumeHolder {
  padding: 0px 43px 35px;

  @include responsive(1024px) {
    padding: 20px 16px;
  }
}

.iconButton {
  background-color: transparent;
  border: 0px;
  box-shadow: none;
}

.buttonGenericStyle {
  min-width: 108px;
  min-height: 35px;
  border-radius: 5px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  transition: 0.3s !important;
  padding: 0px 15px !important;

  @include responsive(1279px) {
    font-size: 12px;
  }
}

.modal {
  .buttonGenericStyle {
    height: 43px;

    @include responsive(767px) {
      height: 33px !important;
    }
  }

  .buttonGenericStyleInner {
    height: 33px !important;
  }
}

.buttonPrimary {
  min-width: 85px;
  height: 43px;
  background: #e5e9f8 !important;
  border: 1px solid #e5e9f8 !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #686e78 !important;

  @include responsive(767px) {
    height: 35px;
    font-size: 14px !important;
  }
}

.outlinedButton {
  background: #ffffff;
  border: 1px solid #eaa827;
  color: #eaa827;
  margin-right: 16px;

  &:hover {
    background: #eaa827;
    border: 1px solid #eaa827;
    color: #fff;
  }

  &:disabled {
    background-color: #d4d4d4;
    border: 1px solid #d4d4d4;
    color: #ffffff;
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.filledButton {
  color: #fff !important;
  background: #eaa827 !important;
  border: 1px solid #eaa827 !important;

  &:hover {
    background: #d8930d;
    border: 1px solid #d8930d;
    color: #ffff;
  }

  &:disabled {
    background-color: $GREY_EIGHT;
    cursor: not-allowed;
    border: 1px solid $GREY_EIGHT;

    &:hover {
      background-color: $WHITE_NINE;
      border: 1px solid $WHITE_NINE;
      color: #fff;
    }
  }
}

.fillGreyButton{
  background-color: #E6E9F7;
  border-radius: 4px;
  border: 1px solid #E6E9F7;
  color: #696E77;
}

.outlinedButtonDark {
  font-weight: 500;
  font-size: 14px;
  color: #424242;
  min-width: 58px;
  min-height: 35px;
  background: #ffffff;
  border: 1px solid #d1d5db;

  &:hover {
    background: #424242;
    border: 1px solid #424242;
    color: #fff;
  }
}

.rich-text-paragraph {
  .rich-text-paragraph-notes {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 200px;

    * {
      font-size: 12px;
    }
  }

  p {
    margin: 0px;
  }
}

.candidateNotes.modal-dialog {
  position: fixed;
  right: 0px;
  margin: auto;
  width: 304px;
  height: 100%;
  z-index: 999;

  .modal-content {
    height: 100vh;
    border-radius: 0px;
  }

  .modal-header {
    border: 0px;
    padding: 24px;

    .modal-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    h6 {
      margin: 0px;
      font-weight: 500;
      font-size: 14px;
      color: #272727;
    }

    button {
      border: 0px;
      background-color: transparent;
    }
  }

  .modal-body {
    padding: 0px 0px 24px;
    overflow-y: auto;
  }

  .modal-body::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eaeaea;
  }

  .modal-body::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  .modal-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d4d4d4;
  }

  .candidate-notes {
    padding: 16px 0px;
    border-bottom: 1px solid #e5e9f8;

    label {
      font-weight: 500;
      font-size: 12px;
      color: #686e78;
      margin-bottom: 6px;
    }

    h6 {
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 13px;
      color: rgba(104, 110, 120, 0.5);
    }

    .rich-text * {
      font-weight: normal;
      font-size: 12px;
      color: #686e78;
    }

    .notesBadge {
      min-width: 77px;
      height: 28px;
      border: 1px solid #e5e5e5;
      border-radius: 20px;
      font-weight: 400;
      font-size: 10px;
      color: #000000;
      margin-right: 8px;
    }
  }
}

.deleteResume {
  p {
    b {
      word-break: break-all;
    }
  }
}

.basicInfoSwitch {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include responsive(767px) {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.privateCandidate {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include responsive(767px) {
    flex-direction: column;
    align-items: flex-start !important;
  }

  label.switch {
    width: 60px;
  }
}

.candidateJobInfo {
  label {
    width: 200px;

    @include responsive(767px) {
      width: initial;
    }
  }
}

.forMobileRichText {
  @include responsive(767px) {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 18ch;
  }
}

.contentGridLabel {
  label {
    width: 40%;

    @media only screen and (min-width: 1025px) and (max-width: 1100px) {
      width: 45% !important;
    }

    @include responsive(1024px) {
      width: 100%;
    }
  }

  @include responsive(767px) {
    width: 100%;
    flex-direction: column;
  }
}

.candidateWriteUp {
  @include responsive(767px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.addRecruiterFolder {
  @include responsive(767px) {
    align-items: flex-start;
    flex-direction: column;
  }

  h4 {
    @include responsive(767px) {
      margin-bottom: 15px;
    }
  }

  button {
    @include responsive(767px) {
      font-size: 14px;
    }
  }
}

.oneLineClampText {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // width: 35ch;
  max-width: 35ch;
}

.paginationSpacing {
  padding: 0px 22px 20px;
}

.splitUserListing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;

  padding: 18px 23px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 4px;
  color: #686e78;
  background: rgba(245, 247, 249, 0.8);
  border: 1px solid #d9d9d9;
  max-width: 400px;

  p {
    font-weight: 500;
    font-size: 12px;
    color: #424242;
    margin: 0px 0px 0px 10px;
  }

  label {
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #eaa827 !important;
  }
}

.disableLink {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.filterCandidateSelect {
  select {
    width: 150px;
  }
}

.customUploadHover .bg-secondary {
  background-color: #d4d4d4 !important;
}

.ownerNotesWidth {
  max-width: 250px;
}

.revampContainerHover:hover {
  background-color: #d4d4d4 !important;
}

.FilesDragAndDrop {
  .FilesDragAndDrop__area {
    // width: 300px;
    height: 200px;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 24px;
    color: #555555;
    border: 2px #c3c3c3 dashed;
    border-radius: 12px;

    .area__icon {
      font-size: 64px;
      margin-top: 20px;
    }
  }
}

.FilesDragAndDrop {
  position: relative;

  .FilesDragAndDrop__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    background-color: #e7e7e7;
    border-radius: 12px;
    color: #7f8e99;
    font-size: 24px;
    opacity: 0.9;
    text-align: center;
    line-height: 1.4;
  }
}

.contactlist-dropdown {
  .actionsDropdown {
    .text-default {
      background-color: transparent !important;
      border-color: transparent !important;
    }
    .text-danger {
      color: red !important;
    }
    .text-yellow {
      color: #f6d234 !important;
    }
    .text-lightGreen {
      color: #1fc866 !important;
    }
    .text-lightBlue {
      color: #4d83e1 !important;
    }
    .text-indigo {
      color: indigo !important;
    }
  }
}
.text-capitalize {
  text-transform: capitalize;
}
.search-filter .input-group .input-group-text {
  background: transparent;
  border: 0px;
}

.candidate-search-sfilter {
  & > .search-filter {
    max-width: 380px;
  }
}

.candidatesearch-filter {
  input {
    border: 0px !important;
    height: 40px !important;
  }
  .input-group-text {
    height: 40px !important;
  }
}

.moveFolderTable {
  .table {
    border-width: 0;

    thead {
      background-color: #f7f8f9;
      border-radius: 5px;
      th {
        border-width: 0 !important;
        background: none !important;
      }
    }
  }
}

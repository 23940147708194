.user-info-main {
  border-bottom: 0px;
}

.settings-dropdown-menu {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #6b7280 !important;

  a {
    color: #6b7280 !important;
    &:hover {
      border-bottom: none !important;
    }
  }
}
@import "../styles/variables";

.dark-mode {
  & .wrapper {
    background: $BLACK_EIGHT;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #282828 inset;
    }
  }

  .sidebar {
    background: $BLACK_SIX;

    .nav-item {
      &:hover {
        a {
          color: $PRIMARY_BUTTON_BACKGROUND !important;
        }
      }
    }

    .navbar-nav.navbar-menu {
      a {
        color: $WHITE_LIGHT_TWO;

        &.is-active {
          color: $PRIMARY_BUTTON_BACKGROUND;
        }
      }

      .divider {
        background: $BLACK_FIVE;
      }
    }
  }

  .section-header,
  .heading-border,
  .heading-flex {
    h5 {
      color: $WHITE_LIGHT_TWO;
    }

    label {
      color: $WHITE_LIGHT_TWO;
      opacity: 0.6;
    }
  }

  .no-data-found {
    background: $BLACK_SEVEN;
    color: $WHITE_LIGHT_TWO;

    img {
      filter: invert(1);
    }
  }

  .make-private {

    span,
    p {
      color: $WHITE_LIGHT_TWO;
    }
  }

  .admin-tabs.team-tabs .activity-card {
    border: 0px;
  }

  .activity-card {
    label {
      color: $WHITE_LIGHT_TWO;
      opacity: 0.6;
    }

    h6 {
      color: $WHITE_LIGHT_TWO;

      a {
        color: $WHITE_LIGHT_TWO;
      }
    }
  }

  .admin-tabs.team-tabs {
    // .section-header {
    //   background-color: $BLACK_SIX;
    //   border-bottom: 1px solid $BLACK_NINE;
    // }

    .btn.bg-transparent.text-dark {
      color: $WHITE !important;
    }

    table tr td span {
      color: #f0f1f2 !important;
    }

    table tr td .text-success {
      color: #28a745 !important;
    }

    table tr td .synonym-badge>span {
      color: #065f46 !important;
    }
  }

  .activity-card {
    background-color: $BLACK_SIX !important;

    .section-header {
      background-color: $BLACK_SIX;
      border-bottom: 1px solid $BLACK_NINE;
    }

    .stats-card,
    .widget-card {
      background-color: $BLACK_SIX;
      border-bottom: 1px solid $BLACK_NINE;
      border-right: 1px solid $BLACK_NINE;
    }
  }

  .dashboard-main {
    .section-header {
      // background-color: $BLACK_SEVEN;
      border-bottom: 1px solid $BLACK_SEVEN;
    }
  }

  .stats-card,
  .widget-card {
    background-color: $BLACK_SIX;
    border-bottom: 1px solid $BLACK_NINE;
    border-right: 1px solid $BLACK_NINE;
  }

  .section-header,
  .heading-border,
  .admin-tabs.team-tabs .activity-card .section-header {
    // background: $BLACK_SIX;
    // border-bottom: 1px solid $BLACK_NINE;
  }


  .logs-tab {
    border: 0px;

    .card {
      background-color: $BLACK_SIX;
      border-radius: 0px;
    }

    .nav-tabs {
      background-color: $BLACK_SIX;
      border-bottom: 1px solid $BLACK_NINE;
      border-radius: 4px 4px 0px 0px;
    }
  }

  .heading-border {
    border-radius: 4px 4px 0px 0px;

    span {
      color: $WHITE_LIGHT_TWO;
    }
  }

  .nav-tabs {
    .nav-link {
      color: $WHITE_LIGHT_TWO;

      &.active {
        background-color: transparent;
      }
    }
  }

  .create-company {
    .card-body {
      border-bottom: 1px solid $BLACK_SIX;
    }
  }

  .user-basic-form {
    label {
      color: $WHITE_LIGHT_TWO;
    }
  }

  .user-card {
    background-color: $BLACK_SIX;
    border: 1px solid $BLACK_FIVE;
  }

  .user-card {
    border-top: 0px;

    h5 {
      color: $WHITE_LIGHT_TWO;
    }
  }

  .widget-card {
    border-left: 1px solid $BLACK_FIVE;
  }

  .stats-card,
  .widget-card {

    h2,
    label {
      color: $WHITE_LIGHT_TWO;
    }

    label {
      opacity: 0.6;
    }
  }

  .stats {
    border: 0px;
  }

  h3,
  h4 {
    color: $WHITE_LIGHT_TWO;
  }

  .admin-tabs.team-tabs,
  .admin-tabs.team-tabs.table,
  .table {
    border: 0px;

    thead {
      th {
        background: $BLACK_SIX;
        color: $WHITE_LIGHT_TWO;
        border-bottom: 1px solid $BLACK_SIX;

        &:first-child {
          padding-left: 22px;
        }

        .sort-btn,
        .sort-btn-new {
          color: #d9d9d9;
        }
      }
    }

    tbody {
      tr {
        td {
          background-color: $BLACK_SEVEN;
          border-top: 1px solid $BLACK_FIVE;
          color: $WHITE_LIGHT_TWO;

          .badge {
            background-color: transparent;
            border: none;
            // border-radius: 4px;
            // font-size: 12px;
            // padding: .50rem .35rem;
            // font-weight: 500;
            color: $WHITE_LIGHT_TWO !important;

            // &.badge-success,
            // &.badge-secondary {
            //   border: 1px solid $WHITE_ALPHA;
            // }

            &.badge-success {
              color: #34c38f !important;
              background-color: rgba(52, 195, 143, .18) !important;
            }

            &.badge-secondary {
              background: #d1fae5;
              color: #065f46 !important;
            }

            &.badge-warning {
              color: #eaa827 !important;
              background-color: rgba(234, 168, 39, .18);
            }

            &.badge-primary {
              color: #686e78 !important;
              background: #e5e5e5 !important;
            }

            &.badge-danger {
              background-color: #fee6e4;
              color: #fb4a3f !important;
            }

            &.badge-info {
              background-color: rgba(14, 121, 182, 0.3);
            }
          }
        }
      }
    }
  }

  .theme-switch {
    color: $WHITE_LIGHT_TWO;
  }

  .reports-wrapper {

    .card,
    .collapse-header {
      background: $BLACK_SIX;
      border: 1px solid $BLACK_FIVE;

      h5 {
        color: $WHITE_LIGHT_TWO;
      }

      img {
        filter: invert(1);
      }
    }

    .card-body {
      border: 1px solid $BLACK_FIVE;
      border-top: 0px;
    }

    .collapse {
      border: 0px;
    }

    label {
      color: $WHITE_LIGHT_TWO;
      opacity: 0.6;
    }
  }

  .filter-by {
    label {
      color: $WHITE_LIGHT_TWO;
    }
  }

  .form-control {
    background-color: $BLACK_SIX !important;
    border: 1px solid $BLACK_FIVE !important;
    color: $WHITE_LIGHT_TWO;

    &::placeholder {
      color: $WHITE_LIGHT_TWO !important;
    }
  }

  select {
    .form-control {
      padding-right: 20px;
    }
  }


  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-text-fill-color: $WHITE_LIGHT_TWO !important;
  }

  input {
    color: $WHITE_LIGHT_TWO !important;

    &::placeholder {
      color: $WHITE_LIGHT_TWO !important;
    }
  }

  -webkit-text-fill-color {
    color: $WHITE_LIGHT_TWO !important;
  }

  // input prepend
  .input-group {
    .input-group-text {
      background-color: #393a3b !important;
      border: 1px solid #525252 !important;
      color: $WHITE_NINE;
      opacity: 1;
    }

    .input-group-prepend {
      &+input {
        padding-left: 10px;
      }
    }
  }

  .search-filter {
    input {
      // border-left: 0px;
    }

    .input-group-prepend {
      margin-right: 0px;
    }



    .search-by-company {
      opacity: 1;
      padding-left: 10px;
    }
  }

  select {
    background-image: url("../assets/images/select-arrow-dark.svg") !important;
    background-position: center right 10px !important;
    background-repeat: no-repeat !important;
  }

  .table-sticky {
    tr {
      &:hover {
        td {
          &:first-child {
            background-color: $BLACK_SEVEN;
          }
        }
      }
    }
  }

  .pagination-display {

    p,
    span {
      color: $WHITE_LIGHT_TWO;
    }
  }

  // .create-company {
  //   .btn-secondary {
  //     background: $BLUE;
  //     border: 1px solid $BLUE;
  //   }
  // }


  // .admin-tabs.team-tabs {
  //   .btn.btn-secondary {
  //     background: $BLUE;
  //     border: 1px solid $BLUE;
  //   }
  // }

  .select__multi-value__remove {
    svg {
      fill: $WHITE_LIGHT_TWO;
    }
  }

  .select__multi-value {
    background-color: $BLACK_FIVE;
    color: $WHITE_LIGHT_TWO;
  }

  .select__multi-value__label {
    color: $WHITE_LIGHT_TWO;
  }

  .text-field-value {
    background-color: transparent;
    padding: 15px;
    border: 1px solid $BLACK_FIVE;

    p {
      margin: 0px;
    }
  }

  .editor-height .ql-toolbar.ql-snow,
  .editor-height .ql-container.ql-snow {
    border: 1px solid $BLACK_FIVE;
  }

  .ql-editor {
    color: $WHITE_LIGHT_TWO;
  }

  .address-card {
    border: 1px solid $BLACK_FIVE;

    &.active {
      border: 1px solid #8f8686;
    }

    h6,
    label,
    p {
      color: $WHITE_LIGHT_TWO;
    }
  }

  .radio-container input:checked~.checkmark {
    background-color: $GREEN_TWO;
  }

  .notes-flex {
    border-bottom: 1px solid $BLACK_FIVE;

    .font-weight-bold {
      color: $WHITE_LIGHT_TWO;
    }

    .added-by {
      color: $GRAY_ELEVEN;
    }
  }

  .line-clamp {
    color: $WHITE_LIGHT_TWO;
  }

  .users-card {
    background-color: $BLACK_SEVEN;
    border: 1px solid $BLACK_FIVE;

    h5 {
      color: $WHITE_LIGHT_TWO;
    }

    a {
      &:hover {
        color: $PRIMARY_BUTTON_BACKGROUND;
      }
    }
  }

  .edit-user-form {
    background: $BLACK_SIX;
    border: 1px solid $BLACK_FIVE;

    .form-divider {
      border-bottom: 1px solid $BLACK_FIVE;

      h5 {
        color: $WHITE_LIGHT_TWO;
      }
    }

    .form-divider.form-actions {
      border-bottom: 0px;
    }
  }

  .document-table {
    tbody {
      tr {
        td {
          background-color: $BLACK_SIX;
          border-top: 0px;
        }
      }
    }

  }

  .search-by-company.form-control {
    border: 0px;
    padding-left: 10px;

    &::placeholder {
      color: #d9d9d9 !important;
    }
  }

  .search-filter .input-group {
    background-color: transparent;
    border: 1px solid #525252;
  }

  .dollar-addon-btn {
    .input-group-text {
      border: 1px solid $BLACK_FIVE;

      &:focus {
        border-color: transparent;
        outline: none;
      }
    }

    input.form-control {
      border-left: 0px;
      padding-left: 10px;

      &:focus {
        border-color: $BLACK_FIVE;
      }
    }
  }

  .editor-height-job-order .ql-toolbar.ql-snow,
  .editor-height-job-order .ql-container.ql-snow {
    border: 1px solid $BLACK_FIVE;
  }

  .user-basic-form {
    .rounded {
      background: $BLACK_SIX !important;
      border: 1px solid $BLACK_FIVE;
      color: $WHITE_LIGHT_TWO;
    }
  }

  .common-stats-card {
    background-color: $BLACK_SIX;
    border-bottom: 1px solid $BLACK_FIVE;
    border-right: 1px solid $BLACK_FIVE;

    &:nth-child(even) {
      border-right: 0px;
    }

    h2 {
      color: $WHITE_LIGHT_TWO;
    }
  }

  .agreement-download.btn-secondary {
    background-color: transparent !important;
    border: 0px !important;
  }

  .filter-by {
    select {
      // border: 1px solid $BLACK_SIX;
    }
  }

  .user-basic-form h5 {
    color: $WHITE_LIGHT_TWO;
  }

  .my-stats {
    .section-header {
      background-color: $BLACK_SIX;
    }

    .personal-info {
      background: $BLACK_SIX;
      border: 1px solid $BLACK_FIVE;
      border-top: 0px;
    }

    .user-card {
      border: 0px;
    }

    .col-xl-9 {
      .section-header {
        min-height: 55px;
      }
    }
  }

  .my-stats {

    h6,
    p {
      color: $WHITE_LIGHT_TWO;
    }

    label {
      color: $WHITE_LIGHT_TWO;
      opacity: 0.6;
    }
  }

  .stats-text {
    color: $WHITE_LIGHT_TWO;
  }

  .create-company {
    label {
      color: $WHITE_LIGHT_TWO;
      opacity: 0.6;
    }

    h6 {
      color: $WHITE_LIGHT_TWO;
    }
  }

  .application-status {
    h5 {
      color: $WHITE_LIGHT_TWO;
    }
  }

  .d-flex-field {
    label {
      color: $WHITE_LIGHT_TWO;
    }
  }

  // .css-1pahdxg-control,
  // .css-1v5djbv-control,
  // .css-mryez8-control,
  // .select__control {
  //   background-color: $BLACK_SIX !important;
  //   border-color: $BLACK_SIX;
  //   color: $WHITE_LIGHT_TWO;

  //   &::placeholder,
  //   .select__placeholder {
  //     color: $WHITE_LIGHT_TWO !important;
  //   }

  //   &:hover {
  //     border-color: $BLACK_SIX;
  //   }
  // }

  .css-1fhf3k1-control {
    background-color: $BLACK_SIX !important;
    // border-color: transparent;
    color: $WHITE_LIGHT_TWO;

    &::placeholder,
    .select__placeholder {
      color: $WHITE_LIGHT_TWO !important;
    }
  }

  .css-1rhbuit-multiValue {
    background: $BLACK_FIVE;
    color: $WHITE_LIGHT_TWO;
  }

  .css-12jo7m5 {
    color: $WHITE_LIGHT_TWO;
  }

  .css-1okebmr-indicatorSeparator {
    background: $WHITE_LIGHT_TWO;
  }

  // .css-tlfecz-indicatorContainer {
  //   & svg {
  //     path {
  //       fill: $WHITE_NINE;
  //     }
  //   }
  // }
  .css-1uccc91-singleValue {
    // color: unset !important;
    color: #d9d9d9;
  }

  // .css-hv69ms-control {
  //   background-color: $BLACK_SIX;
  //   border: 0px;
  //   color: $WHITE_LIGHT_TWO;
  // }


  // .create-company {
  //   .css-yk16xz-control,
  //   .css-1fhf3k1-control {
  //     border: 1px solid $BLACK_FIVE;
  //   }
  // }

  // .css-yk16xz-control,
  // .css-1v80m20-control,
  // .css-w1wm87-control {
  //   background-color: $BLACK_SIX !important;
  //   border: 1px solid $BLACK_FIVE;
  //   color: $WHITE_LIGHT_TWO;

  //   &::placeholder,
  //   .select__placeholder {
  //     color: $WHITE_LIGHT_TWO !important;
  //   }
  // }

  .filter-by {
    .async-dropdown {
      .css-yk16xz-control {
        border-color: transparent;
      }
    }
  }

  .escalation-protocol {
    background: $BLACK_SIX;
    border: 1px solid $BLACK_FIVE;

    h5 {
      color: $WHITE_LIGHT_TWO;
    }

    p {
      color: $WHITE_LIGHT_TWO;
      opacity: 0.5;
    }
  }

  .agreement-details.card {
    border: 1px solid $BLACK_FIVE;

    h5,
    h6 {
      color: $WHITE_LIGHT_TWO;
    }

    label {
      color: $GRAY_ELEVEN;
      opacity: 1 !important;
    }
  }

  .rounded.bg-light {
    background: $BLACK_FIVE !important;
    color: $WHITE_LIGHT_TWO;
  }

  .candidate-action {
    label {
      color: $WHITE_LIGHT_TWO;
    }

    h6 {
      color: $WHITE_LIGHT_TWO;

      span {
        color: $WHITE_LIGHT_TWO;
      }
    }
  }

  .job-orders-tbody {
    td {
      border-top: 0px !important;
    }
  }

  .admin-tabs {
    p {
      color: $GRAY_ELEVEN;
    }
  }

  .activity-card {
    .job-applicant-content {
      label.text-muted {
        color: $WHITE_LIGHT_TWO !important;
        opacity: 1 !important;
      }
    }
  }

  .border-bottom {
    border-bottom: 1px solid $BLACK_FIVE !important;
  }

  .job-application-grid {
    h5 {
      color: $WHITE_LIGHT_TWO;
    }

    label {
      color: $GRAY_ELEVEN !important;
    }
  }

  .user-info-main {
    span {
      color: $WHITE_LIGHT_TWO;
    }
  }

  .page-not-found {
    background: $BLACK_SIX;
    border: 1px solid $BLACK_FIVE;

    .inner {
      opacity: 0.6;
    }
  }

  .add-user-form {
    background: $BLACK_SIX;
    border: 1px solid $BLACK_FIVE;

    h6 {
      color: $GRAY_ELEVEN;
    }

    .form-divider {
      border-bottom: 1px solid $BLACK_FIVE;
    }

    .form-divider:last-child {
      border-bottom: 0px;
    }
  }

  .team-reports {
    .section-header {
      background: $BLACK_SEVEN;
    }
  }

  .pagination-container .page-link {
    color: $WHITE_LIGHT_TWO;

    &:hover {
      color: $PRIMARY_TEXT_COLOR;
    }
  }

  .document-table.table.table-borderless {


    td {
      border: 0px;
      background-color: $BLACK_SIX;
    }
  }

  .p-2.mr-3.rounded.bg-light {
    color: $WHITE_LIGHT_TWO;
    background: $BLACK_SIX !important;
    border: 1px solid $BLACK_FIVE;
  }

  .actions-menu .dropdown-menu .dropdown-item {
    color: $WHITE_LIGHT_TWO !important;
  }

  .dropdown-menu {
    background-color: $BLACK_SEVEN;
    border: 1px solid $BLACK_FIVE;

    .dropdown-item {
      color: $WHITE_LIGHT_TWO !important;

      a {
        color: $WHITE_LIGHT_TWO !important;
      }

      &:hover,
      &:focus {
        background-color: #282828 !important;
      }
    }
  }

  .candidate-action {
    border-bottom: 1px solid $BLACK_FIVE;
  }

  .filter-job-order {
    color: #fff !important;
    background: #eaa827 !important;
    border: 1px solid #eaa827 !important;
    transition: all 0.4s;

    &:hover {
      background: #d8930d !important;
      border: 1px solid #d8930d !important;
      color: #ffff !important;
    }
  }

  .filter-status {
    span {
      color: $WHITE_LIGHT_TWO;
    }
  }

  .sequence-cards .card {
    border: 1px solid $BLACK_FIVE;
  }

  .modal {
    .modal-title {
      color: $WHITE_LIGHT_TWO !important;
      background-color: transparent !important;
    }

    .modal-content {
      background: #1e1e1e !important;
      border: 1px solid $BLACK_FIVE !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    label,
    td>span {
      color: $WHITE_LIGHT_TWO !important;
    }

    &>h5 {
      // background-color: #e9ecef !important;
      color: #858a8f !important;
      padding: 1px 5px;
    }
  }

  strong,
  .btn-default {
    color: $WHITE_LIGHT_TWO !important;
  }

  .bg-light.text-dark {
    color: $BLACK_SIX !important;
  }

  .notification-card {
    border-bottom: 1px solid $BLACK_FIVE;

    .message {
      color: #d9d9d9;
    }
  }

  .ql-picker-label {
    color: $WHITE_LIGHT_TWO !important;
  }

  .ql-snow .ql-stroke {
    stroke: $WHITE_LIGHT_TWO;
  }

  .sharee-card.card {
    border: 1px solid $BLACK_FIVE;
  }

  .contacts-wrapper {
    background-color: $BLACK_SIX;
    border: 1px solid $BLACK_FIVE;

    .contact-card {
      border-right: 1px solid $BLACK_FIVE;

      h5,
      label {
        color: $WHITE_LIGHT_TWO !important;
      }

      label {
        opacity: 0.6;
        color: #e6a21c;
      }
    }
  }

  .contacts-card-flex .contact-card {
    @media only screen and (max-width: 992px) {
      border-bottom: 1px solid $BLACK_FIVE;
    }
  }

  .hamburger .line {
    background-color: $WHITE_LIGHT_TWO;
  }

  .alert-danger-card {
    .alert-danger {
      color: #ff7066;
      background-color: #3b2220;
      border-color: #ff7066;
    }

    .card-text {

      h6,
      label {
        color: #ff7066;
      }
    }
  }

  // .css-1bpab52-menu {
  //   background-color: #282828 !important;
  //   color: #d9d9d9;

  // }

  .react-select__option {
    &:hover {
      background-color: #363636 !important;
      color: #d9d9d9;
    }
  }

  .advertisedLocation-card {

    h6,
    label {
      color: #d9d9d9;
    }
  }

  .css-26l3qy-menu {
    background-color: #282828;
    color: #d9d9d9;
  }

  .candidate-resume-grid {
    background: #282828;
    border: 1px solid #525252;
  }

  .employment-text {
    span {
      color: #d9d9d9;
    }
  }

  .skills-badge {
    color: #d9d9d9;
  }

  .sticky-action-container {
    border: 1px solid #525252;
    background-color: #282828;
    padding: 0px 15px;

    .d-selected-num {
      color: #d9d9d9;
    }
  }

  .job-applicant-buttons a.text-dark,
  .sticky-action-btns a.text-dark {
    background-color: #2e95b4 !important;
    color: #ffffff !important;
  }

  .modal-btn-cancel.btn {
    background-color: transparent;
    border: 0px;
    color: #d9d9d9;
  }

  .ownership-content {
    ul {
      li {
        color: #d9d9d9;
      }
    }
  }

  .multiselect,
  .p-2.d-flex.text-dark.alert.alert-primary {
    border: 1px solid #525252;
    background: #282828;
  }

  .avatar-label {
    &:hover {
      background: #363636;
    }
  }

  .actions-menu .dropdown-menu .dropdown-item:focus,
  .actions-menu .dropdown-menu .dropdown-item:hover {
    background: #282828;
  }

  .add-team {
    .card {
      border: 1px solid #525252;
    }
  }

  .user-info-main .text-right,
  .candidate-notes-wrapper .candidate-notes h5,
  .candidate-notes .text-muted,
  .person-name,
  label {
    color: #d9d9d9 !important;
  }

  .candidate-notes-wrapper .candidate-notes,
  .application-status {
    border-bottom: 1px solid #525252;

    small {
      color: #b8b2b2 !important;
      word-break: break-all !important;
    }

    .for-link-theme-color {
      color: #eaa827 !important;
    }
  }

  .rich-text {

    dl,
    ol,
    ul {
      li {

        span,
        &::marker {
          color: #d9d9d9 !important;
        }
      }
    }

    p {
      span {
        color: #d9d9d9 !important;
      }
    }
  }

  .step-grid,
  .stepper-grid {
    background-color: #282828;
  }

  .step-grid label {
    color: #d9d9d9;
  }

  .step-grid .input-group-text {
    background-color: transparent;
    border: 1px solid #525252;
  }

  .noteSection {
    color: #d9d9d9;
    border-top: 1px solid #525252;
  }

  .stepper-grid {
    color: #d9d9d9;
  }

  .users-pagination,
  .applicantDetail,
  .jobOrdersActionLog,
  .resumeHeader,
  .css-7ntjzm-control,
  .css-a5fo5t-control,
  .css-1epi15c-control,
  .revampContainer,
  .css-eezgbe-control,
  .css-1t2f22x-control,
  .message-queue div div,
  .no-contact-added,
  .file-upload-csv-index,
  .css-1dgd3tv-control {
    background-color: #363636;
  }

  .dark-users-pagination {
    background-color: transparent;
  }

  .jobOrderRevamp,
  .step-cards,
  .step-preview,
  .CandidateTabsWrapper {
    background-color: #282828;

  }

  .preview-body-quil {
    background: transparent !important;
  }

  .step-card-footer {
    background-color: transparent !important;
  }

  .no-contact-added {
    border: 1px solid transparent;
  }

  .pagination_pageItem__hDxyx,
  .pagination_separator__1NovJ,
  .primaryHeading h5,
  .jobOrderRevamp h5,
  .jobOrderRevamp label,
  .CandidateTabsWrapper label,
  .CandidateTabsWrapper h5,
  .CandidateTabsWrapper h6,
  .CandidateTabsWrapper li,
  .CandidateTabsWrapper .nav-tabs a.active,
  .CandidateTabsWrapper .nav-tabs a:hover,
  .css-1wa3eu0-placeholder,
  .revampDialog.modal-dialog .form-control,
  h5,
  .jobOrderRevamp h6,
  .jobOrderRevamp .contact-list-ul .contact-list-li,
  .infoCardDivider label,
  .infoCardDivider a,
  .switchLabel,
  .settings-dropdown-menu,
  .text-muted {
    color: #d9d9d9 !important;
  }

  .icon-div {
    path {
      fill: #d9d9d9 !important;
    }
  }

  .CandidateTabsWrapper .nav-tabs a.active,
  .CandidateTabsWrapper .nav-tabs a:hover {
    border-bottom: 2px solid #eaa827;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #1e1e1e !important;
  }

  .filledButton:disabled {
    background-color: #eaa827 !important;
  }

  .searchFilterTable .input-group {
    background-color: transparent;

  }

  .subtitleText // .table tbody td a,

  // .table tbody td .linkButton
    {
    color: #fff !important;
  }

  .jobOrderRevamp .card {
    background-color: transparent;
  }

  .dropdown-caret:after {
    border-top: 0.4em solid #fff;
  }

  // update by awais
  // Sidenavbar

  // Sidenavbar
  .card {
    background-color: transparent;
  }

  .agreementDownload {
    background-color: transparent;
    color: #eaa827;
    border-color: #eaa827;
    transition: all 0.5s;

    &:hover {
      background-color: rgba(234, 168, 39, 0.1);
    }
  }

  .css-lj28cy-control,
  .css-1xuv997-control,
  .css-1sqjfcc-control,
  .css-1sqjfcc-control.css-lxuv997-control,
  .css-1g1xnay-control,
  .css-1dgd3tv-control {
    border: 1px solid #525252 !important;
    box-shadow: none !important;

    &:hover,
    &:focus {
      border: 1px solid #525252 !important;
    }
  }

  .css-tlfecz-indicatorContainer {
    color: hsl(0, 0%, 80%) !important;
  }

  .for-modal-styling.modal-dialog.editPlacementDialog .modal-body label {
    font-weight: 500;
    font-size: 12px;
    color: #bfbec2;
    margin-bottom: 8px;
  }

  .for-modal-styling.modal-dialog.editPlacementDialog .modal-body .input-group .input-group-prepend {
    &+ {
      input {
        padding-left: 10px !important;
      }
    }
  }

  .previous-page {
    &+ {
      .primaryHeading {
        &>h6 {
          color: $WHITE_LIGHT_TWO;
        }
      }
    }
  }

  .actionsDropdown {
    .btn-secondary {
      color: #fff !important;
      background-color: #f9b220 !important;
      border-color: #f9b220 !important;

      &:hover,
      &:focus {
        color: #fff !important;
        background-color: #f5aa0e !important;
        border-color: #f5aa0e !important;
      }
    }
  }

  .subTextSpacing {
    color: $WHITE_LIGHT_TWO !important;
  }

  .css-tj5bde-Svg {
    fill: white;
  }

  .resume-preview.btn-secondary {
    &:hover {
      background-color: #b3acac;
    }
  }

  .user-info {
    a {
      // font-size: 1.05rem;
      color: $BLUE_FIVE;
      font-family: $INTER_FONT;
    }
  }

  .main-container {
    &>.text-dark {
      color: $WHITE_LIGHT_TWO !important;
    }
  }

  .revampDialog {
    .modal-content {
      h5 {
        &.m-0 {
          padding: 0;
        }

      }
    }
  }

  .conditions-terminate {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    margin-top: 20px;

    div {
      color: #d9d9d9;
    }
  }

  .candidtae-bcredit-ssheet {
    .table {
      background: transparent;
    }

    .transparentTable tr {
      border: 0px !important;
      background-color: transparent;
    }
  }

  .input-group-text {
    background-color: transparent;
    color: #d9d9d9;
  }

  .noteSection h5 {
    background: #000 !important;
    color: #d9d9d9 !important;
    padding-left: 2px;
  }

  .users-pagination .paginationItemsSelect select {
    color: #ffffff !important;
  }

  .emptyPlaceholder,
  .splitUserListing {
    background: transparent;
    color: $WHITE_LIGHT_TWO;
  }

  .candidateNotes {
    &.modal-dialog {
      .modal-header {
        h6 {
          color: $WHITE_LIGHT_TWO;
        }
      }

      .candidate-notes-wrapper {
        .candidate-notes {
          label {
            color: $WHITE_LIGHT_TWO;
          }

          h6 {
            color: $WHITE_LIGHT_TWO;
          }

          .notesBadge {
            color: $WHITE_LIGHT_TWO;
          }
        }
      }
    }
  }

  .send-submission-package {
    .card {
      border-color: $WHITE_LIGHT_TWO;
    }

    .modal-body {
      small {
        color: $WHITE_LIGHT_TWO;
      }

      .dollar-addon-btn {
        button {
          color: $WHITE_LIGHT_TWO;
        }
      }
    }
  }

  .sticky-action-container {
    .form-group {
      select {
        &.form-control {
          padding-right: 25px;
        }
      }
    }
  }

  .diable-form-group,
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #393b3d !important;
    opacity: 1;
    border-radius: 5px;
  }

  .file-name-csv-index {
    color: $WHITE_LIGHT_TWO;
  }

  .badge-submission-light {
    background-color: #eaa827 !important;
    color: #000 !important;
  }

  .date-range-today-contacts {
    background: #282828;
    box-shadow: $BOX_SHADOW_ONE;
  }

  .summarySectionJob {
    div {
      color: $WHITE_LIGHT_TWO;
    }
  }

  .viewAdvertised-jobDescription {

    div,
    em {
      color: $WHITE_LIGHT_TWO !important;
    }
  }

  .candidateSubmission {
    .text-dark {
      color: $WHITE_LIGHT_TWO !important;
    }
  }

  .edit-start-team {
    color: $WHITE_LIGHT_TWO;
  }

  .section-notes-search div {
    background-color: $BLACK_SIX;
    color: #d9d9d9;

    p {
      color: #d9d9d9;
    }
  }

  .candidate-submission-modal {
    small {
      color: $WHITE_LIGHT_TWO !important;
    }
  }

  .contact-selection {
    background-color: transparent;
  }

  .version-text {
    color: #ffffff;
    opacity: 0.7;
  }

  .btn-job-filters {
    background-color: #282828;
    border-color: #525252;
    color: #d9d9d9;

    &.active {
      background-color: #eaa827;
    }
  }

  .css-1whuqa8-control,
  .css-qm4vfb-control {
    border-color: #525252;

    &:hover {
      border-color: #525252;
    }
  }

  .empty-table-bg {
    .no-data-found {
      background-color: transparent;
    }
  }

  .contact-card-new {

    h5,
    label.active {
      color: #424242 !important;
    }
  }

  .sidebar-content,
  .sidedrawer-content {
    background-color: #282828;
  }

  .job-status-box {
    background-color: #363636;
  }

  .btn-light-yellow {
    background-color: #282828 !important;
    border-color: #525252 !important;
    color: #d9d9d9 !important;
  }

  .job-status-wrap {
    p {
      color: #d9d9d9;
    }
  }

  .light-text {
    color: #d9d9d9 !important;

    font {
      color: #d9d9d9 !important;
    }
  }

  .select__control {
    background-color: #363636;
  }

  .candidate-drawer-resume-grid {
    background-color: #363636;
  }

  .dark-table {
    table {
      background-color: transparent !important;

      thead {
        th {
          background-color: #282828 !important;
          color: #d9d9d9 !important;
          border: none !important;
        }
      }

      tbody {
        tr {
          td {
            color: #d9d9d9 !important;
          }
        }
      }

      tfoot {
        tr {
          td {
            color: #d9d9d9 !important;

            span {
              color: #d9d9d9 !important;
            }
          }
        }
      }
    }
  }

  .dark-schedule-card {
    background-color: #363636;
    border: 1px solid #525252;
    color: #d9d9d9 !important;
  }

  .dark-day-text {
    color: #212529;
  }

  .schedule-main {
    background-color: #363636;
    border: 1px solid #525252;
  }

  .day-main {
    background-color: #363636;
  }

  .dark-card {
    background-color: #282828 !important;
    color: #d9d9d9 !important;
  }

  .light-dark {
    background-color: #363636 !important;

    h6,
    p {
      color: #d9d9d9 !important;
      word-break: break-all !important;
    }
  }

  .dark-theme-text {
    color: #d9d9d9 !important;
  }

  .dark-theme-icon {
    svg {
      path {
        stroke: white !important;
      }
    }
  }

  .dark-theme-border {
    span {
      border-color: #e5e5e5 !important;
    }
  }

  .dark-border {
    border: none !important;
  }

  .light-dark-color {
    h5 {
      background-color: #1e1e1e !important;
    }
  }

  .text-blackten {
    color: #d9d9d9 !important;
  }

  .notification-body {
    background-color: #202020;

    svg {
      path {
        fill: #ffffff;
      }
    }

    .notification-list-wrap {
      background-color: #363636;

      .notification-text {

        h6,
        span {
          color: #d9d9d9 !important;
        }
      }
    }
  }

  .step2-header {
    background-color: #1e1e1e;
  }

  .step2-style-index-csv {
    background-color: #1e1e1e;
  }

  .WordSection1 {
    p {
      background-color: #1e1e1e !important;

      span {
        color: #d9d9d9 !important;
        background-color: #1e1e1e !important;
      }
    }

    .MsoNormal {
      background-color: #1e1e1e !important;

      span,
      a,
      p {
        color: #d9d9d9 !important;
        background-color: #1e1e1e !important;
      }
    }
  }

  .alert-danger {
    strong {
      color: inherit !important;
    }
  }

  .lookup-signature-card {

    p,
    span {
      background-color: #282828 !important;

      span {
        color: #d9d9d9 !important;
      }
    }
  }

  #stepBodyQuill span {
    color: #d9d9d9 !important;
  }

  .contentGridColumn {
    color: #d9d9d9 !important;
  }

  .custom-scroller {
    p {
      color: #d9d9d9 !important;
    }
  }

  .dark-border {
    border: 1px solid #8f8686;
  }

  .buttonGenericStyle {
    background-color: #eaa827;
    color: #ffffff;
  }

  .empty-preview-steps {
    background-color: #363636;
  }

  .email-signature {
    td {
      color: #d9d9d9;

      span {
        color: #d9d9d9 !important;
      }

      a {
        color: #007bff !important;
      }
    }
  }

  #ms-outlook-mobile-signature {
    background-color: #1e1e1e !important;

    div,
    p,
    span {
      background-color: #1e1e1e !important;
      color: #d9d9d9 !important;
    }
  }

  .admin-tabs.team-tabs table tr .badge-dark-text span {
    color: #343a40 !important;
    background-color: #f8f9fa !important;
  }

  .skillBadge {
    border: none;
    background-color: #d9d9d9;
    color: #363636;
  }

  .chatgpt-text {
    color: #a3a3a3;
  }

  .chatgpt-reply {
    background-color: #363636;
    border: 1px solid #1c1c1c;
  }

  .chatgpt-prompt {
    background-color: #282828;

    .chatgpt-input-body {
      border: 1px solid #1c1c1c;
      box-shadow: none;

      textarea::placeholder, textarea {
        color: #a3a3a3 !important;
      }
    }
  }
}

.filter-job-order {
  border-radius: 4px;
  padding: 4px 10px;
}
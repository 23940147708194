.leader-board {
  // background-color: white;
  // border: 1px solid #f0f1f2;
  padding: 15px 25px;
}

.leader-board-profile {
  // width: 50px;

  & > img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
}

.person-name {
  font-weight: 500;
  font-size: 14px;
  min-width: 120px;
  line-height: 21px;
  // color: #33475b;
}

.person-credit {
  color: #eaa827;
}

.custom-progressbar.progress-bar {
  background-color: #686E78;
}

.progress-box {
  width: 15px;
  height: 15px;
  border-radius: 1px;
  background-color: #FFE676;

}

.progress-box2 {
  background-color: #EFB446;


}

// progressbar-new-css
.progressbar-main {
  height: 6px;
  .progress-bar {
    background-color: #EFB446;
  }
  &.progress {
    height: 6px;
  }

  .progressbar-parent {
    position: relative;
    width: 100%;
    background-color: #FFE676;


    .progressbar-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: #EFB446;
    }
  }
}
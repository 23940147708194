@import "../../../styles/variables";
@import "../../../styles/mixins";

.stepper-grid {
  background-color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.step-grid {
  padding-right: 20px;
  padding-left: 20px;
  background-color: white;
}

.percentage-am {
  color: #eaa82e;
  margin-left: 10px;
}

.calculation-border {
  width: 300px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  border-right: 1px solid #f0f1f2;

  label {
    margin-right: 10px;
  }
}

.breadcrumb {
  color: #eaa82e !important;
  background-color: transparent !important;
  cursor: pointer;

  .breadcrumb-item {
    padding-left: 0px !important;

    :nth-child(1) {
      padding-right: 15px !important;
    }

    :nth-child(2) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    :nth-child(3) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  li {
    display: inline-block;
    margin-right: 10px;
  }
}

.placement-options {
  width: 250px;
  margin: 0px;
}

.active-step {
  font-weight: bold;
  font-size: 18px;
  border-bottom: 3px solid #eaa82e;
}

.disabled-active-step {
  border-bottom: 2px solid #cacfd3;
}

.disabled-breadcrumb-item {
  color: #a2a5aa !important;
  cursor: default;
}

.border-top-information {
  border-top: 1px solid #f0f1f2;
}

.border-bottom-information {
  border-bottom: 1px solid #f0f1f2;
}

.border-right-information {
  border-right: 1px solid #f0f1f2 !important;
}

.formula-placement-calc {
  color: #308d3f;
}

.readonly-aca-calc {
  background-color: #f7f8f9;
  height: 46px;
  border: 1px solid #e5e9f8;
  border-radius: 0px;
  margin-left: 0px;
  margin-right: 4px;
  padding-left: 10px;

  label {
    padding-top: 12px;
  }
}

.recruiter-profit-placement-calc {
  background: #f7f8f9;
  border: 1px solid #e5e9f8;
  box-sizing: border-box;
  padding-top: 12px;
  width: 170px;
  height: 46px;
}

.rate-info-calculations {
  label {
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #686e78 !important;
    margin: 0px !important;
  }

  h6 {
    font-weight: 500;
    font-size: 12px;
    color: #424242;
    margin: 0px;
    text-align: left;
  }

  .labelWidth {
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .contentWidth {
    width: 50%;
    display: flex;
    align-items: center;
  }
}

.emptyPlaceholder {
  
  padding: 18px 23px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 4px;
  color: #686e78;
  background: rgba(245, 247, 249, 0.8);
  border: 1px solid #d9d9d9;
  max-width: 400px;

  @include responsive(1024px) {
    padding: 15px;
  }

  @include responsive(767px) {
    margin-bottom: 15px;
  }

  & img {
    margin-right: 24px;

    @include responsive(767px) {
      margin-right: 10px;
    }
  }
}

.placementDetail {
  .badge {
    min-width: 50px;
    height: 20px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .badge-primary {
    background-color: #e5e5e5;
    color: #686e78;
  }
}

.for-modal-styling.modal-dialog.editPlacementDialog {
   width: 100%;
  max-width: 1400px;
  
  // @include responsive(1450px) {
  //   width: 98%;
  //   max-width: 98%;
  // }
  // @include responsive(1024px) {
  //   width: 95%;
  //   max-width: 95%;
  // }

  .modal-header {
    padding: 32px;
    border: 0px;

    @include responsive(1024px) {
      padding: 15px;
    }
  }

  .modal-footer {
    padding: 28px 32px;

    @include responsive(1024px) {
      padding: 15px;
    }
  }

  .modal-body {
    padding: 13px 32px 15px;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
   
    @media screen and (max-device-height: 500px) and (orientation: landscape) {
      height: 200px;
    }

    @include responsive(1024px) {
      padding: 15px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #d4d4d4;
    }

    label {
      font-weight: 500;
      font-size: 12px;
      color: #272727;
      margin-bottom: 8px;
    }

    .agreementSelectSpacing {
      .css-2b097c-container,
      input.form-control,
      .input-group {
        margin-bottom: 0px;
      }

      .text-danger {
        display: block;
        margin-bottom: 15px;
      }

      label {
        .text-danger {
          display: initial;
        }
      }
    }

    .forInputMargin {
      input.form-control {
        margin-bottom: 0px;
      }
    }

    textarea {
      background: #ffffff;
      border: 1px solid #e5e9f8;
      border-radius: 4px;
      resize: none;
      min-height: 83px;
    }

    // .input-group-text {
    //   font-weight: 500;
    //   font-size: 14px;
    //   color: #9ba1a8;
    //   background-color: transparent;
    //   border: 1px solid #e5e9f8;
    //   border-radius: 4px 0px 0px 4px;
    //   border-right: 0px;
    //   height: 36px;
    // }

    .displayNoneHeading {
      .form-group-heading {
        display: none;
      }
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .form-group {
      margin: 0px;
    }

    .input-group {
      // margin-bottom: 28px;
      input {
        border-radius: 0px 4px 4px 0px;
        border-left: 0px;
        // padding-left: 0px;
        margin: 0px;
        outline: none;
        box-shadow: none;
      }
    }
  }

  .modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
    color: #272727;
  }
}

.for-modal-styling.modal-dialog.splitDialog {
  width: 800px;
  max-width: 800px;

  @include responsive(1024px) {
    width: 95%;
    max-width: 95%;
  }

  .modal-header {
    padding: 32px 32px 0 32px;
    border: 0px;

    @include responsive(1024px) {
      padding: 15px;
    }
  }

  .modal-footer {
    padding: 28px 32px;

    @include responsive(1024px) {
      padding: 15px;
    }
  }

  .modal-body {
    padding: 13px 32px 15px;
    height: 480px;
    overflow-y: auto;

    @media screen and (max-device-height: 500px) and (orientation: landscape) {
      height: 200px;
    }

    @include responsive(1024px) {
      padding: 15px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #eaeaea;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #d4d4d4;
    }

    label {
      font-weight: 500;
      font-size: 12px;
      color: #272727;
      margin-bottom: 8px;
    }

    .form-control {
      background: #ffffff;
      border: 1px solid #e5e9f8;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      color: #272727;
    }

    input.form-control,
    .css-2b097c-container {
      margin-bottom: 28px;
    }

    .agreementSelectSpacing {
      .css-2b097c-container,
      input.form-control,
      .input-group {
        margin-bottom: 0px;
      }

      .text-danger {
        display: block;
        margin-bottom: 15px;
      }

      label {
        .text-danger {
          display: initial;
        }
      }
    }

    .forInputMargin {
      input.form-control {
        margin-bottom: 0px;
      }
    }

    textarea {
      background: #ffffff;
      border: 1px solid #e5e9f8;
      border-radius: 4px;
      resize: none;
      min-height: 83px;
    }

    .input-group-text {
      font-weight: 500;
      font-size: 14px;
      color: #9ba1a8;
      border: 1px solid #e5e9f8;
      border-radius: 4px 0px 0px 4px;
      border-right: 0px;
      height: 36px;
    }

    .displayNoneHeading {
      .form-group-heading {
        display: none;
      }
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .form-group {
      margin: 0px;
    }

    .input-group {
      margin-bottom: 28px;
      input {
        border-radius: 0px 4px 4px 0px;
        border-left: 0px;
        // padding-left: 0px;
        margin: 0px;
        outline: none;
        box-shadow: none;
      }
    }
  }

  .modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
    color: #272727;
  }
}

.careActTable {
  border: 0px;

  thead th {
    font-weight: 500;
    font-size: 12px;
    color: #8e949d;
    background: #f7f8f9;
    border: 0px !important;
    text-transform: capitalize;
    vertical-align: middle !important;
    height: 40px;
  }

  tbody td {
    border: 0px !important;
    font-weight: 500;
    font-size: 12px;
    color: #424242;
  }
}

.profitTable {
  @include responsive(605px) {
    margin-bottom: 15px;
  }

  .colorText {
    color: #0e79b6;
    font-weight: 500;
  }

  thead th {
    text-transform: capitalize;

    &:first-child {
      border-right: 1px solid #e5e9f8;
    }
  }

  tbody td {
    &:first-child {
      border-right: 1px solid #e5e9f8;
    }
  }

  .paddingCell {
    padding-left: 3rem !important;
  }
}

.mobileSpacing {
  @include responsive(605px) {
    flex-direction: column;
    align-items: flex-start;
  }

  h4 {
    @include responsive(605px) {
      margin-bottom: 15px;
    }
  }

  button {
    @include responsive(605px) {
      font-size: 12px;
      padding: 0px 5px;
    }
  }
}

.splitDialogTable {
  margin-bottom: 15px !important;

  thead {
    th {
      background-color: transparent;
      border: 0px !important;
      padding: 0px !important;
      border: 0px !important;
    }
  }

  tbody {
    tr {
      border-radius: 4px;
    }

    td {
      font-weight: 500;
      font-size: 12px;
      color: #424242;
      border: 0px;
      height: 43px;
      padding: 0px 16px !important;
    }
  }

  .colorBlue {
    color: #0e79b6 !important;
  }
}

.primaryHeadingSubtitle {
  font-size: 14px;
  font-weight: 400;
}

.input-group,
input {
  &:focus {
    border-color: transparent;
    box-shadow: none;
  }
}

.input-group,
input {
  &:hover {
    box-shadow: none;
  }
}

.form-control:disabled,
.form-control[readonly],
.diable-form-group {
  background-color: #e9ecef !important;
  opacity: 1;
  border-radius: 5px;
}

.form-control:focus {
  box-shadow: none !important;
}

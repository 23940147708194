@import "../../styles/mixins";
@import "../../styles/variables";

.anchorColorText {
  font-weight: 500;
  font-size: 12px;
  color: #2e95b4;
}

.accountingDashboardFilter {
  .input-group {
    max-width: 352px;
  }
}

.candidateSourceVia {
  padding: 19px 23px;
  border-radius: 4px;
  background: rgba(229, 233, 248, 0.22);

  label {
    font-weight: bold !important;
    font-size: 12px;
    color: #272727 !important;
    display: block;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  h6 {
    font-weight: 500;
    font-size: 14px;
    color: #0e79b6;
  }
}

@import '../../styles/variables';
@import '../../styles/mixins';

.avatar-profile {
  @include flexAlignItemCenter(flex);
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $GREY_ONE;
  margin-right: 16px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.dashboard-stats {
  display: flex;
  margin-bottom: 32px;

  @include responsive(1199px) {
    flex-direction: column;
  }

  .user-card {
    background-color: $WHITE;
    padding: 22px;
    width: 20%;
    border: 1px solid $WHITE_TEN;
    @include flexContentCenter(flex);
    flex-direction: column;

    @include responsive(1199px) {
      width: 100%;
      align-items: center;
    }
  }

  .stats {
    width: 80%;

    @include responsive(1199px) {
      width: 100%;
    }
  }
}

.dashboard {
  margin-bottom: 10px;
  @include flexAlignCenter(flex);

  @include responsive(1199px) {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  h4 {
    margin: 0px;
    font-family: $INTER_FONT;
    font-weight: 400;
    font-size: 24px;
    color: #272727;

    @include responsive(767px) {
      margin-bottom: 15px;
    }

    @include responsive(1199px) {
      font-size: 17px;
    }
  }

  h5 {
    font-weight: 400;
    font-size: 24px;
    color: #272727;
  }
}

.level-tables {
  table {
    thead {
      th {
        &:nth-child(1) {
          width: 25%;
        }

        &:nth-child(2) {
          width: 65%;
        }

        &:nth-child(3) {
          width: 10%;
        }
      }
    }
  }
}

select.position {
  border: 0px;
  background-color: $WHITE_THREE;
  width: 65px;
}

.switch {
  display: inline-block;
  width: 60px;
  height: 26px;
  position: relative;

  input {
    display: none;
  }
}

.switch-contact {
  display: inline-block;
  width: 60px;
  height: 26px;
  position: relative;
  margin-bottom: 0px;

  input {
    display: none;
  }
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  background: #efefef;
  cursor: pointer;
  border: 2px solid transparent;
  overflow: hidden;
  transition: 0.4s;
  padding: 1px 12px 0px !important;

  &:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 30px;
    transform: translateX(-10px);
    transition: 0.4s;
    background: $WHITE_NINE;
  }
}

input:checked + .slider {
  &:before {
    transform: translateX(21px);
    background: $GREEN_ONE;
  }
}

input:checked + .slider {
  background: $WHITE_ALPHA;
}

.dashboard-main {
  .section-header {
    a {
      color: $PRIMARY_BUTTON_BACKGROUND !important;
    }
  }
}

$AvenirRegular: 'AvenirNextLTProRegular';
$AvenirMedium: 'AvenirNextLTPro-Medium';
$INTER_FONT: 'Inter', sans-serif;

$THEME_COLOR: #f5f7f9;

$WHITE: #ffffff;
$WHITE_ONE: #edf4fb;
$WHITE_TWO: #e5f6f8;
$WHITE_THREE: #f7f8f9;
$WHITE_FOUR: #fcfdfe;
$WHITE_FIVE: #f7f7f7;
$WHITE_SIX: #ecf5ff;
$WHITE_SEVEN: #fafafa;
$WHITE_EIGHT: #e9ecef;
$WHITE_NINE: #96a1ac;
$WHITE_TEN: #f0f1f2;
$WHITE_ALPHA: #eaffed;
$WHITE_BETA: #ced4da;
$WHITE_LIGHT_ONE: #f0f0f0;
$WHITE_LIGHT_TWO: #d9d9d9;

$PRIMARY_TEXT_COLOR: #33475b;
$PRIMARY_BUTTON_BACKGROUND: #eaa827;

$HEADER_SHADOW: 0px 2px 10px rgba(0, 0, 0, 0.15);
$BOX_SHADOW: 0px 0px 10px rgba(0, 0, 0, 0.15);
$BOX_SHADOW_ONE: 0px 0px 10px rgba(0, 0, 0, 0.5);

$BLACK: #000000;
$BLACK_ONE: #738699;
$BLACK_TWO: #565656;
$BLACK_THREE: #495057;
$BLACK_FOUR: #42526e;
$BLACK_FIVE: #525252;
$BLACK_SIX: #282828;
$BLACK_SEVEN: #363636;
$BLACK_EIGHT: #202020;
$BLACK_NINE: #4a4a4a;
$BLACK_TEN: #424242;
$BLACK_ELE: #272727;
$BLACK_TWELVE: #393b3d;

$LIGHT_GREY: #fafbfd;
$GREY: #c4c8cd;
$GREY_ONE: #c4c4c4;
$GREY_TWO: #c1cad3;
$GREY_THREE: #e5e9f8;
$GREY_FOUR: #e6e8eb;
$GREY_FIVE: #766f63;
$GREY_SIX: #e5e5e5;
$GRAY_SEVEN: #dfe1e6;
$GREY_EIGHT: #687786;
$GREY_NINE: #fff3db;
$GREY_TEN: #6c757d;
$GRAY_ELEVEN: #a3a3a3;
$GRAY_TWELVE: #eeeeee;
$GRAY_LIGHT: #686e78;

$BLUE: #2e95b4;
$BLUE_ONE: #00c4ff;
$BLUE_TWO: #104f8d;
$BLUE_THREE: #1977f3;
$BLUE_FOUR: #3366ea;
$BLUE_FIVE: #b8c9f4;
$BLUE_SIX: #58afff;
$BLUE_SEVEN: #3b5ca0;
$BLUE_NINE: #172b4d;
$BLUE_TEN: rgba(23, 162, 184, 0.6);
$BLUE_ELEVEN: #27b0ff;
$BLUE_ALPHA: #80bdff;
$BLUE_RGBA: rgba(0, 123, 255, 0.25);
$BLUE_BADGE: #ebfaff;
$BLUE_LIGHT: #7793af;
$BLUE_LINK: #2960ec;
$BLUE_STATUS: #0e79b6;

$YELLOW: #ffe4b0;
$YELLOW_ONE: #ffe2aa;
$YELLOW_TWO: #fff4e0;

$RED: #dc3545;
$RED_ONE: #be3740;
$RED_TWO: rgba(220, 53, 69, 0.7);
$RED_THREE: #de350b;
$RED_FOUR: #ffe5de;
$RED_FIVE: #de350b;

$GREEN: #28a745;
$GREEN_ONE: #308d3f;
$GREEN_TWO: #6bfe82;

$BADGE_ONE: #ffe8e2;
$BADGE_TWO: #e5f9ff;
$DK_BG: #282828;

$ORANGE_ONE: #eaa827;

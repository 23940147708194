@import "../../styles/mixins";
@import "../../styles/variables";

.notification-card {
  @include flexAlignCenter(flex);
  flex-wrap: wrap;
  padding: 14px 22px;
  border-bottom: 1px solid $WHITE_TEN;

  &:last-child {
    border-bottom: 0px;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    color: $PRIMARY_TEXT_COLOR;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    color: $PRIMARY_TEXT_COLOR;
    opacity: 0.6;
    margin: 0px;
  }

  .btn-secondary {
    background: $WHITE_THREE;
    border-color: $WHITE_THREE;
    font-size: 12px;
    color: $PRIMARY_TEXT_COLOR;
  }
}

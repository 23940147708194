@import "../../../styles/variables";
@import "../../../styles/mixins";

.sidebar {
  max-width: 196px;
  width: 100%;
  min-height: calc(100vh - 32px);
  background: $WHITE;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  float: left;
  overflow-y: auto;
  height: 100px;

  &.open {
    @include responsive(1024px) {
      left: 0px;
      box-shadow: $BOX_SHADOW_ONE;
      transition: all 0.4s ease-in-out;
    }
  }

  @include responsive(1024px) {
    position: absolute;
    left: -200px;
    transition: all 0.4s ease-in-out;
  }

  .navbar {
    flex-direction: column;
    flex: 1;
    float: left;
    overflow-y: auto;
    height: 100px;
    padding: 0px;

    @include responsive(600px) {
      max-height: 600px;
      height: 100%;
      overflow-y: auto;
    }
  }
}

.sidebar-logo {
  padding: 15px 22px 39px;
}

.navbar-nav.navbar-menu {
  width: 100%;
  flex: 1;
  padding: 0px 10px;
  overflow-y: auto;
  height: 400px;

  a {
    width: 100%;
    height: 44px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    padding: 0px 12px;
    margin-bottom: 5px;
    text-decoration: none;
    font-family: $INTER_FONT;
    color: #686e78;
    @include flexAlignItemCenter(flex);

    &:hover,
    &.is-active {
      color: $PRIMARY_BUTTON_BACKGROUND;
      background: rgba(234, 168, 39, 0.06);

      svg {
        fill: $PRIMARY_BUTTON_BACKGROUND;

        path {
          fill: $PRIMARY_BUTTON_BACKGROUND;
          transition: all 0.3s ease-in;
        }
      }

      .stroke {
        fill: none !important;

        path {
          stroke: $PRIMARY_BUTTON_BACKGROUND;
          fill: none;
          transition: all 0.3s ease-in;
        }
      }
    }
  }

  .nav-icon {
    width: 33px;
  }

  .divider {
    height: 1px;
    background: $WHITE_THREE;
    margin: 0px 0px 15px;
  }
}

.nav.navbar-bottom {
  width: 100%;
  padding: 15px 22px;

  li {
    width: 100%;
    font-size: 14px;
    font-family: $INTER_FONT;

    a {
      color: $RED_ONE;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-family: $INTER_FONT;
    }
  }

  .nav-icon {
    width: 30px;
  }
}

// mobile

.sidebar-mobile {
  @include responsiveMobile(1025px) {
    display: none;
  }
}

.hamburger {
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
  z-index: 9;

  @include responsiveMobile(1025px) {
    display: none;
  }

  .line {
    width: 30px;
    height: 4px;
    background-color: $PRIMARY_TEXT_COLOR;
    border-radius: 5px;
    display: block;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
  }

  &.is-active {
    .line {
      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }

      &:nth-child(3) {
        transform: translateY(-13px) rotate(-45deg);
      }
    }
  }
}

.dropdown-caret {
  position: relative;
  right: 30px;

  &:after {
    cursor: pointer;
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
    position: relative;
    top: 4px;
    left: 5px;
  }
}

.agreement-submenu {
  display: block;
}

.submenu-dropdown {
  padding: 0px 27px;
  transition: all 0.3s ease-in-out;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $PRIMARY_TEXT_COLOR;
    transition: all 0.3s ease-in-out;
  }
}

// custom scroll

.navbar-nav.navbar-menu {
  scrollbar-color: transparent transparent;
  scrollbar-width: 6px;
}

.navbar-nav.navbar-menu::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

.navbar-nav.navbar-menu::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.navbar-nav.navbar-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: transparent;
}

.CandidateTabsWrapper .navTabsOverflow::-webkit-scrollbar-track,
.breadcrumbComponent::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: transparent;
}

.CandidateTabsWrapper .navTabsOverflow::-webkit-scrollbar,
.breadcrumbComponent::-webkit-scrollbar {
  height: 4px;
  background-color: transparent;
}

.CandidateTabsWrapper .navTabsOverflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #f0f1f2;
}

.breadcrumbComponent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d4d4d4;
}

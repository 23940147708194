@font-face {
  font-family: "AvenirLTStd-Roman";
  src: url("./AvenirLTStd-Roman.eot");
  src: url("./AvenirLTStd-Roman.eot?#iefix") format("embedded-opentype"),
    url("./AvenirLTStd-Roman.svg#AvenirLTStd-Roman") format("svg"),
    url("./AvenirLTStd-Roman.ttf") format("truetype"),
    url("./AvenirLTStd-Roman.woff") format("woff"),
    url("./AvenirLTStd-Roman.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

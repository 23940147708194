@import "../../styles/variables";
@import "../../styles/mixins";

.login-wrapper {
  .user-form-inner {
    padding: 0px 40px;
    box-shadow: none;

    @include responsive(767px) {
      padding: 0px;
    }

    .form-footer {
      margin-top: 40px;

      .btn.btn-primary {
        min-width: 229px;
        padding: 14px 10px;
      }
    }
  }

  .user-form {
    background-image: none;

    .logo-header {
      margin-bottom: 48px;
    }
  }

  h6 {
    margin-bottom: 0px;
  }

  .Card-layout{
    border-radius: 8px;
    padding: 24px 32px 32px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    margin: 0 auto;
  }


}

@font-face {
  font-family: "AvenirNextLTPro-Light";
  src: url("./AvenirNextLTPro-Light.eot");
  src: url("./AvenirNextLTPro-Light.eot?#iefix") format("embedded-opentype"),
    url("./AvenirNextLTPro-Light.svg#AvenirNextLTPro-Light") format("svg"),
    url("./AvenirNextLTPro-Light.ttf") format("truetype"),
    url("./AvenirNextLTPro-Light.woff") format("woff"),
    url("./AvenirNextLTPro-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@import "../../../styles/variables";
@import "../../../styles/mixins";

.section-header {
  @include flexAlignCenter(flex);
  padding: 15px 0px;

  @include responsive(375px) {
    padding: 20px 10px;
  }

  a {
    color: $PRIMARY_BUTTON_BACKGROUND !important;
  }

  h5 {
    font-weight: 800;
    font-size: 12px;
    margin: 0px;

    span {
      margin-left: 10px;
      font-weight: 500;
      font-size: 12px;
      color: $WHITE_NINE;
    }
  }
}

.section-header-add-client-notes {
  @include flexAlignCenter(flex);
  padding: 15px 22px 0px;
  border-radius: 10px 10px 0px 0px;
  color: $WHITE;

  @include responsive(375px) {
    padding: 20px 10px;
  }

  a {
    color: $PRIMARY_BUTTON_BACKGROUND !important;
  }

  h5 {
    font-weight: 500 !important;
    font-size: 14px !important;
    margin: 0px;
    color: #000000;

    span {
      margin-left: 10px;
      font-weight: 500;
      font-size: 12px;
      color: $WHITE_NINE;
    }
  }
}

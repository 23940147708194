@font-face {
  font-family: "AvenirNextLTPro-Demi";
  src: url("./AvenirNextLTPro-Demi.eot");
  src: url("./AvenirNextLTPro-Demi.eot?#iefix") format("embedded-opentype"),
    url("./AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi") format("svg"),
    url("./AvenirNextLTPro-Demi.ttf") format("truetype"),
    url("./AvenirNextLTPro-Demi.woff") format("woff"),
    url("./AvenirNextLTPro-Demi.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

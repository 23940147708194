@import "../../../styles/variables";
@import "../../../styles/mixins";
.form-group {
  margin-bottom: 32px;
  text-align: left;
}

.user-form-inner {
  .form-control {
    background: $WHITE_FOUR;
    width: 100%;
    border: 1px solid $GREY_THREE;
    border-radius: 4px;
    font-size: 16px;
    color: $PRIMARY_TEXT_COLOR;
    height: 46px;
    line-height: normal;
    padding: 11px 16px 9px;

    &:focus {
      background: $WHITE_FOUR;
      border: 1px solid $BLUE_TWO;
      color: $PRIMARY_TEXT_COLOR;
      box-shadow: none;
    }
  }
  label {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 3px;
    color: $PRIMARY_TEXT_COLOR;
  }
}

.error {
  color: $RED;
  font-size: 13px;
  margin-top: 4px;
  min-height: 20px;
}

.for-icon-placeholder {
  position: relative;
  .icon {
    position: absolute;
    right: 16px;
    z-index: 9;
    top: 53%;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  input.form-control {
    padding-right: 50px;
  }
}

.input-group {
  &:hover {
    border-radius: 4px;
    border-color: $BLUE_ALPHA;
    box-shadow: 0 0 0 0.2rem $BLUE_RGBA;
  }
  .with-spinner.form-control {
    border-right: 0px;
    &:focus {
      box-shadow: none;
    }
  }
  .input-group-append {
    display: flex;
    align-items: center;
    border: 1px solid $GREY_THREE;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 0px;
    padding: 10px;
    background-color: $WHITE_FOUR;
    .spinner {
      color: $PRIMARY_BUTTON_BACKGROUND;
    }
  }
}

.form-group-heading {
  @include flexAlignCenter(flex);
  line-height: 25px;
}

.select-sorting {
  select {
    // min-width: 10px !important;
    // max-width: 80px !important; 
    // width: auto;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: hidden;
    // box-sizing: border-box; 
    background-position: center right 4px !important;
  }
}
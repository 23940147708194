@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700;900&display=swap');
@import './fonts/AvenirLTStd-Roman/AvenirLTStdRoman.css';
@import './fonts/AvenirNextLTPro-Demi/AvenirNextLTProDemi.css';
@import './fonts/AvenirNextLTPro-Light/AvenirNextLTPro-Light.css';
@import './fonts/AvenirNextLTPro-Medium/AvenirNextLTProMedium.css';
@import './fonts/AvenirNextLTPro-Regular/AvenirNextLTProRegular.css';
@import 'variables';
@import 'mixins';

@import url('https://fonts.cdnfonts.com/css/arial');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

html,
body {
  scroll-behavior: smooth;

  @media screen and (max-device-height: 400px) and (orientation: landscape) {
    overflow: auto !important;
  }
}

body {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px;
  color: $PRIMARY_TEXT_COLOR;
  display: block !important;

  @include responsive(575px) {
    font-size: 14px;
  }
}

button,
button:focus {
  outline: none !important;
}

a {
  color: $PRIMARY_TEXT_COLOR;
  transition: all 0.3s ease-in;
  text-decoration: none !important;
  cursor: pointer;

  &:hover,
  &.active {
    color: $PRIMARY_BUTTON_BACKGROUND;
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}

.text-xs {
  font-size: 12px !important;
}

.text-sm {
  font-size: 14px !important;
}

p {
  word-wrap: break-word;
}

.css-yk16xz-control,
.css-hs5iqp-control {
  border-color: #e5e9f8 !important;
  border-radius: 4px !important;
  font-weight: 400;
  font-size: 12px;
  color: #9ba1a8;
}

.css-1pahdxg-control {
  font-weight: 400;
  font-size: 12px;
  color: #9ba1a8;
}

.input-group-text {
  height: 36px;
  // border: 1px solid #e5e9f8 !important;
  border-right: 0px !important;
}

input.form-control,
select.form-control {
  border: 1px solid #e5e9f8;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  height: 36px;
}

.container {
  max-width: 1230px;

  &.sm {
    max-width: 1030px;
  }

  &.xs {
    max-width: 830px;
  }
}

.navbar-expand-md {
  .navbar-nav {
    .nav-link {
      padding: 0;
    }
  }
}

.nav,
.navbar,
.nav-link {
  padding: 0;

  .navbar-brand {
    padding: 0;
    margin: 0;
    max-width: 119px;
  }

  .nav-link {
    padding: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
label,
ul,
p {
  font-weight: normal;
  margin: 0;
}

strong {
  font-weight: bold !important;
}

h1 {
  font-size: 40px;
  font-family: 'Inter', sans-serif;
  line-height: 43px;

  @include responsive(991px) {
    font-size: 32px;
    line-height: normal;
  }

  @include responsive(767px) {
    font-size: 28px;
  }

  >span {
    font-size: 16px;
    font-family: 'Inter', sans-serif;
  }
}

h2,
.h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: $PRIMARY_TEXT_COLOR;

  @include responsive(1279px) {
    font-size: 30px;
    line-height: normal;
  }

  @include responsive(767px) {
    font-size: 26px;
  }
}

h3 {
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  line-height: 36px;

  @include responsive(1279px) {
    font-size: 22px;
    line-height: normal;
  }

  @include responsive(767px) {
    font-size: 18px;
  }
}

h4 {
  font-size: 22px;
  font-family: 'Inter', sans-serif;
  line-height: 33px;

  @include responsive(767px) {
    font-size: 18px;
  }
}

h5 {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: #33475b;

  @include responsive(575px) {
    font-size: 16px;
    line-height: normal;
  }
}

h6 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $PRIMARY_TEXT_COLOR;
  margin-bottom: 48px;

  @include responsive(575px) {
    font-size: 14px;
  }
}

.text-12 {
  font-size: 12px !important;
}

.text-greylight {
  color: $GRAY_LIGHT !important;
}

.text-greylight {
  color: $GRAY_LIGHT !important;
}

.text-blackten {
  color: $BLACK_TEN !important;
}

.text-blackele {
  color: $BLACK_ELE !important;
}

.flexBoxes {
  flex: 1;
  border: 1px solid #F0F1F2; 
  display: flex; 
  justify-content: center;
  align-items: center;
  height: 120px;
  flex-direction: column;
}

.header-cell, .content-cell {
  border-bottom: 1px solid #dee2e6;
  width: 10%;
  vertical-align: middle !important;
}

.header-cell {
  color: #8E949D;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  background-color: #f8f9fa; /* optional background color for header cells */
  min-width: 208px;
}

.content-cell {
  width: 208px;
  background-color: #fff; 
  text-align: center;
}

.content-highlight {
  background-color: #D1FAE5; 
}

.table-label{
  font-size: 16px;
  font-weight: 600;
}

.btn-outline_prime {
  border-color: #eaa827 !important;
  border-style: solid;
  border-width: 0 0 1px 0 !important;
  background-color: transparent;
  color: #eaa827 !important;
  font-size: 14px;
  border-radius: 0 !important;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    color: inherit;
    border-width: 0 0 1px 0;
    box-shadow: none !important;
  }

  &.border-none {
    border: none !important;
  }

  &>svg {
    margin-right: 0;
  }
}

.btn-outline-warning {
  background-color: transparent !important;
  border: 1px solid #eaa827 !important;
  color: #eaa827 !important;
}

input,
textarea,
select {
  &:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: $PRIMARY_TEXT_COLOR;
  }
}

textarea.form-control {
  height: 150px;
  resize: vertical;
  font-size: 12px;
}

textarea.h-150 {
  height: 150px !important;
}
textarea.h-350 {
  height: 350px !important;
}

input::placeholder,
textarea::placeholder {
  color: rgba($color: $PRIMARY_TEXT_COLOR, $alpha: 0.5) !important;
}

.main-section {
  padding: 20px 0;
  min-height: 100vh;
  @include flexExactCenter(flex);
}

select {
  -webkit-appearance: none;
  background-image: url('../assets/images/select-arrow.svg');
  background-repeat: no-repeat;
  background-position: center right 10px;
}

.modal {
  .modal-header {
    border-bottom: 1px solid $WHITE_TEN;

    .close {
      display: none;
    }
  }

  .modal-footer {
    border: 0px;
  }
}

.error-loading {
  @include flexExactCenter(flex);
  width: 100%;
  height: 100vh;
  font-size: 20px;

  &.sm-height {
    height: 60vh;
  }

  &.xs-height {
    height: 30vh;
  }

  &.auto-height {
    height: auto !important;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.loader {
  margin: 0px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 7px solid rgba($color: $GREY_ONE, $alpha: 0.2);
  border-right: 7px solid rgba($color: $GREY_ONE, $alpha: 0.2);
  border-bottom: 7px solid rgba($color: $GREY_ONE, $alpha: 0.2);
  border-left: 7px solid $PRIMARY_BUTTON_BACKGROUND;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.small-error-loading {
  font-size: 20px;
}

.small-loader,
.small-loader:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.small-loader {
  margin: 0px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid rgba($color: $GREY_ONE, $alpha: 0.2);
  border-right: 2px solid rgba($color: $GREY_ONE, $alpha: 0.2);
  border-bottom: 2px solid rgba($color: $GREY_ONE, $alpha: 0.2);
  border-left: 2px solid $PRIMARY_BUTTON_BACKGROUND;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.text-capitalize.badge,
.synonym-badge.badge {
  padding: 5px 13px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 10px;
  display: inline-flex;

  &.badge-success {
    background: #d1fae5;
    color: #065f46;
  }

  &.badge-warning {
    background: rgba(234, 168, 39, 0.1);
    color: #eaa827;
  }

  &.badge-primary {
    color: #686e78 !important;
    background: #e5e5e5 !important;
  }

  &.badge-danger {
    background-color: #fee6e4;
    color: #fb4a3f;
  }

  &.badge-info {
    background-color: $BLUE_TEN;
  }
}

.synonym-badge.badge span {
  color: #065f46 !important;
  text-transform: none !important;
}

.btn-light-yellow {
  background-color: #ffffff !important;
  border: 1px solid #eaa827 !important;
  border-radius: 4px !important;
  color: #eaa827 !important;
}

.text-myWarning {
  color: $ORANGE_ONE !important;
}

.text-count.badge {
  width: 20px;
  height: 20px;
  font-weight: 500;
  font-size: 10px;
  border-radius: 50%;
  padding: 0px;
  @include flexExactCenter(flex);
  line-height: inherit;

  &.badge-success {
    background: $WHITE_ALPHA;
    color: $GREEN_ONE;
  }
}

.bg-whiteseven {
  background-color: $WHITE_SEVEN !important;
}

.dashboard-profile-img {
  border-radius: 50%;
  object-fit: cover;
}

.text-blue-link {
  color: $BLUE !important;

  &:hover {
    text-decoration: none !important;
  }
}

.text-blackten {
  color: $BLACK_TEN !important;
}

.mt-3_5 {
  margin-top: 1.2rem !important;
}

.page-not-found {
  text-align: center;
  background: $WHITE_THREE;
  padding-top: 76px;

  .inner {
    padding-bottom: 100px;
    background: url('../assets/images/page-not-found-bg.svg') no-repeat;
    background-position: center 103px;
    background-size: cover;

    img {
      margin-bottom: 48px;
    }

    h1 {
      font-size: 36px;
      line-height: 51px;
      margin-bottom: 12px;
    }

    p {
      font-size: 18px;
      opacity: 0.7;
      line-height: 24px;
      max-width: 587px;
      width: 100%;
      margin: 0 auto 40px;
    }
  }
}

.Toastify__toast {
  border-radius: 4px;
  padding: 10px 15px;
}

.input-group {
  .with-spinner.form-control {
    border: 1px solid $GREY_THREE;
    border-right: 0px;
  }
}

// reports
.reports-wrapper {
  .card {
    border: 0px;
    border-radius: 0px 0px 4px 4px;
  }

  .reports-name {
    padding: 15px 0px;

    a {
      color: $BLUE;

      &:hover {
        color: $PRIMARY_BUTTON_BACKGROUND;
      }
    }
  }
}

.multiselect {
  .no-data-found {
    height: 220px;
    font-size: 15px;
  }
}

// radio component
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px !important;
  user-select: none;
  line-height: 27px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $GRAY_SEVEN;
    border-radius: 50%;
  }

  input~.checkmark {
    background-color: $GRAY_SEVEN;
  }

  input:checked~.checkmark {
    background-color: $GREEN_ONE;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: $WHITE;
  }

  input:checked~.checkmark:after {
    display: block;
    top: 8px;
    left: 8px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: $WHITE;
  }
}

.d-flex-field {
  label {
    margin: 0px;
    font-size: 12px;
    color: #33475b;
    line-height: 27px;
  }
}

.create-agreement-radio.d-flex-field {
  label {
    line-height: 23px;
  }
}

// for modal styling
.modal {
  .modal-dialog {
    &.create-contact-modal {
      transform: translate(0%, 0%) !important;
    }
  }
}

.for-modal-styling {
  &.modal-dialog {
    max-width: 600px;
    margin: 0;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;

    @media only screen and (max-width: 767px) {
      max-width: 95%;
    }
  }

  .modal-title {
    font-weight: 500;
    font-size: 20px;
    color: $BLUE_NINE;
    text-transform: capitalize;
  }

  .modal-footer {
    .btn {
      text-transform: capitalize;
      font-size: 14px;
    }
  }

  .modal-close {
    color: $BLACK_FOUR;
    background-color: transparent;
    border: transparent;
  }
}

.agreement-detials {
  &.card {
    background: $WHITE;
    margin: 32px 0px;
    background: #ffffff;
    border: 1px solid #eaa827;
    border-radius: 4px;
  }

  label {
    font-weight: 400;
    font-size: 14px;
    color: #686e78;
  }

  h6 {
    font-weight: normal;
    font-size: 16px;
    color: $PRIMARY_TEXT_COLOR;
    margin: 0px;

    a {
      color: $PRIMARY_TEXT_COLOR;
    }
  }

  .view-agreement-text {
    margin-bottom: 30px;
  }
}

.heading-border {
  @include flexAlignCenter(flex);
  border-bottom: 1px solid $WHITE_TEN;
  padding: 14px 22px;
  min-height: 68px;

  span {
    font-size: 12px;
  }

  h5 {
    margin: 0px;
    font-weight: bold;
    font-size: 12px;
    color: #272727;
  }

  .btn-secondary {
    background-color: $PRIMARY_TEXT_COLOR;

    @include responsive(360px) {
      font-size: 12px;
    }
  }
}

.for-temp-ol {
  ol {
    padding-left: 1rem;
  }

  ul {
    padding-left: 1rem;
  }
}

.width-85 {
  width: 85%;
}

.width-15 {
  width: 15%;
}

.p-l-3 {
  padding-left: 20px !important;
}

.mt-section-4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mb-section-4 {
  margin-bottom: 32px;
}

.link-tag {
  color: $PRIMARY_BUTTON_BACKGROUND;

  &:hover {
    color: $PRIMARY_BUTTON_BACKGROUND;
  }

  svg {
    margin-right: 10px;
  }
}

.Toastify__toast {
  &.Toastify__toast--default {
    width: 408px;
    background: $BLUE_BADGE;
    padding: 17px 20px;
    margin: 2px;
    margin-top: 20px;
    border: 1px solid $BLUE;
    @include flexAlignItemCenter(flex);

    @include responsive(480px) {
      width: 90%;
    }

    .Toastify__toast-body {
      font-weight: 800;
      font-size: 12px;
      color: $PRIMARY_TEXT_COLOR;

      p {
        margin: 0px;
      }
    }
  }

  & .Toastify__close-button--default {
    color: $PRIMARY_TEXT_COLOR;
    opacity: 1;
    width: 40px;
    height: 40px;
  }
}

.Toastify__toast-container--bottom-right {
  right: 7em !important;
}

// notifications
.notifications {
  h6 {
    font-weight: 800;
  }

  button {
    border: whitesmoke;
    width: 50px;
    background-color: transparent;
    height: 50px;
  }
}

.editor-height {
  .ql-editor {
    height: 180px;
  }

  .ql-toolbar.ql-snow {
    border-radius: 4px 4px 0px 0px;
  }

  .ql-container.ql-snow {
    border-radius: 0px 0px 4px 4px;
  }

  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: 1px solid $GREY_THREE;
  }
}

.editor-height-for-body {
  .ql-editor {
    height: 400px;
  }

  .ql-toolbar.ql-snow {
    border-radius: 4px 4px 0px 0px;
  }

  .ql-container.ql-snow {
    border-radius: 0px 0px 4px 4px;
  }

  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: 1px solid $GREY_THREE;
  }
}

.editor-height-job-order {
  .ql-editor {
    height: 400px;
  }

  .ql-toolbar.ql-snow {
    border-radius: 4px 4px 0px 0px;
  }

  .ql-container.ql-snow {
    border-radius: 0px 0px 4px 4px;
  }

  .ql-toolbar.ql-snow,
  .ql-container.ql-snow {
    border: 1px solid $GREY_THREE;
  }
}

.notes-scrollable {
  height: 207px;
  overflow-y: auto;
}

.text-ellipsis {
  @include ellipsis();
  width: 100px;
}

#merge-companies {
  margin: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

#noteModal {
  max-width: 650px;
  margin: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;

  ul {
    padding-left: 20px;
  }
}

.view-note {

  ol,
  ul {
    padding-left: 20px;
  }
}

.delete-action-btn.btn-secondary {
  background-color: transparent;
  border: 0px;
  padding: 0px 15px 0px 0px;

  &:hover,
  &:focus {
    background-color: transparent !important;
  }

  img {
    margin: 0px;
  }
}

.table {
  tbody {
    td {
      &.td-break-text {
        max-width: 300px;
        white-space: break-spaces;
      }
    }
  }
}

.temp-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.list-remove-marker {
  ol {
    list-style-type: none;
  }

  li {
    ::marker {
      display: none;
    }
  }
}

.for-link-theme-color {
  color: $PRIMARY_BUTTON_BACKGROUND !important;
  font-size: 13px;
}

.border-divider {
  border-bottom: 1px solid #e4e4e4;

  &:last-child {
    border-bottom: 0px;
  }
}

.for-word-break {
  word-break: break-all;
}

.for-wordfull-break {
  word-break: break-word;
}

.async-dropdown {
  width: 300px;
  margin-right: 24px;

  @include responsive(767px) {
    width: 100% !important;
    margin-right: 0px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 260px;
  }
}

.for-mobile-padding {
  @include responsive(767px) {
    padding-top: 15px !important;
  }
}

.owner-sharee {
  margin-left: 8px;
  margin-top: 2px;
  word-break: break-all;
}

.padding-edit-button {
  padding-top: 0.5rem !important;
  padding-left: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 0.1rem !important;
}

.radioWrapperWidth {
  width: 600px;
}

.create-agreement-radio {
  @include responsive(1199px) {
    flex-wrap: wrap;
  }

  .radio-container {
    @include responsive(1199px) {
      margin-bottom: 10px;
    }
  }
}

.dollar-addon-btn {

  // .input-group-text {
  //   background-color: transparent;
  //   font-weight: 500;
  //   font-size: 16px;
  //   color: $WHITE_NINE;
  //   border-right: 0px;
  // }
  input {
    border-left: 0px;
    padding-left: 0px;

    &:focus {
      box-shadow: none;
      border-color: $WHITE_BETA;
    }
  }
}

.relocate-allowed {
  @include flexAlignItemCenter(flex);
  min-height: 38px;

  .radio-container {
    & .checkmark {
      width: 20px;
      height: 20px;
      margin-top: 4px;

      &:after {
        width: 10px;
        height: 10px;
      }
    }

    input:checked~.checkmark:after {
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
    }
  }

  .form-check {
    @include responsive(1024px) {
      width: 100%;
    }
  }
}

.btn.btn-secondary {
  background-color: $PRIMARY_TEXT_COLOR;
}

.publish-ad-post {
  background-color: $WHITE;
  padding: 20px;

  h5 {
    font-weight: 800;
    font-size: 16px;
    color: $PRIMARY_TEXT_COLOR;
    margin-bottom: 24px;
  }

  h6 {
    font-weight: normal;
    font-size: 16px;
    color: $PRIMARY_TEXT_COLOR;
    margin-bottom: 12px;
  }

  label {
    font-weight: normal;
    font-size: 14px;
    color: $BLUE_NINE;
  }
}

.remove-table-margin {
  table {
    margin-bottom: 0px;
  }
}

.qualification-ellipsis {
  width: 300px;
  @include ellipsis();
}

.nextbtn-Pagination {
  transform: rotate(180deg);
  position: relative;
  top: 1px;
}

.users-pagination-style {
  width: 90px;
  height: 24px;
  font-weight: 500 !important;
  font-size: 12px !important;
  letter-spacing: 0.2px;
  color: #534d47;
  background: #ffffff;
  border: 1px solid #9a9693 !important;
  border-radius: 1000px;
  padding: 2px 20px 2px 12px;
  margin: 0px 8px;
  background-position: center right 10px;
  background-repeat: no-repeat;
  background-image: none;
}

.users-pagination {
  background: #fff;
  border-top: 0px;
  padding: 3px 24px;
  display: flex;
  justify-content: space-between;

  @include flexAlignItemCenter(flex);

  @include responsive(1024px) {
    border-left: 0px;
    border-right: 0px;
  }

  @include responsive(767px) {
    flex-wrap: wrap;
  }

  @include responsive(572px) {
    padding: 5px 5px;
  }

  .paginationItemsSelect {
    display: flex;
    align-items: center;
  }

  .paginationItemsSelect p {
    margin: 0px;
    font-weight: 400;
    font-size: 12px;
    color: #352e27;
    letter-spacing: 0.2px;
    text-transform: initial;
  }

  .divider {
    width: 1px;
    height: 20px;
    margin: 0px 5px;
    background-color: #e5e9f8;
  }

  .paginationItemsSelect select,
  input {
    width: 90px;
    height: 24px;
    font-weight: 500 !important;
    font-size: 12px !important;
    letter-spacing: 0.2px;
    color: #534d47;
    background: #ffffff;
    border: 1px solid #9a9693 !important;
    border-radius: 1000px;
    padding: 2px 20px 2px 12px;
    margin: 0px 8px;
    background-position: center right 10px;
    background-repeat: no-repeat;
    background-image: url('../assets/images/dropdown-arrow.svg');
  }

  input {
    background-image: none;
  }

  .paginationItemsSelect label {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #9a9693;
    margin: 0px;
  }

  .pagination {
    margin: 0px;
  }

  .form-group-heading {
    display: none;
  }

  .form-group {
    margin: 0px;

    @include responsive(767px) {
      margin: 15px 0px !important;
    }
  }

  .pagination-container {
    padding: 0px 8px;
  }

  .pagination-display {
    @include flexAlignItemCenter(flex);
    flex-wrap: wrap;

    @include responsive(767px) {
      margin: 15px 0px;
    }
  }
}

.users-pagination {
  .nextbtn-Pagination {
    transform: rotate(180deg);
  }
}

// theme
.theme-switch {
  @include flexAlignItemCenter(flex);

  .slider:before {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: $WHITE;
  }

  .slider {
    background: $PRIMARY_TEXT_COLOR;
  }

  .switch {
    width: 40px;
    height: 20px;
    top: 3px;
  }

  input:checked+.slider:before {
    transform: translateX(9px);
  }
}

.sliderNotes {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  background: #eaffed;
  cursor: pointer;
  border: 2px solid transparent;
  overflow: hidden;
  transition: 0.4s;
  padding: 1px 12px 0px !important;

  &:before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 30px;
    transform: translateX(-10px);
    transition: 0.4s;
    // background: $WHITE_NINE;
    background: #308d3f;
  }
}

input:checked+.sliderNotes {
  &:before {
    transform: translateX(21px);
    background: $GREEN_ONE;
  }
}

input:checked+.sliderNotes {
  background: $WHITE_ALPHA;
}

// preview job ad modal
.preview-job-modal {
  .modal-header {
    padding: 20px 25px 17px;

    .modal-title {
      font-weight: normal;
      font-size: 16px;
    }

    .previous-page {
      margin: 0px;

      a {
        &:hover {
          color: $PRIMARY_BUTTON_BACKGROUND;
        }
      }

      img {
        margin-right: 16px;
      }
    }
  }

  .modal-body {
    padding: 22px;

    .mb-4 {
      margin-bottom: 10px !important;
    }

    h5 {
      font-weight: 800;
      font-size: 16px;
      color: $PRIMARY_TEXT_COLOR;
      margin-bottom: 12px;
    }

    .description-grid {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $PRIMARY_TEXT_COLOR;
      list-style-type: none;

      li {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $PRIMARY_TEXT_COLOR;
        @include flexAlignItemCenter(flex);

        img {
          margin-right: 17px;
        }
      }
    }
  }

  .modal-footer {
    padding: 22px;

    .btn-secondary {
      background: $PRIMARY_BUTTON_BACKGROUND;
      border: 1px solid $PRIMARY_BUTTON_BACKGROUND;
      border-radius: 3px;
      min-width: 57px;
      margin: 0px;
    }
  }
}

.qualification-ellipsis {
  width: 300px;
  @include ellipsis();
}

.tool-tip {
  display: inline-block;
}

td.empty-table-td {
  padding: 0px !important;
}

.contact-phone-fields {
  @media only screen and (min-width: 1200px) and (max-width: 1920px) {

    .col-lg-6,
    .col-lg-3 {
      max-width: 50%;
      flex: 0 0 50%;
    }

    .col-lg-3.col-sm-4 {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.client-job-description {
  @media only screen and (min-width: 1025px) and (max-width: 1440px) {

    h5,
    button {
      font-size: 12px;
    }
  }
}

.notes-flex {
  @include flexAlignCenter(flex);
  border-bottom: 1px solid $WHITE_TEN;
  margin-bottom: 12px;
  padding-bottom: 12px;

  &:last-child {
    border-bottom: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}

.file-name-client {
  display: block;
  width: 120px;
  @include ellipsis();
}

.client-add-notes {
  top: 25px;
  right: 45px;
  position: fixed !important;
  max-width: 500px;
  width: 100%;
  border-radius: 10px !important;
  box-shadow: -2px 0px 16px rgba(0, 0, 0, 0.12);
  z-index: 999999;

  @include responsive(767px) {
    top: 10px;
    max-width: 95%;
    right: 10px;
  }

  .card-body {
    padding: 12px 22px !important;
  }
}

.cards-job-application {
  .job-application-search-grid {
    width: 20%;
    border-right: 1px solid $WHITE_TEN;

    &:last-child {
      border-right: 0px;
    }

    @include responsive(767px) {
      width: 100%;
      border-right: 0px;
      border-bottom: 1px solid $WHITE_TEN;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 50%;
      border-bottom: 1px solid $WHITE_TEN;
    }
  }

  .job-application-grid {
    width: 25%;
    border-right: 1px solid $WHITE_TEN;

    &:last-child {
      border-right: 0px;
    }

    @include responsive(767px) {
      width: 100%;
      border-right: 0px;
      border-bottom: 1px solid $WHITE_TEN;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 50%;
      border-bottom: 1px solid $WHITE_TEN;
    }
  }
}

.job-applicant-buttons {
  @include flexAlignItemCenter(flex);
  justify-content: flex-end;
  flex-wrap: wrap;

  @include responsive(767px) {
    justify-content: flex-start;
  }

  a {
    @include responsive(767px) {
      margin: 0px 15px 15px 0px !important;
    }
  }
}

.status-dropdown.w-50 {
  @include responsive(767px) {
    width: 100% !important;
    flex-direction: column;
  }

  .w-50 {
    @include responsive(767px) {
      width: 100% !important;
    }
  }
}

.job-applicant-content {
  .ml-4 {
    @include responsive(767px) {
      margin-left: 0px !important;
    }
  }

  .rounded {
    @include responsive(767px) {
      display: block !important;
      margin-bottom: 15px !important;
      width: 46%;
    }
  }

  .skills-badge {
    @include flexAlignItemCenter(flex);
    flex-wrap: wrap;
  }
}

.admin-tabs {
  h4 {
    font-family: $INTER_FONT;
    font-weight: 400;
    font-size: 24px;
    color: #272727;
    margin-bottom: 10px;
  }
}

.pdf-viewer {
  padding: 20px;
  font-weight: bold;
  background: $PRIMARY_TEXT_COLOR;
}

.pdf-actions {
  button {
    background: $BLUE_LIGHT !important;
    backdrop-filter: blur(4px);
    border-radius: 4px;
    margin: 0px 10px;
  }
}

.pdf-heading {
  padding-bottom: 24px;

  h4 {
    font-weight: 800;
    font-size: 16px;
    color: $WHITE;
    margin: 0px;
  }
}

.css-1wa3eu0-placeholder {
  white-space: nowrap;
  color: #000000 !important;
}

.react-pdf__Page__canvas {
  @include responsive(767px) {
    width: 100% !important;
  }
}

.react-pdf__Page__textContent {
  @include responsive(767px) {
    width: 100% !important;
    position: initial !important;
  }
}

.sequence-cards {
  @include flexAlignCenter(flex);
  flex-wrap: wrap;
  cursor: pointer;

  .card {
    background-color: $WHITE;
    width: 32%;
    padding: 20px;
    flex-direction: column;
    @include flexAlignItemCenter(flex);
    border: 1px solid $WHITE_TEN;
    border-radius: 4px;
    box-shadow: none;

    @include responsive(767px) {
      width: 100%;
      margin-bottom: 20px;
    }

    h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $BLUE;
      margin-bottom: 2px;
    }

    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: $PRIMARY_TEXT_COLOR;
      opacity: 0.6;
      margin: 0 0 10px 0;
    }
  }
}

.cursor-pointer {
  cursor: default;
  transition: cursor 0.5s ease;

  &:hover {
    cursor: pointer;
  }
}

.color-blue-text {
  color: $BLUE_ELEVEN !important;
}

.modal-btn-cancel.btn {
  background-color: transparent;
  border: 0px;
  color: #000000;
}

.multiselect {
  .avatar-label {
    label {
      margin-bottom: 0px !important;
    }
  }
}

.form-check {
  .form-check-input {
    margin-top: 8px;
  }
}

.form-check-input {
  @include responsive(1024px) {
    position: initial !important;
  }
}

.rich-text {

  dl,
  ol,
  ul {
    padding-left: 1rem;
  }
}

.rich-text {

  dl,
  ol,
  ul {
    li {
      span {
        color: #000000 !important;
      }
    }
  }

  p {
    span {
      color: #000000 !important;
    }
  }
}

.add-new-refrence .modal-body {
  max-height: 450px;
  overflow: auto;
}

.arrow-btn {
  height: 40px;
  margin: 10px;

  & img {
    margin: 0;
  }
}

.ellipsisText {
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noteSection {
  width: 100%;
  margin: 20px 0px 30px;
  border-bottom: 1px solid #e5e9f8;

  h5 {
    // display: flex;
    // align-items: center;
    // font-weight: 500;
    // font-size: 10px;
    // color: #9ba1a8;
    // position: relative;
    // top: 13px;
    // padding-right: 10px;
    // max-width: 160px;
    // background: #fff;
    display: inline-block;
    font-weight: 500;
    font-size: 10px;
    color: #9ba1a8;
    position: relative;
    top: 13px;
    padding-right: 10px;
    min-width: 80px;
    background: #fff;
  }

  // h5.shortTextWidth {
  //   width: 100px;
  // }
}

.stepper-grid {
  padding-top: 10px;
}

.table th,
.table td {
  white-space: inherit !important;
}

.table thead th {
  font-size: 13px;
}

.select-members {
  @include flexAlignItemCenter(flex);
  flex-wrap: wrap;

  label {
    @include ellipsis();
    width: 100px;
    margin-bottom: 0px;
  }
}

.avatar {
  margin-right: 9px;
}

.link-text-btn {
  color: #0e79b6 !important;
  font-weight: 500;
}

.forMobileResponsive {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include responsive(767px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0px 40px !important;
  }
}

.dividerDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0px 10px;
  background: #0e79b6;
}

label.switch {
  width: 60px;
}

.skillBadge {
  font-weight: 400;
  font-size: 10px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  min-height: 32px;
  background: #ffffff;
  border: 1px solid #f9b220;
  border-radius: 20px;
  text-transform: capitalize;
  min-width: 71px;
  margin: 0px 16px 15px 0px;
  overflow: hidden;
}

.dialogSectionSpacing {
  padding: 0px 22px 22px;

  h5 {
    font-weight: bold;
    font-size: 12px;
    color: #272727;
  }
}

.btn.text-info {
  font-size: 12px !important;
  color: #0e79b6 !important;
}

.revampDialogWidth.modal-dialog {
  max-width: 692px;
}

.revampDialog.modal-dialog {
  label {
    font-weight: 500;
    font-size: 12px;
    color: #272727;
  }

  .form-control {
    border: 1px solid #e5e9f8;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    min-height: 36px;
  }

  textarea.form-control {
    height: initial;
  }

  .css-yk16xz-control {
    border-color: #e5e9f8;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    color: #9ba1a8;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .modal-header {
    border: 0px;
    padding: 36px 36px 0px;

    @include responsive(767px) {
      padding: 15px 15px 0px;
    }
  }

  .modalHeaderCustom {
    padding: 36px 36px 0px;

    @include responsive(767px) {
      padding: 15px 15px 0px;
    }
  }

  .modal-body {
    padding: 10px 36px;

    @include responsive(767px) {
      padding: 15px;
    }
  }

  .modal-footer {
    padding: 0px 36px 30px;

    @include responsive(767px) {
      padding: 15px;
    }
  }

  .modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & img {
      margin: 0;
    }
  }

  h5 {
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
    color: #272727;
  }

  .modalBodyScroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eaeaea;
  }

  .modalBodyScroll::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  .modalBodyScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d4d4d4;
  }
}

.revampContainer {
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;

  @include responsive(767px) {
    flex-direction: column !important;
  }

  h5 {
    font-weight: bold;
    font-size: 12px;
    text-transform: capitalize;
    color: #272727;
  }
}

.switchLabel {
  font-size: 12px;
  font-weight: 500;

  @include responsive(767px) {
    margin-top: 15px !important;
  }
}

.darkButton {
  min-width: 79px;
  height: 36px;
  background: #424242;
  border: 1px solid #424242;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.darkButtonSpace {
  margin-top: 6px;
}

.numberBadge {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 10px;
  font-size: 10px;
  background-color: #fdf6e9;
}

.textButton {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #424242 !important;
  margin: 0px !important;
}

.contentGridColumn {
  width: 65%;

  @include responsive(1024px) {
    width: 100%;
  }
}

.candidateInterview {
  height: 660px !important;
  overflow-y: auto;
}

.headingSpanText {
  font-style: 400;
  font-weight: 500;
  font-size: 12px;
  color: #686e78;
  padding-left: 10px;
}

.transparentTable {
  border: 0px !important;

  tr {
    border: 0px !important;

    &:hover {
      box-shadow: none !important;
    }
  }

  thead th,
  tbody td,
  tfoot td {
    padding: 0px 0px 5px !important;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 12px;
    border: 0px !important;
    background-color: transparent !important;
    height: initial;
  }

  tfoot td {
    padding: 5px 0px 0px !important;
  }

  th {
    color: #686e78;
  }

  td {
    color: #424242;
  }

  .valueText {
    color: #686e78;
    padding-right: 16px;
  }

  tfoot td {
    border-top: 1px solid #e5e9f8 !important;
  }
}

.recruiterFolderList {
  thead th {
    width: 45%;

    &:last-child {
      width: 10%;
    }
  }

  th,
  td {
    padding: 12px 30px !important;
  }
}

.forHeadingAlign {
  position: absolute;
  z-index: 999;
}

.mobileResponsiveFlexAlign {
  display: flex;
  align-items: center;

  @include responsive(767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  & a {
    margin-top: 10px;
  }
}

.mobileResponsiveFlexTablet {
  display: flex;
  align-items: center;

  @include responsive(991px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.mobileResponsiveFlexDevice {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include responsive(480px) {
    // flex-direction: column;
    // align-items: flex-start;
    display: block;
  }

  .switchLabel {
    @include responsive(767px) {
      margin-top: 0px !important;
    }
  }
}

.billingSheetButton {
  button {
    @include responsive(480px) {
      margin-top: 10px !important;
    }
  }
}

.mobileResponsiveFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include responsive(767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  & a {
    margin-top: 10px;
  }
}

.doubleButtonStyle {
  gap: 10px;

  @include responsive(1150px) {
    margin-bottom: 15px;
  }

  button {
    @include responsive(767px) {
      margin: 0px 10px 10px 0px !important;
    }
  }
}

.checkboxTextStyle {
  font-weight: 400;
  position: relative;
  // bottom: 2px;
  bottom: -2px;
  padding-left: 0px;

  @media only screen and (min-width: 1025px) {
    bottom: -2px;
  }
}

.ellipsisTextStyle {
  @include ellipsis();
  display: block;
  width: 200px;
}

.billingSheetButtons {
  @include responsive(1024px) {
    margin-top: 15px !important;
  }
}

.addFolderButtons {
  button {
    @include responsive(767px) {
      margin-top: 15px !important;
    }
  }
}

.actionIconsGridWidth {
  width: 100px;
}

.jobOrderRevamp h5.noMarginHeading {
  margin-bottom: 0px !important;
}

.removeSpacingFormGroup .form-group {
  margin-bottom: 0px !important;
}

.emailLengthWidth {
  @include ellipsis();
}

.emailLengthWidthApproach a {
  width: 250px;
  display: block;
  @include ellipsis();
}

.addreferenceDialog {
  label {
    margin-bottom: 4px;
    line-height: 18px;
  }

  .form-group {
    margin-bottom: 8px;
  }
}

.pdf-viewer .text-truncate {
  @include responsive(767px) {
    font-size: 10px;
  }
}

.pdf-viewer-spacing {
  @include responsive(767px) {
    padding: 0px;
  }
}

.buttonSpacing {

  .btn.btn-primary,
  button {
    @include responsive(480px) {
      margin-bottom: 15px !important;
      margin-left: 0px !important;
    }
  }
}

.buttonSpacingTablet {

  .btn.btn-primary,
  button {
    @include responsive(991px) {
      margin-bottom: 15px !important;
      margin-left: 0px !important;
    }
  }
}

.buttonSpacingNew {

  .btn.btn-primary,
  button {
    @include responsive(560px) {
      font-size: 12px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    @include responsive(480px) {
      margin: 0px !important;
      margin-top: 15px !important;
    }
  }
}

.resumeFormGroup {
  width: 250px;
}

.jobOrderLonger {
  @include responsive(767px) {
    padding: 0px 0px 15px !important;
  }
}

.filterJobOrder {
  @media screen and (min-width: 1024px) and (max-width: 1080px) {
    margin-top: 15px;
  }

  @include responsive(840px) {
    margin-top: 15px;
  }

  @include responsive(767px) {
    margin-top: 15px;
  }

  button {
    @include responsive(767px) {
      margin-bottom: 15px;
    }
  }

  .form-group {
    @include responsive(767px) {
      width: 100%;
    }
  }

  &>div {
    @include responsive(767px) {
      width: 100% !important;
    }
  }

  .css-1epi15c-control,
  .css-1epi15c-control {
    @include responsive(767px) {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }
}

.addNewEmailPrimary {
  .form-check .form-check-input {
    position: absolute !important;
  }
}

.searchFilterResponsive {
  @include responsive(767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  button {
    @include responsive(767px) {
      margin-bottom: 15px;
    }

    &.btn-candidate-clear {
      @include responsive(767px) {
        margin-bottom: 0px;
      }
    }
  }
}

.primaryHeading {
  margin-bottom: 20px;

  h5 {
    font-size: 20px !important;
  }
  h6{
    font-size: 18px !important;
  }
}

.secondaryHeadingTheme {
  font-size: 12px !important;
  font-weight: bold;
  color: #33475b;

  h5 {
    font-size: 12px !important;
    font-weight: bold;
    color: #33475b;
  }
}

.primaryHeaderSpacing {
  margin-bottom: 20px;
}

.badge.badge-secondary {
  background: #d1fae5;
  color: #065f46 !important;
}

.badge.badge-success {
  background: #d1fae5 !important;
  color: #065f46 !important;
}

.activateButton {
  color: #fff;
  background-color: #28a745;
  border: 1px solid #28a745;
}

.iconButton {
  height: 21px;
}

.iconStyle {
  margin-top: 2px;
  width: 21px;
}

.iconButtonAction {
  background-color: transparent;
  border: 0px;
  padding: 0px;
  @include flexExactCenter(flex);
  border-radius: 50%;
  width: 35px;
  height: 35px;

  img {
    margin: 0px;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.jobAppStatus {
  width: 150px;
}

.input-group-text {
  background-color: transparent !important;
}

.currentlyWorkingCheckbox {
  position: relative;
  top: 36px;
  display: flex;
  align-items: center;

  & input[type='checkbox'] {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  label {
    margin: 0px !important;
    font-weight: 400 !important;
  }
}

.fileNameWidth {
  max-width: 578px;

  @include responsive(991px) {
    max-width: 100%;
  }
}

.companyTextWidth {
  max-width: 550px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.labelLineHeight {
  label {
    line-height: 25px;
  }
}

// secondary navTabs

.secondaryNavTabs {
  .nav-tabs.navTabsOverflow {
    padding: 0px 29px;
  }

  .nav-tabs.navTabsOverflow a {
    padding: 7px 15px;
    margin: 10px 5px 10px 0px;
    border: 0px;
    border-radius: 30px;
    font-size: 13px;
  }

  .nav-tabs.navTabsOverflow a.active,
  .nav-tabs.navTabsOverflow a:hover {
    border: 0px;
    background-color: #33475b;
    color: #fff;
  }
}

.dropdown-absolute {
  position: absolute !important;
}

.dropdown-relative {
  position: relative !important;
}

.dropdownPositionRelative {
  position: relative;

  .dropdown-menu {
    max-height: 200px !important;
    overflow-y: auto !important;
  }

  // @include responsive(1460px) {
  //   position: relative;
  // }

  //   .dropdown,
  //   .dropleft,
  //   .dropright,
  //   .dropup {
  //     position: absolute !important;
  //   }
}

.candidatePipelineTable {
  .table-responsive {
    @include responsive(1459px) {
      // min-height: 280px;
    }
  }

  .table thead th,
  .table thead td {
    // white-space: initial !important;
  }
}

.account-manager-splitTable .table tbody td {
  white-space: unset !important;
}

.candidates-list-table {
  .form-check-input {
    position: relative;
  }
}

.viewcompany-greement {
  .jobOrderRevamp label {
    flex: 35%;
    max-width: 35%;
  }

  .contentGrid {
    flex: 65%;
    max-width: 65%;
  }
}

.view-candidate-container {
  .infoCardDivider {
    display: flex;
    align-items: flex-start;

    &>img {
      &+label {
        position: relative;
        top: -2px;
      }
    }
  }
}

.view-contacts-basicInfo {
  .jobOrderRevamp label {
    flex: 35%;
    max-width: 35%;
    width: auto;
  }

  .jobOrderRevamp {

    .contentGrid,
    h6 {
      flex: 65%;
      max-width: 65%;
      width: auto;
    }
  }

  .socialMedia a {
    justify-content: flex-start;
    align-items: flex-start !important;
  }
}

.step-preview .step-preview-body.step-mybody-preview .form-check .form-check-input {
  margin-top: 2px !important;
}

.search-filter-sequence {
  .input-group {
    max-width: 400px;
  }
}

// .tooltips{
//   display: inline;
//   position: relative;
// }
// .tooltips:after {
//   border-radius: 5px;
//   bottom: 26px;
//   content: attr(title);
//   left: 20%;
//   padding: 5px 15px;
//   position: absolute;
//   z-index: 98;
//   width: 220px;
//   opacity:1;

//   transition: opacity 1s ease-in-out;
// }

// .tooltips:hover:after {
//   opacity:1;
// }
.view-candidate-list-table {
  &>.table-responsive {
    min-height: 300px;
  }
}

.sidebar-shadow {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.1;
  display: none;

  &.show {
    display: block;
  }
}

.all-job-orders {
  button {
    min-height: 38px !important;
  }
}

.sidebar-content {
  position: fixed;
  min-width: 50%;
  max-width: 50%;
  background-color: #ffff;
  top: 0;
  right: 0;
  height: 100vh;
  overflow-y: auto;
  z-index: 1041;
  padding: 20px;
  transform: translateX(100%);
  transition: transform 0.5s;

  &.open {
    transform: translateX(0%);
  }

  @include responsive(1240px) {
    min-width: 70%;
    max-width: 70%;
  }

  @include responsive(768px) {
    min-width: 80%;
    max-width: 80%;
  }

  @include responsive(572px) {
    min-width: 90%;
    max-width: 90%;
  }
}

.sidebar-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

//change
.accountDashboard-Table {
  .table-responsive {
    tbody {
      tr {
        @media only screen and (min-width: 1460px) and (max-width: 1640px) {
          position: relative;
        }
      }
    }
  }
}

.version-text {
  font-size: 10px;
  color: #33475b;
  text-align: center;
  margin-right: 33px;
  opacity: 0.6;
}

.steps-tags-template {
  position: relative;

  &:hover {
    background: orange;
    color: white;
    cursor: pointer;
  }
}

.badge-main {
  position: absolute;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
  animation-name: animationList;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.btn-reply-outlined {
  display: block !important;
  border: 1px solid #f9b220;
  width: 90px;
  padding: 0.275rem 0.75rem !important;
  border-radius: 59px;
  text-align: center;
  color: #f9b220 !important;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: rgba($color: #f9b220, $alpha: 0.1);
  }
}

@media (min-width: 576px) {
  .customModalAction {
    &.modal-dialog {
      max-width: 420px;
    }
  }
}

.modal .customModalAction .modal-header .close {
  display: block;
}

.text-link {
  color: #33475b;

  &:hover {
    text-decoration: underline !important;
    color: #f9b220;
  }
}

.jobapp-category-dropdown .form-control {
  padding: 0.375rem 2rem 0.375rem 0.75rem;
}

@keyframes animationList {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.job-status-border {
  border-bottom: 1px solid #e5e7eb;
}

.job-status-wrap p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #424242;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  width: 100%;
}

.job-status-wrap {
  .status {
    position: relative;
    left: -5px;
  }
}

.job-status-content .job-status-wrap h6 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #424242;
}

.job-interview-rounds {
  h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #272727;
  }

  .card-content {
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    h6 {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #686e78;
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #424242;
    }
  }
}

.custom-scroller::-webkit-scrollbar {
  width: 10px;
}

.custom-scroller::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.custom-scroller::-webkit-scrollbar-thumb {
  background-color: $ORANGE_ONE;
  border-radius: 100px;
}

.custom-form-input {
  &.form-input {
    .input-group-text {
      border-right-width: 0 !important;
      padding-right: 0;
    }

    .input-group-prepend {
      &+ {
        input {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-left-width: 0 !important;
        }
      }
    }

    .input-group-text {
      height: auto;
    }

    input {
      background-color: transparent !important;
      border: 1px solid #e5e5e5 !important;
      border-radius: 6px !important;
      min-height: 48px;
    }
  }
}

.contactlist-dropdown {
  .actionsDropdown .btn {
    width: auto;
    border-width: 0;

    &:hover {
      opacity: 0.5;
    }
  }
}

.table-responsive-container {
  &>.table-responsive {
    min-height: 300px;
  }
}

.reference-modal-dialog {
  &.modal-xl {
    max-width: 1000px;
  }
}

.reference-modal {
  .modal__header {
    &>h4 {
      margin-bottom: 15px;
      color: #000;
      letter-spacing: -0.5px;

      span {
        border-bottom: 2px solid #000;
      }
    }
  }

  .modal__body {
    .top-section {
      .row {
        line-height: 24px;
      }

      h5 {
        margin-bottom: 0;
        font-weight: 600;
        color: #000;
        font-size: 1rem;
      }

      p {
        margin-bottom: 0;
        font-size: 1rem;
        color: #000;
      }
    }

    .bottom-section {
      p {
        padding-bottom: 10px;
        margin-bottom: 1rem !important;
        font-size: 13px;
        color: #000;
      }
    }
  }
}

#stepBodyQuill {
  .editor-toolbar.fullscreen {
    z-index: 1001;
  }

  .EasyMDEContainer .CodeMirror-fullscreen {
    z-index: 1001;
  }
}

#stepBodyQuill .stepBodyQuill__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #96a1ac !important;
  margin: 5px 0 0 0;
  display: block !important;
}

iframe {
  display: none;
}

.candidate-serach-wrap {
  border-left: 1px solid #d1d5db;
}

.checkbox-square {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-square input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-square .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #e5e9f8;
  border-radius: 4px;
  background-color: #fff;
}

.checkbox-square :hover input~.checkmark {
  background-color: #ccc;
}

.checkbox-square input:checked~.checkmark {
  background-color: #308d3f;
}

.checkbox-square .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-square input:checked~.checkmark:after {
  display: block;
}

.checkbox-square .checkmark:after {
  left: 9px;
  top: 3px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(42deg);
  -ms-transform: rotate(42deg);
  transform: rotate(42deg);
}

.ckeckbox-circle {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ckeckbox-circle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.ckeckbox-circle .checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #e5e9f8;
  border-radius: 50%;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.ckeckbox-circle :hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.ckeckbox-circle input:checked~.checkmark {
  border-color: #308d3f;
  border-width: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.ckeckbox-circle .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.candidate-search-content {
  border-top: 1px solid #e5e7eb;
}

.candidate-search-all {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #424242;
    margin-bottom: 0;
  }
}

.candidate-search-option {
  &>p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #696e77;
  }

  button {
    min-height: 40px !important;
  }
}

.candidate-searchitem-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.candidate-searchitem-item {
  .fixed-width {
    width: 120px;
  }
}

#stepBodyQuill .stepBodyQuill__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #96a1ac !important;
  margin: 5px 0 0 0;
  display: block !important;
}

.job-status-box {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.jobapp-category-dropdown .form-control {
  padding: 0.375rem 2rem 0.375rem 0.75rem;
}

@keyframes animationList {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.job-status-border {
  border-bottom: 1px solid #e5e7eb;
}

.job-status-wrap p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #424242;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.job-status-wrap {
  .status {
    position: relative;
    left: -5px;
  }
}

.job-status-content .job-status-wrap h6 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #424242;
}

.job-interview-rounds {
  h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #272727;
  }

  .card-content {
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    h6 {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #686e78;
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #424242;
    }
  }
}

.custom-scroller::-webkit-scrollbar {
  width: 10px;
}

.custom-scroller::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.custom-scroller::-webkit-scrollbar-thumb {
  background-color: $ORANGE_ONE;
  border-radius: 100px;
}

.custom-form-input {
  &.form-input {
    .input-group-text {
      border-right-width: 0 !important;
      padding-right: 0;
    }

    .input-group-prepend {
      &+ {
        input {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-left-width: 0 !important;
        }
      }
    }

    .input-group-text {
      height: auto;
    }

    input {
      background-color: transparent !important;
      border: 1px solid #e5e5e5 !important;
      border-radius: 6px !important;
      min-height: 48px;
    }
  }
}

.contactlist-dropdown {
  .actionsDropdown .btn {
    width: auto;
    border-width: 0;

    &:hover {
      opacity: 0.5;
    }
  }
}

// .css-1yaelg0-control, .css-onzmuj-control{
//   background: #FFFFFF !important;
//   border: 1px solid #E5E5E5 !important;
//   border-radius: 6px !important;
//   min-height: 48px !important;
// }

// .css-1fhf3k1-control, .css-yk16xz-control{

//   background: #FFFFFF !important;
//   border: 1px solid #E5E5E5 !important;
//   border-radius: 6px !important;
//   min-height: 48px !important;
// }

#submittedEmail {
  &.submission-package {
    .to-header {
      border-width: 1px;
      border-style: solid;
      border-color: #e5e5e5 !important;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 6px 6px 0 0;
    }

    .to-subject {
      border-width: 0 1px 0 1px;
      border-style: solid;
      border-color: #e5e5e5 !important;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.table-responsive-container {
  &>.table-responsive {
    min-height: 300px;
  }
}

.reference-modal-dialog {
  &.modal-xl {
    max-width: 1000px;
  }
}

.reference-modal {
  .modal__header {
    &>h4 {
      margin-bottom: 15px;
      color: #000;
      letter-spacing: -0.5px;

      span {
        border-bottom: 2px solid #000;
      }
    }
  }

  .modal__body {
    .top-section {
      .row {
        line-height: 24px;
      }

      h5 {
        margin-bottom: 0;
        font-weight: 600;
        color: #000;
        font-size: 1rem;
      }

      p {
        margin-bottom: 0;
        font-size: 1rem;
        color: #000;
      }
    }

    .bottom-section {
      p {
        padding-bottom: 10px;
        margin-bottom: 1rem !important;
        font-size: 13px;
        color: #000;
      }
    }
  }
}

#stepBodyQuill {
  .editor-toolbar.fullscreen {
    z-index: 1001;
  }

  .EasyMDEContainer .CodeMirror-fullscreen {
    z-index: 1001;
  }
}

.checkbox-square .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.candidate-serach-wrap {
  border-left: 1px solid #d1d5db;
}

.checkbox-square {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-square input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-square .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #e5e9f8;
  border-radius: 4px;
  background-color: #fff;
}

.checkbox-square :hover input~.checkmark {
  background-color: #ccc;
}

.checkbox-square input:checked~.checkmark {
  background-color: #308d3f;
}

.checkbox-square .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-square input:checked~.checkmark:after {
  display: block;
}

.checkbox-square .checkmark:after {
  left: 9px;
  top: 3px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(42deg);
  -ms-transform: rotate(42deg);
  transform: rotate(42deg);
}

.ckeckbox-circle {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ckeckbox-circle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.ckeckbox-circle .checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #e5e9f8;
  border-radius: 50%;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.ckeckbox-circle :hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.ckeckbox-circle input:checked~.checkmark {
  border-color: #308d3f;
  border-width: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.ckeckbox-circle .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.candidate-search-content {
  border-top: 1px solid #e5e7eb;
}

.candidate-search-all {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #424242;
    margin-bottom: 0;
  }
}

.candidate-search-option {
  &>p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #696e77;
  }

  button {
    min-height: 40px !important;
  }
}

.candidate-searchitem-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.candidate-searchitem-item {
  .fixed-width {
    width: 120px;
  }
}

.job-status-border {
  border-bottom: 1px solid #e5e7eb;
}

.job-status-wrap p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #424242;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.job-status-wrap {
  .status {
    position: relative;
    left: -5px;
  }
}

.job-status-content .job-status-wrap h6 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #424242;
}

.job-interview-rounds {
  h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #272727;
  }

  .card-content {
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    h6 {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #686e78;
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #424242;
    }
  }
}

.custom-scroller::-webkit-scrollbar {
  width: 10px;
}

.custom-scroller::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.custom-scroller::-webkit-scrollbar-thumb {
  background-color: $ORANGE_ONE;
  border-radius: 100px;
}

.custom-form-input {
  &.form-input {
    .input-group-text {
      border-right-width: 0 !important;
      padding-right: 0;
    }

    .input-group-prepend {
      &+ {
        input {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-left-width: 0 !important;
        }
      }
    }

    .input-group-text {
      height: auto;
    }

    input {
      background-color: transparent !important;
      border: 1px solid #e5e5e5 !important;
      border-radius: 6px !important;
      min-height: 48px;
    }
  }
}

.custom-amount-form-input {
    .input-group-text {
      border-right-width: 0 !important;
      padding-right: 0;
    }

    .input-group-prepend {
      &+ {
        input {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-left-width: 0 !important;
        }
      }
    }

    .input-group-text {
      height: auto;
    }

    input {
      border: 1px solid #d7d7e7 !important;
      height: auto;
    }
}
.contactlist-dropdown {
  .actionsDropdown .btn {
    width: auto;
    border-width: 0;

    &:hover {
      opacity: 0.5;
    }
  }
}

// .css-1yaelg0-control, .css-onzmuj-control{
//   background: #FFFFFF !important;
//   border: 1px solid #E5E5E5 !important;
//   border-radius: 6px !important;
//   min-height: 48px !important;
// }

// .css-1fhf3k1-control, .css-yk16xz-control{

//   background: #FFFFFF !important;
//   border: 1px solid #E5E5E5 !important;
//   border-radius: 6px !important;
//   min-height: 48px !important;
// }

#submittedEmail {
  &.submission-package {
    .to-header {
      border-width: 1px;
      border-style: solid;
      border-color: #e5e5e5 !important;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 6px 6px 0 0;
    }

    .to-subject {
      border-width: 0 1px 0 1px;
      border-style: solid;
      border-color: #e5e5e5 !important;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.table-responsive-container {
  &>.table-responsive {
    min-height: 300px;
  }
}

.reference-modal-dialog {
  &.modal-xl {
    max-width: 1000px;
  }
}

.reference-modal {
  .modal__header {
    &>h4 {
      margin-bottom: 15px;
      color: #000;
      letter-spacing: -0.5px;

      span {
        border-bottom: 2px solid #000;
      }
    }
  }

  .modal__body {
    .top-section {
      .row {
        line-height: 24px;
      }

      h5 {
        margin-bottom: 0;
        font-weight: 600;
        color: #000;
        font-size: 1rem;
      }

      p {
        margin-bottom: 0;
        font-size: 1rem;
        color: #000;
      }
    }

    .bottom-section {
      p {
        padding-bottom: 10px;
        margin-bottom: 1rem !important;
        font-size: 13px;
        color: #000;
      }
    }
  }
}

#stepBodyQuill {
  .editor-toolbar.fullscreen {
    z-index: 1001;
  }

  .EasyMDEContainer .CodeMirror-fullscreen {
    z-index: 1001;
  }
}

#stepBodyQuill .stepBodyQuill__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #96a1ac !important;
  margin: 5px 0 0 0;
  display: block !important;
}

iframe {
  display: none;
}

.candidate-serach-wrap {
  border-left: 1px solid #d1d5db;
}

.checkbox-square {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-square input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-square .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #e5e9f8;
  border-radius: 4px;
  background-color: #fff;
}

.checkbox-square :hover input~.checkmark {
  background-color: #ccc;
}

.checkbox-square input:checked~.checkmark {
  background-color: #308d3f;
}

.checkbox-square .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-square input:checked~.checkmark:after {
  display: block;
}

.checkbox-square .checkmark:after {
  left: 9px;
  top: 3px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(42deg);
  -ms-transform: rotate(42deg);
  transform: rotate(42deg);
}

.ckeckbox-circle {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ckeckbox-circle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.ckeckbox-circle .checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #e5e9f8;
  border-radius: 50%;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.ckeckbox-circle :hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.ckeckbox-circle input:checked~.checkmark {
  border-color: #308d3f;
  border-width: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.ckeckbox-circle .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.candidate-search-content {
  border-top: 1px solid #e5e7eb;
}

.candidate-search-all {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #424242;
    margin-bottom: 0;
  }
}

.candidate-search-option {
  &>p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #696e77;
  }

  button {
    min-height: 40px !important;
  }
}

.candidate-searchitem-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.candidate-searchitem-item {
  .fixed-width {
    width: 120px;
  }
}

#stepBodyQuill .stepBodyQuill__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #96a1ac !important;
  margin: 5px 0 0 0;
  display: block !important;
}

.job-status-box {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.jobapp-category-dropdown .form-control {
  padding: 0.375rem 2rem 0.375rem 0.75rem;
}

@keyframes animationList {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.job-status-border {
  border-bottom: 1px solid #e5e7eb;
}

.job-status-wrap p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #424242;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.job-status-wrap {
  .status {
    position: relative;
    left: -5px;
  }
}

.job-status-content .job-status-wrap h6 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #424242;
}

.job-interview-rounds {
  h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    color: #272727;
  }

  .card-content {
    background: #fafafa;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    h6 {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #686e78;
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #424242;
    }
  }
}

.custom-scroller::-webkit-scrollbar {
  width: 10px;
}

.custom-scroller::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.custom-scroller::-webkit-scrollbar-thumb {
  background-color: $ORANGE_ONE;
  border-radius: 100px;
}

.custom-form-input {
  &.form-input {
    .input-group-text {
      border-right-width: 0 !important;
      padding-right: 0;
    }

    .input-group-prepend {
      &+ {
        input {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          border-left-width: 0 !important;
        }
      }
    }

    .input-group-text {
      height: auto;
    }

    input {
      background-color: transparent !important;
      border: 1px solid #e5e5e5 !important;
      border-radius: 6px !important;
      min-height: 48px;
    }
  }
}

.contactlist-dropdown {
  .actionsDropdown .btn {
    width: auto;
    border-width: 0;

    &:hover {
      opacity: 0.5;
    }
  }
}

// .css-1yaelg0-control, .css-onzmuj-control{
//   background: #FFFFFF !important;
//   border: 1px solid #E5E5E5 !important;
//   border-radius: 6px !important;
//   min-height: 48px !important;
// }

// .css-1fhf3k1-control, .css-yk16xz-control{

//   background: #FFFFFF !important;
//   border: 1px solid #E5E5E5 !important;
//   border-radius: 6px !important;
//   min-height: 48px !important;
// }

#submittedEmail {
  &.submission-package {
    .to-header {
      border-width: 1px;
      border-style: solid;
      border-color: #e5e5e5 !important;
      padding-top: 5px;
      padding-bottom: 5px;
      border-radius: 6px 6px 0 0;
    }

    .to-subject {
      border-width: 0 1px 0 1px;
      border-style: solid;
      border-color: #e5e5e5 !important;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.table-responsive-container {
  &>.table-responsive {
    min-height: 300px;
  }
}

.reference-modal-dialog {
  &.modal-xl {
    max-width: 1000px;
  }
}

.reference-modal {
  .modal__header {
    &>h4 {
      margin-bottom: 15px;
      color: #000;
      letter-spacing: -0.5px;

      span {
        border-bottom: 2px solid #000;
      }
    }
  }

  .modal__body {
    .top-section {
      .row {
        line-height: 24px;
      }

      h5 {
        margin-bottom: 0;
        font-weight: 600;
        color: #000;
        font-size: 1rem;
      }

      p {
        margin-bottom: 0;
        font-size: 1rem;
        color: #000;
      }
    }

    .bottom-section {
      p {
        padding-bottom: 10px;
        margin-bottom: 1rem !important;
        font-size: 13px;
        color: #000;
      }
    }
  }
}

#stepBodyQuill {
  .editor-toolbar.fullscreen {
    z-index: 1001;
  }

  .EasyMDEContainer .CodeMirror-fullscreen {
    z-index: 1001;
  }
}

.checkbox-square .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.candidate-serach-wrap {
  border-left: 1px solid #d1d5db;
}

.checkbox-square {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-square input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-square .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #e5e9f8;
  border-radius: 4px;
  background-color: #fff;
}

.checkbox-square :hover input~.checkmark {
  background-color: #ccc;
}

.checkbox-square input:checked~.checkmark {
  background-color: #308d3f;
}

.checkbox-square .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-square input:checked~.checkmark:after {
  display: block;
}

.checkbox-square .checkmark:after {
  left: 9px;
  top: 3px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(42deg);
  -ms-transform: rotate(42deg);
  transform: rotate(42deg);
}

.ckeckbox-circle {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ckeckbox-circle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.ckeckbox-circle .checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #e5e9f8;
  border-radius: 50%;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.ckeckbox-circle :hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.ckeckbox-circle input:checked~.checkmark {
  border-color: #308d3f;
  border-width: 4px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.ckeckbox-circle .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.candidate-search-content {
  border-top: 1px solid #e5e7eb;
}

.candidate-search-all {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #424242;
    margin-bottom: 0;
  }
}

.candidate-search-option {
  &>p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #696e77;
  }

  button {
    min-height: 40px !important;
  }
}

.candidate-searchitem-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.candidate-searchitem-item {
  .fixed-width {
    width: 120px;
  }
}

.btn-job-filters {
  border-style: solid;
  border-width: 1px;
  border-color: #ced4da;
  background-color: #fff;
  color: #495057;
  padding: 6px 20px;

  &.btn-job-order {
    border-radius: 8px 0 0 8px;
    border-right-width: 0;
  }

  &.btn-pending-reviews {
    border-radius: 0 8px 8px 0;
  }

  &.btn-pending-submission {
    border-radius: 0;
  }

  &.active {
    background-color: $PRIMARY_BUTTON_BACKGROUND;
    color: #fff;
  }
}

.lookup-signature-card {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #e5e9f8;
  padding-left: 15px;
  padding-right: 15px;
}

.lookup-quill-wrap {
  .editor-height-for-body .ql-container.ql-snow {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.route-link {
  cursor: pointer;

  &:hover {
    // text-decoration: underline;
    color: #0056b3;
  }
}

.flex-none {
  flex: none;
}

.video-table-row {
  td:nth-of-type(1) {
    width: 30%;
  }

  td:nth-of-type(2) {
    width: 60%;
  }

  td:nth-of-type(3) {
    width: 10%;
  }

  td {

    span,
    button {
      max-width: 280px;
      font-size: 12px;
    }
  }
}

.report-table-row {
  td:nth-of-type(1) {
    width: 30%;
  }

  td:nth-of-type(2) {
    width: 30%;
  }

  td:nth-of-type(3) {
    width: 30%;
  }

  td:nth-of-type(4) {
    width: 10%;
  }

  td {

    span,
    button {
      max-width: 280px;
      font-size: 12px;
    }
  }
}

.gap-10 {
  gap: 10px;
}

.hover-link {
  a:hover {
    text-decoration: underline !important;
  }
}

.theme-color {
  color: #eaa827;
}

.img-btn {
  img {
    margin-right: 0 !important;
  }
}

.status-main {
  .status-1 {
    min-width: 35%;
  }

  .status-2 {
    min-width: 25%;
  }

  .status-3 {
    min-width: 40%;
  }
}

.text-bluelink {
  word-break: break-all;
}

.max-300 {
  max-width: 300px;
}

.max-800 {
  max-width: 800px;
}

.max-200 {
  max-width: 200px;
}

.max-100 {
  max-width: 100px;
}

.green-dot {
  background: #198754;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 5px;
}

.schedule-main {
  width: 98%;
  height: fit-content;
  background-color: #fbfcfd;
  border: 1px solid #e7e8eb;

  .flex-equal {
    flex: 1;
  }
}

.timezone-width {
  width: 50%;
}

@media only screen and (max-width: 1200px) {
  .schedule-main {
    border: none;
  }

  .flex-equal {
    flex: none !important;
    border: 1px solid #e7e8eb !important;
    margin-bottom: 0.5rem;
  }

  .timezone-width {
    width: 100%;
  }
}

.vert-text {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  padding: 10px;
}

.day-main {
  background-color: #fbfcfd;
  width: 60px;
}

.dropdown-item:active {
  background-color: transparent !important;
}

.custom-slider {
  background-color: gray !important;
  border: none !important;

  &::-webkit-slider-thumb {
    background-color: orange !important;
  }
}

.custom-stepper {
  .step-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    // padding: 10px 0;

    &:not(:first-child)::before {
      content: '';
      background-color: #ced4da;
      position: absolute;
      width: 100%;
      height: 4px;
      right: 50%;
      top: 29%;
      border-radius: 8px;
      transform: translateY(-50%);
    }
  }

  .step {
    position: relative;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-weight: 600;
    color: white;
    border-radius: 100%;
    background-color: lightgray;
  }

  .active .step {
    background-color: #fec135;
  }
}

.request-badge {
  &.badge-info {
    background-color: rgba(14, 121, 182, 0.3) !important;
    color: #0e79b6 !important;
  }
}
// .request-badge {
//   &.badge-info {
//     background-color: rgba(14, 121, 182, 0.3) !important;
//     color: #0e79b6 !important;
//   }
// }

.w-12rem {
  width: 12rem;
}

.w-35rem {
  width: 35rem;
}

.dropdown-width-class {
  &.actionsDropdown .btn {
    width: auto;
    padding: 6px 12px;

    &:active {
      background-color: transparent !important;
      color: inherit !important;
      border-color: inherit !important;
    }
  }
}

.request-table {
  tr {
    .badge-padding {
      padding-left: 6px !important;
    }
  }
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.gpt-btn {
  border-radius: 4px;
  border: 1px solid #10A37F;

  .customMenuDropdown {
    background-color: #10A37F !important;
  }

  .btn {
    background-color: #10A37F !important;
    color: #ffffff !important;
    width: 120px;
    height: 33px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #10A37F;
    padding: 0px;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      color: #ffffff !important;
      border-color: #10A37F !important;
      background-color: transparent !important;
    }

    svg {
      margin-right: 3px !important;
    }
  }

  .dropdown-toggle::after {
    margin-left: 12px;
    border: 0px;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../assets/images/dropdown-white.svg');
  }
}

.chatgpt-text {
  color: #343541;
}

.pre-text {
  white-space: pre-line;
  font-family: inter;
  font-size: 16px;
  color: #343541;
  line-height: normal;
}

.chatgpt-div {
  position: relative;
  height: 92vh;
  display: flex;
  flex-direction: column;

  @include responsive(600px) {
    height: 88vh;
  }
}

.fade-button:disabled{
  img{
    opacity: 0.4;
  }
}

.chatgpt-container {
  flex-grow: 1;
  max-height: 80%;
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F1F1F1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }
}

.chatgpt-reply {
  background-color: #F7F7F8;
  border: 1px solid #D1D5DB;
  margin: 10px 0;
  padding: 1rem;
  max-height: 600px;
  overflow-y: auto;
}

.chatgpt-prompt {
  position: absolute;
  bottom: 0px;
  width: -webkit-fill-available;
  background-color: white;
  padding: 1rem 0;

  .chatgpt-input-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    position: relative;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 0px 15px 0px #0000001A;
    border-radius: 6px;
    min-height: 50px;

    .textarea {
      margin: 0;
      width: 100%;
      height: 100px;
      max-height: 200px;
      resize: none;
      border: none;
      background-color: transparent;
      padding-top: 20px;
      overflow: hidden;
      overflow-y: auto;
      font-size: 16px;
      color: #343541;
    }

    .button {
      position: absolute;
      border-radius: 4px;
      right: 12px;
      top: 20px;
      background-color: transparent;
      border: 0;
    }
  }
}

.custom-input-field {
  border: 1px solid #e5e9f8;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  height: 36px;
  padding-right: 10px;

  input {
    border: none;
    height: auto;
  }
}

.plus-icon {
  background-color: #EAA827;
  border-radius: 100%;
  border: none;
  width: 28px;
  height: 28px;
  color: #ffffff;
  font-size: 24px;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cross-btn {
  color: #BE3740;
  border: none;
  background-color: transparent;
  margin-left: 5px;
  font-size: 10px;
}

.synonym-table-row {
  td:nth-of-type(1) {
    width: 30%;
  }

  td:nth-of-type(2) {
    width: 60%;
  }

  td:nth-of-type(3) {
    width: 10%;
  }
}

.chat-textarea {
  resize: none;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  font-size: 16px;
  color: #343541;
  border: none;
  background-color: transparent;
  padding: 15px;
  width: 100%;
}
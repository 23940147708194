@import "../../styles/variables";
@import "../../styles/mixins";

.my-stats {
  margin-top: 32px;
}

.personal-info {
  padding: 15px 22px;
  background-color: $WHITE;
  border: 1px solid $WHITE_TEN;
  border-left: 0px;
  border-right: 0px;

  h6 {
    opacity: 0.6;
    margin-bottom: 8px;
  }

  a {
    color: $BLUE;
  }

  label {
    font-size: 14px;
    opacity: 0.6;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    margin: 0px;
  }
}

.stats {
  @include flexExactCenter(flex);
  flex-wrap: wrap;
  border-top: 1px solid $WHITE_TEN;
}

.mt-xs-4 {
  @include responsive(1024px) {
    margin-top: 24px;
  }
}

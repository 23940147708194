@import "../../../styles/variables";
@import "../../../styles/mixins";

.table {
  background: $WHITE;
  border-radius: 4px;
  border: 1px solid #e5e9f8;
  border-bottom: 0px;
  margin-bottom: 0px !important;

  th,
  td {
    padding: 12px 16px !important;
    border-top: 0px !important;
  }

  thead {
    th {
      color: #8e949d;
      padding: 10px 12px;
      font-weight: 500;
      font-size: 12px;
      background: $WHITE_THREE;
      text-transform: uppercase;
      border: 0px !important;
      font-family: $INTER_FONT;
      border-bottom: 1px solid #e5e9f8 !important;
      white-space: nowrap !important;
      vertical-align: top !important;
      min-width: 100px;

      &:first-child {
        border-top-left-radius: 3px;
      }

      &:last-child {
        border-top-right-radius: 3px;
      }

      .sort-btn-div {
        .btn {
            padding: 0.375rem 0.75rem !important;
        }

        .sort-btn {
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
          color: #8e949d;
          text-align: start;
          font-size: 13px;
          font-weight: 400;

          &:active,
          .show {
            background-color: none;
            border: none;
          }

          .icon-rotate {
            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }

      .sort-btn-new {
        background-color: transparent;
        color: #8e949d;
        font-size: 13px;
        font-weight: 400;
        text-align: start;
        vertical-align: middle;
        padding: 0.375rem 0.75rem;
        text-transform: capitalize;
      }
    }

    .th-width {
      width: auto !important;
      .form-group {
        margin-bottom: 0 !important;
      }
    }

    .select-width {
      .form-control {
        width: 135px !important;
        
      }
    }
  }

  tbody {
    td {
      font-weight: 400;
      font-size: 12px;
      padding: 9px 12px;
      color: #272727;
      vertical-align: middle !important;
      border-bottom: 1px solid #e5e9f8 !important;
      white-space: nowrap !important;
      height: 52px;

      a,
      .linkButton {
        color: #0e79b6;
      }

      .linkButton {
        font-weight: 400;
        font-size: 12px;
        opacity: 1;
      }
    }
  }

  tbody {
    tr:hover {
      box-shadow: 0px 4px 10px 1px #e5e9f8;
      background-color: transparent !important;
    }
  }
}

.table-min-expectations{
  background: $WHITE;
  border-radius: 4px;
  border-top: 0px solid #e5e9f8;

  td{
    &:first-child{
      span{
      margin: 0px -15px;
      }
      font-size: 16px;
      font-weight: 600;
    }
    border-bottom: 1px solid #e5e9f8;
  }
}

.min-expectations-tr{
border-right: 1px solid #fff;
border-left: 1px solid #fff;
}

.placement-table {
  tr {
    td {
      padding-left: 30px !important;
    }
    .badge-pading{
      padding-left: 16px !important;

    }
  }
}

.minimize-pading-from-table{
  th {
    &:nth-of-type(1) {
      padding-left: 6px !important;
    }
  }

  td {
    padding: 12px 4px 12px 30px !important;
    &:nth-of-type(1) {
      padding-left: 16px !important;
    }
  }
  .badge-column {
    padding-left: 19px !important;
  }
}

.table-custom-width {
  thead th {
    width: auto !important;
    min-width: auto !important;
  }
}

.candidateTable{
  thead th:nth-of-type(1) {
    padding-left: 29px !important;
  }
  tbody td:nth-of-type(1) {
    padding-left: 9px !important;
  }
}



.table-responsive {
  width: 100%;
  overflow-x: auto !important;

  @include responsive(1024px) {
    overflow-x: scroll !important;
  }

  &.tablePlacementResponsive {
    overflow-x: auto !important;
    overflow-y: visible !important;

    @include responsive(1024px) {
      overflow-x: auto !important;
      overflow-y: visible !important;
    }
  }

  &.tableResponsiveInitial {
    overflow-x: initial !important;

    @include responsive(1024px) {
      overflow-x: auto !important;
    }
  }
}

.table-responsive::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eaeaea;
}

.table-responsive::-webkit-scrollbar {
  height: 4px;
  width: 0px;
  background-color: transparent;
}

.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d4d4d4;
}

// custom-table-menu
.tableMenuItem {
  margin: 10px 0px;
  font-weight: 400;
  font-size: 12px;
  color: #272727 !important;
  transition: 0.6s;
  border-radius: 0px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    background-color: #e4e4e4 !important;
  }

  & a {
    color: #272727 !important;
  }

  & img {
    margin-right: 12px;
  }

  & a,
  & button {
    color: #272727;
    font-size: 12px;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(60%) hue-rotate(180deg);
}
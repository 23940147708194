@import "../../../styles/variables";
@import "../../../styles/mixins";
@import "../../layout/MultiSelect/style.scss";

.avatar-label {
  height: 32px;
  display: flex;
  align-items: center;
  padding: 21px 24px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 23px;
  color: $BLUE_NINE;
  @include flexAlignItemCenter(flex);

  &:hover {
    background: $WHITE_ALPHA;
  }
}

@import "../../../styles/variables";
@import "../../../styles/mixins";

.user-form {
  max-width: 685px;
  width: 100%;
  background: url("../../../assets/images/form-bg.svg") no-repeat left bottom;
  padding-bottom: 70px;

  a {
    font-size: 14px;
    line-height: 21px;
    color: $PRIMARY_BUTTON_BACKGROUND;
  }
}

.user-form-container {
  max-width: 540px;
  width: 100%;
  margin: 0px auto;
  padding: 25px 20px;
}

.logo-header {
  margin-bottom: 24px;
  text-align: center;
}

.user-form-inner {
  text-align: center;
  padding: 40px;
  border-radius: 4px;
  box-shadow: $BOX_SHADOW;
  z-index: 99;
  background-color: $WHITE;

  @include responsive(767px) {
    padding: 20px;
  }
}

.form-height {
  min-height: 340px;
}

.form-height-reset {
  height: 300px;
}

.form-reset-password {
  height: 260px;
}

.form-footer {
  margin-top: 40px;
}

.link-btn {
  display: block;
  margin-top: 32px;
}

.verify-check {
  margin-bottom: 48px;
}

@import "../../styles/variables";

.span-notes {
  color: #eaa82e;
  cursor: pointer;
}

.section-header-notes-search {
  background-color: white;
  margin: 3px !important;
  border-radius: 5px;

  div {
    label {
      padding-top: 15px;
    }
  }
}

.section-notes-search {
  margin-top: 20px;

  .loader {
    border-radius: 50px !important;
  }

  div {
    background-color: white;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 15px;

    .badge-tag {
      background-color: #d4d4d4;
      background-color: #eaffed;
      border-radius: 15px;
      font-weight: 600;
      font-size: 12px;
      color: #359049;
    }

    h5 {
      margin-bottom: 1.8%;

      .badge-secondary {
        border: 1px solid #9abe9f;
        background-color: #eaffed;
        border-radius: 15px;
        font-weight: 600;
        font-size: 14px;
        color: #359049;
        padding: 5px 10px;
      }

      .badge-info {
        border: 1px solid $WHITE_THREE;
        background-color: #d7dfdc;
        border-radius: 15px;
        font-weight: 600;
        font-size: 12px;
        color: #6c757d;
        padding: 5px 10px;
      }
    }

    .color-category {
      font-size: 16px;
      font-weight: 600;
    }

    p {
      font-weight: 300;
      font-size: 12px;
    }

    .description {
      font-size: 12px;
      font-weight: 300;
    }

    .user {
      font-size: 12px;
      font-style: italic;
      font-weight: 300;
    }
  }
}

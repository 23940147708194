@import "../../../styles/variables";
@import "../../../styles/mixins";

.btn {
  transition: all 0.3s ease-in;
  box-shadow: none !important;
  cursor: pointer;
  @include flexExactCenter(inline-flex);

  @include responsive(767px) {
    font-size: 15px;
    padding: 9px 20px 7px;
  }

  &:disabled {
    cursor: not-allowed;
  }

  > i,
  > svg,
  > img {
    margin-right: 19px;

    @include responsive(767px) {
      margin: 0 10px 3px 0px;
    }
  }

  &.btn-outlined-sm{
    color: $WHITE;
    border-radius: 5px;
    min-width: 4px;
    height: 26px;
    font-size: 12px;
    font-weight: 500;
    background: $PRIMARY_BUTTON_BACKGROUND;
    border: 1px solid $PRIMARY_BUTTON_BACKGROUND;
    background: transparent;
    color: $PRIMARY_BUTTON_BACKGROUND;
    border-color: $PRIMARY_BUTTON_BACKGROUND;
    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background: transparent;
      color: $PRIMARY_BUTTON_BACKGROUND;
      border-color: $PRIMARY_BUTTON_BACKGROUND;
    }
     &:disabled {
      color: $WHITE_NINE;
      background-color: $WHITE_THREE;
      border: 1px solid $WHITE_THREE;

      &:hover {
        color: $WHITE_NINE;
        background-color: $WHITE_THREE;
        border: 1px solid $WHITE_THREE;
      }
    }

    &.danger {
      background: $RED;
      border-color: $RED;

      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background: transparent;
        color: $RED;
        border-color: $RED;
      }
    }

    
  }

  &.btn-primary {
    color: $WHITE;
    border-radius: 5px;
    min-width: 160px;
    font-size: 16px;
    color: $WHITE;
    font-weight: 500;
    min-height: 35px;
    background: $PRIMARY_BUTTON_BACKGROUND;
    border: 1px solid $PRIMARY_BUTTON_BACKGROUND;

    @include responsive(767px) {
      min-width: 140px;
      font-size: 12px;
    }

    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background: transparent;
      color: $PRIMARY_BUTTON_BACKGROUND;
      border-color: $PRIMARY_BUTTON_BACKGROUND;
    }

    &:disabled {
      color: $WHITE_NINE;
      background-color: $WHITE_THREE;
      border: 1px solid $WHITE_THREE;

      &:hover {
        color: $WHITE_NINE;
        background-color: $WHITE_THREE;
        border: 1px solid $WHITE_THREE;
      }
    }

    &.danger {
      background: $RED;
      border-color: $RED;

      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background: transparent;
        color: $RED;
        border-color: $RED;
      }
    }

    @include responsive(767px) {
      font-size: 14px;
    }
  }

  &.btn-outline-primary {
    min-width: 293px;
    height: 54px;
    background: $WHITE;
    border-color: $PRIMARY_BUTTON_BACKGROUND;
    color: $PRIMARY_BUTTON_BACKGROUND;

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active {
      background: $PRIMARY_BUTTON_BACKGROUND;
      color: $WHITE;
      border-color: $PRIMARY_BUTTON_BACKGROUND;
    }

    &.blue {
      color: $BLUE_FOUR;
      border-color: $BLUE_FIVE;
      height: 66px;
      min-width: 338px;

      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active {
        background: $BLUE_FOUR;
        color: $WHITE;
        border-color: $BLUE_FOUR;
      }

      @include responsive(767px) {
        height: 50px;
        min-width: auto;
      }
    }
  }

  &.btn-outlined {
    font-size: 16px;
    line-height: 24px;
    border: 1px solid $PRIMARY_TEXT_COLOR;
    padding: 7px 16px;

    &:hover {
      background: $PRIMARY_TEXT_COLOR;
      color: $WHITE;
    }
  }

  &.big-width {
    font-size: 16px;
    line-height: 24px;
    min-width: 190px;
    padding: 7px 24px;
  }

  &.sm-btn {
    font-size: 18px;
    line-height: 24px;
    padding: 14px 13px;
    font-weight: normal;

    &:focus {
      outline: none;
    }
  }
}

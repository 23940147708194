@import "../../../styles/variables";
@import "../../../styles/mixins";

.step-cards {
  &.active {
    border: 1px solid #2e95b4 !important;
    box-shadow: 0px 1px 8px 3px rgba(0, 0, 0, 0.16) !important;
  }
  background: #ffffff;
  border: 1px solid #f0f1f2;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.step-card-header {
  @include flexAlignCenter(flex);
  flex-wrap: wrap;
  border-bottom: 1px solid #f0f1f2;
  padding: 14px;
  @include responsive(767px) {
    justify-content: flex-end;
  }
  h5 {
    margin: 0px 0px 0px 10px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}

.step-body-card {
  width: 25%;
  @include responsive(767px) {
    width: 50%;
  }
}

.step-body {
  @include flexAlignCenter(flex);
  flex-wrap: wrap;
  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #96a1ac;
  }
  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #33475b;
  }
}

.step-card-footer {
  border-top: 1px solid #f0f1f2;
  padding: 14px;
  cursor: pointer;
  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #33475b;
    margin: 0px;
  }
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #96a1ac;
    margin: 0px;
  }
}

.deleting-subject {
  color: red;
}

.step-preview {
  background: #ffffff;
  border: 1px solid #f0f1f2;
  border-radius: 4px;
  h5 {
    padding: 22px 22px 0px;
    border-bottom: 1px solid #f0f1f2;
  }
  .step-preview-body {
    padding: 22px;
    @include flexAlignItemCenter(flex);
    flex-direction: column;
    justify-content: center;
    h5 {
      border: 0px;
    }
    .form-check {
      .form-check-input {
        margin-top: 5px;
      }
    }
  }
}

.steps-number-wrapper {
  position: relative;
  .card-number {
    background: #ffffff;
    border: 3px solid #96a1ac;
    width: 32px;
    height: 32px;
    margin: 17px auto;
    border-radius: 50%;
    @include flexExactCenter(flex);
    font-weight: 800;
    font-size: 14px;
    color: #96a1ac;
    margin-bottom: 16px;
    &::after {
      width: 4px;
      height: 17px;
      content: "";
      position: absolute;
      background-color: #96a1ac;
      top: 31px;
    }
    &::before {
      width: 4px;
      height: 17px;
      content: "";
      position: absolute;
      background-color: #96a1ac;
      bottom: 32px;
    }
  }
}

.preview-body-quil {
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
}

.active-circle {
  background-color: rgb(77, 131, 225);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.75rem;
}

.contact-selection {
  padding: 22px;
  border: 1px solid #f0f1f2;
  border-radius: 4px;
  background-color: #ffffff;
}
.dark-mode{
  .contact-selection {
    background-color: transparent;
  }
}
.preview-header {
  padding: 15px 22px;
  @include flexAlignCenter(flex);
  border-bottom: 1px solid #f0f1f2;
  h4 {
    margin: 0px;
    font-weight: 400;
    font-size: 16px;
    color: #33475b;
  }
  .btn.btn-primary {
    font-size: 14px;
    padding: 5px 10px;
    min-width: 90px;
    min-height: 35px;
    height: auto;
    &:last-child {
      margin-left: 10px;
    }
  }
  .btn.btn-secondary {
    min-width: 90px;
    min-height: 35px;
    height: auto;
  }
}

.subject-input {
  width: 100%;
  margin-top: 10px;
  .form-group {
    width: 100%;
  }
  .form-group-heading {
    display: none;
  }
}

.save-step-listing {
  @include flexExactCenter(flex);
  margin-top: 16px;
  button {
    background: #33475b;
    border-radius: 4px;
  }
}

.steps-tags-template {
  background: cornsilk;
  border-radius: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
  margin-bottom: 15px;
  color: goldenrod;
}

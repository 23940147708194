@import "../../../styles/variables";
@import "../../../styles/mixins";

.chatgpt-icon-btn {
  position: fixed;
  right: 0;
  top: 100px;
  z-index: 1011;
  background-color: #10a37f;
  width: 35px;
  height: 40px;
  padding: 5px 2px 5px 8px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  border: none;
}


.notification-warp {
  position: fixed;
  right: 0;
  // top: 118px;
  top: 150px;
  width: 100%;
  max-width: 560px;
  display: flex;
  justify-content: flex-end;
  transform: translateX(0);
  z-index: 1011;
  transition: all 0.5s;

  &.closed {
    transform: translateX(92%);
  }

  @media only screen and (max-width: 600px) {
    &.closed {
      transform: translateX(88%);
    }
  }
}

.notification-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .cursor-pointer {
    cursor: pointer;
  }
}

.opacity-class {
  opacity: 0.2;
}


.notification-icon {
  background-color: #EAA827;
  width: 50px;
  height: 45px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  position: relative;
}

.notification-icon-new {
  background-color: #EAA827;
  width: 60px;
  height: 45px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  position: relative;
  padding-left: 5px;
}

.notification-sign {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #BE3740;
  right: 15px;
  top: 13px;
  border-radius: 50%;
}

.notification-sign-new {
  position: absolute;
  background-color: #BE3740;
  right: 16px;
  top: 3px;
  min-width: 24px;
  min-height: 21px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

  &.badge {
    font-size: 0.56em;
    border-radius: 50%;
    display: flex;
  }
}


.notification-body {
  flex: 1;
  background: #FFFFFF;
  box-shadow: -4px 4px 18px rgba(0, 0, 0, 0.2);
  padding: 20px 30px 10px;
}

.notification-list-wrap {
  background-color: $WHITE_THREE;
  padding: 10px;
}

.text-grey {
  color: #686E78;
}

.notification-count {
  width: 60px;
  height: 60px;
  background-color: #FFFFFF;
  color: #006C8C;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}


.notification-action {
  .anchor-link {
    background-color: #EAA827 !important;
    border-color: #EAA827;
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;

    &>svg {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &:hover {
      background-color: #bd8b28 !important;
      border-color: #bd8b28 !important;
    }
  }
}

.notification-text {
  &>h6 {
    margin-bottom: -1px !important;
    line-height: normal !important;
  }

  &>span {
    font-size: 14px;
  }
}

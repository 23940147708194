@import "../../../styles/variables";
@import "../../../styles/mixins";

.sequence-tab {
  .user-basic-form {
    padding: 22px;
  }

  .tab-pane {
    padding-top: 32px;
  }

  .nav-tabs {
    border-bottom: 1px solid $WHITE_TEN;
    padding: 0px;
    background: transparent;
    box-shadow: none;

    @include responsive(360px) {
      padding: 0px 15px;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1408px) {
      padding: 0px;
    }

    .nav-link {
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      color: $WHITE_NINE;
      border: 0px;
      border-bottom: 4px solid transparent;
      margin-right: 24px;
      padding: 20px 15px;

      &:last-child {
        margin-right: 0px;
      }

      @media only screen and (min-width: 1200px) and (max-width: 1408px) {
        font-size: 14px;
      }

      @include responsive(360px) {
        margin-right: 10px;
        padding: 10px 5px;
      }

      &:hover {
        border-color: transparent;
      }

      &.active {
        color: $PRIMARY_BUTTON_BACKGROUND;
        border-bottom: 4px solid $PRIMARY_BUTTON_BACKGROUND;
        background-color: transparent;
      }
    }
  }

  .notes-flex {
    @include flexAlignCenter(flex);
    border-bottom: 1px solid $WHITE_TEN;
    margin-bottom: 12px;
    padding-bottom: 12px;

    &:last-child {
      border-bottom: 0px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}

.message-queue {
  div {
    width: 788px;
    margin-top: 15px;
    margin-bottom: 15px;
    @include flexAlignCenter(flex);

    @include responsive(1200px) {
      width: 100%;
      flex-wrap: wrap;
    }

    @include responsive(767px) {
      width: 100%;
      flex-direction: column;
    }

    div {
      width: 99px;
      height: 86px;
      background: #ffffff;
      border: 1px solid #f0f1f2;
      @include flexExactCenter(flex);
      flex-direction: column;

      @include responsive(1199px) {
        width: 50%;
      }

      @include responsive(991px) {
        width: 100%;
        flex-direction: column;
        margin: 0px;
      }

      label {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #33475b;
      }

      h5 {
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #33475b;
      }

      &:nth-child(even) {
        background: #f0f1f2;
      }
    }
  }

  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $PRIMARY_TEXT_COLOR;
    opacity: 0.6;
    margin: 0px;
  }
}

.launch-pause-paragraph {
  color: rgb(122, 133, 159);
  font: 300 15px soleil, sans-serif;
  padding-top: 0px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.sequence-name {
  margin-top: 10px;
}

.sequence-name-suggestions {
  color: #8b94ab;
  font-size: 14px;
}

.filterSequence {
  max-width: 307px;
}

.sequenceSwitch {
  .slider:before {
    background-color: #308d3f;
  }

  input:checked + .slider:before {
    background-color: #308d3f;
  }

  .slider {
    background: #eaffed;
  }
}

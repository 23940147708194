@import '../../styles/variables';
@import '../../styles/mixins';

.filter-by button {
  // background-color: $WHITE;
  // color: $BLACK_THREE;
  // margin-left: 1rem;
  // border: 1px solid $WHITE_BETA;
  // padding: 6px 12px 6px 12px !important;
  // border-radius: 4px;
}

.sticky-action-container {
  display: flex;
  justify-content: flex-start;
  border: solid $GRAY_TWELVE;
  border-width: 0.1px 0 0.1px 0;
  background-color: $WHITE;
  z-index: 99;
  position: sticky;
  top: -22px;
  align-items: center;

  &.sticky-extra {
    z-index: 0;
  }
  @include responsive(1199px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-group {
    @include responsive(1199px) {
      margin-left: 0px !important;
    }
  }

  .save-changes.ml-3 {
    @include responsive(1199px) {
      margin-left: 0px !important;
    }
  }

  .sticky-action-btns {
    display: flex;
    margin: 14px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    @include responsive(767px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    @include responsive(1199px) {
      margin-left: 0px;
    }

    .btn.btn-secondary {
      @include responsive(767px) {
        height: 40px;
        margin: 10px 0px 0px 0px !important;
      }
    }

    .disabled-btn {
      width: max-content;
      border: none;
      &:hover {
        cursor: not-allowed;
      }
    }
    a {
      width: max-content;
      white-space: nowrap;
    }
  }
  .form-group-heading {
    display: none;
  }
  .form-group {
    margin: 5px 14px;
  }
  .d-selected-num {
    padding: 14px;
    font-size: 14px;
    border-right: 1px solid $GRAY_TWELVE;
    min-width: max-content;

    @include responsive(1199px) {
      border-right: 0px;
    }
  }
  @media only screen and (max-width: 1199px) {
    width: calc(100% - 0px);
    top: -60px;
    padding: 15px;
  }
}

// revamp

.hotBadge {
  width: 34px;
  height: 16px;
  background: #ea5627;
  border-radius: 30px;
  font-weight: 500;
  font-size: 10px;
  color: #ffffff;
  margin-left: 8px;
}

.companyName {
  .companyNameText {
    font-weight: 400;
    font-size: 14px;
    color: #0e79b6;
  }
}

.jobOrderRevamp {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 24px;

  @include responsive(767px) {
    padding: 15px !important;
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
    color: #272727;
    margin-bottom: 16px;

    @include responsive(1199px) {
      margin-bottom: 15px !important;
    }
  }

  label {
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #686e78 !important;
    margin: 0px;
    width: 35%;
    @include responsive(1279px) {
      width: 100%;
    }
  }

  .contact-list-ul {
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .contact-list-li {
      font-weight: 500;
      font-size: 12px;
      color: #424242;
      margin: 0px;
      width: auto;
    }
  }

  h6 {
    font-weight: 500;
    font-size: 12px;
    color: #424242;
    margin: 0px;
    width: 65%;

    @include responsive(1279px) {
      width: 100%;
    }
  }

  .labelTextGrid {
    width: 65%;

    @include responsive(1279px) {
      width: 100%;
    }
  }

  a,
  .btnLinkColor {
    font-weight: 500;
    font-size: 12px;
    color: #0e79b6;

    &:hover,
    &:focus {
      color: #0e79b6;
    }
  }
}

.summarySectionJob {
  margin: 17px 0px 30px;

  h4 {
    color: #424242;
    font-size: 16px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    color: #686e78;
    margin: 0px;
  }

  span {
    color: #316046;
    font-weight: 600;
  }

  a {
    font-weight: 500;
    font-size: 12px;
    color: #0e79b6;
    margin-left: 5px;

    img {
      margin-left: 6px;
    }
  }
}

.labelTextSpacing {
  margin-bottom: 12px;

  @include responsive(1279px) {
    flex-direction: column;
  }
}

.sectionDivider {
  @include responsive(1279px) {
    margin-top: 30px;
  }
}

.advertiseColumn {
  @include responsive(1279px) {
    margin-top: 40px;
  }
}

.jobOrdersActionLog {
  background: #ffffff;
  border-radius: 4px;
}

.nav-tabs.navTabsOverflow {
  border-bottom: 1px solid #e5e7eb;
  padding: 0px 43px;

  @include responsive(1199px) {
    padding: 0px 15px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    border: 0px;
  }

  a {
    font-weight: 500;
    font-size: 14px;
    color: #6b7280;
    padding: 15px 0px;
    margin-right: 40px;
    border-bottom: 2px solid transparent;

    @include responsive(1199px) {
      margin-right: 15px;
      white-space: nowrap;
    }

    &:hover {
      border-color: transparent;
    }

    &.active,
    &:hover {
      color: #1f2937;
      border-color: transparent;
      border-bottom: 2px solid #1f2937;
      background-color: transparent;
    }
  }
}

.jobOrdersCandidateNotes {
  .textDotDivider {
    width: 5px !important;
    height: 5px;
    border-radius: 50%;
    margin: 0px 10px;
    background-color: #9ba1a8;
  }
}

.nameText {
  color: #9ba1a8;
}

.actionLogTableRecamp {
  padding: 24px;
}

.jobApplicantSkills {
  .skillBadgeApplicant {
    font-weight: 400;
    font-size: 9px;
    color: #272727 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px !important;
    height: 24px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    text-transform: capitalize;
    min-width: 71px;
    margin: 0px 8px 0px 0px;

    &:hover {
      border: 1px solid #f9b220;
    }
  }

  .skillBadgeApplicantWidth {
    min-width: 32px;
  }
}

.customMenuDropdown {
  .dropdown-item {
    margin: 10px 0px;
    font-weight: 400;
    font-size: 12px;
    color: #272727 !important;
    transition: 0.6s;
    border-radius: 0px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:hover {
      background-color: #e4e4e4 !important;
    }

    & a {
      color: #272727 !important;
    }

    & img {
      margin-right: 12px;
    }

    & a,
    & button {
      color: #272727;
      font-size: 12px;
    }
  }

  .dropdown-menu {
    padding: 0px;
    background: #ffffff;
    border: 1px solid #e5e9f8;
    border-radius: 4px;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  }
}

.menuButton {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px !important;
  background-color: transparent !important;
  border-radius: 50% !important;
  padding: 0px !important;

  & img {
    margin: 0px !important;
  }

  &:hover {
    background-color: #f7f8f9 !important;
  }
}

.dividerDot {
  width: 6px;
  height: 6px;
  margin: 0px 8px;
  border-radius: 50%;
  background-color: #eaa827;
}

.newApplicationText {
  color: #eaa827;
}

.applicantDetail {
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  background: #ffffff;
  width: 100%;

  @include responsive(1279px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .divider {
    width: 1px;
    height: 16px;
    margin: 0px 16px;
    background-color: #e5e5e5;

    @include responsive(1279px) {
      display: none;
    }
  }
}

.infoCardDivider {
  @include responsive(1199px) {
    display: flex;
    margin-bottom: 15px;
  }

  label,
  a {
    font-weight: 400;
    font-size: 14px;
    color: #686e78;
    margin: 0px;
    margin-left: 12px;

    @include responsive(1440px) {
      font-size: 12px;
    }
  }
}

.secondaryHeading {
  font-weight: bold;
  font-size: 14px;
  color: #272727;
  margin-bottom: 27px;
}

.text-capitalize.badge.badge-success {
  background: #d1fae5;
  color: #065f46;
}

.tableCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;

  .form-check-input {
    position: initial;
    margin-left: 0px;
  }
}

.statusBulkActions select.form-control {
  width: 200px;
  margin-right: 15px;
}

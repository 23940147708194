@import "../../../styles/variables";
@import "../../../styles/mixins";

.stats-card {
  width: 33.33%;
  padding: 24px 20px;
  background-color: $WHITE;
  flex-direction: column;
  border-bottom: 1px solid $WHITE_TEN;
  border-right: 1px solid $WHITE_TEN;
  @include flexExactCenter(flex);
  min-height: 160px;

  @include responsive(991px) {
    width: 100%;
  }

  h2 {
    font-weight: 800;
    font-size: 36px;
    line-height: 54px;
  }

  label {
    opacity: 0.6;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    color: $PRIMARY_TEXT_COLOR;
  }
}

.common-stats-card {
  width: 50%;
  padding: 24px 20px;
  background-color: $WHITE;
  flex-direction: column;
  border-bottom: 1px solid $WHITE_TEN;
  border-left: 1px solid $WHITE_TEN;
  @include flexExactCenter(flex);
  min-height: 160px;

  @include responsive(991px) {
    width: 100%;
  }

  h2 {
    font-weight: 800;
    font-size: 36px;
    line-height: 54px;
  }

  label {
    opacity: 0.6;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $PRIMARY_TEXT_COLOR;
  }
}

@import "../../styles/variables";
@import "../../styles/mixins";

.wrapper {
  display: flex;
  background-color: $THEME_COLOR;
  min-height: 100vh;
  padding: 16px 0px 16px 16px;

  @include responsive(1024px) {
    padding: 0px;
  }
}

.main-container {
  position: relative;
  width: 100%;
  padding: 0px 32px 50px;
  height: 92vh;
  // min-height: 92vh;
  overflow-y: auto;

  @include responsive(767px) {
    padding: 60px 15px 60px !important;
  }

  @include responsive(1024px) {
    padding: 60px 20px 22px;
  }

  .alert-primary {
    &.d-flex {
      @include responsive(1199px) {
        flex-wrap: wrap;
      }
    }
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 24px;
  }

  h4 {
    margin: 0px;
    font-family: $INTER_FONT;
    font-weight: bold;
    font-size: 24px;
    color: #272727;
  }
}

.user-avatar {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  margin-right: 24px;
  overflow: hidden;
  background-color: $WHITE_THREE;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 75%;
    height: 75%;
    object-fit: contain;
  }

  &.m-3 {
    @include responsive(414px) {
      margin-left: 0px !important;
    }
  }
}

.admin-tabs {
  .user-header {
    margin-bottom: 10px;
    @include flexAlignCenter(flex);

    h4 {
      margin-bottom: 0px;
      font-weight: 400;
    }
  }
}

.users-card {
  width: 100%;
  min-height: 100px;
  padding: 20px 15px;
  border: 1px solid $WHITE_TEN;
  border-radius: 4px;
  background-color: $WHITE;
  margin-bottom: 15px;

  @include responsive(1279px) {
    padding: 15px 10px;
  }

  .user-avatar {
    margin: 0px auto;
  }

  .d-flex {
    @include flexAlignCenter(flex);
    margin-bottom: 16px;
  }

  a {
    color: $BLUE;
    font-size: 15px;

    @include responsive(1600px) {
      font-size: 11px;
    }
  }

  h2 {
    font-weight: 800;

    @include responsive(1600px) {
      font-size: 11px;
    }
  }

  h5 {
    margin: 0px;
    font-size: 15px;

    @include responsive(1600px) {
      font-size: 11px;
    }

    span {
      @include responsive(1600px) {
        font-size: 11px;
      }
    }
  }
}

.spinner-border-sm {
  margin-left: 10px;
}

@import '../../../styles/variables';
@import '../../../styles/mixins';

.btn-next {
  color: #fff;
  background-color: $PRIMARY_BUTTON_BACKGROUND !important;
  border: 1px solid $PRIMARY_BUTTON_BACKGROUND !important;
}

.sidedrawer-shadow {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.1;
  display: none;
  &.show {
    display: block;
  }
}

.sidedrawer-content {
  position: fixed;
  min-width: 566px;
  max-width: 566px;
  background-color: #ffff;
  top: 0;
  right: 0;
  height: 100vh;
  overflow-y: auto;
  z-index: 1041;
  padding: 32px 34px;
  transform: translateX(100%);
  transition: transform 0.5s;
  &.open {
    transform: translateX(0%);
  }
}

@media only screen and (max-width: 600px) {
  .sidedrawer-content {
    width: 100%;
    min-width: auto !important;
  }
}

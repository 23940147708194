@mixin responsive($size) {
  @media only screen and (max-width: $size) {
    @content;
  }
}

@mixin responsiveMobile($size) {
  @media only screen and (min-width: $size) {
    @content;
  }
}

@mixin flexExactCenter($flex) {
  display: $flex;
  align-items: center;
  justify-content: center;
}

@mixin flexAlignCenter($flex) {
  display: $flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flexAlignItemCenter($flex) {
  display: $flex;
  align-items: center;
}

@mixin flexContentCenter($flex) {
  display: $flex;
  justify-content: center;
}

@mixin fullHeightAbsolute() {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

@mixin circle($heightWidth, $background) {
  height: $heightWidth;
  width: $heightWidth;
  background: $background;
  border-radius: 50%;
}

@mixin ellipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

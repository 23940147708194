.client-calendar-invite-modal-body {
  height: 812px !important;
  overflow-x: hidden;
}

.client-calendar-invite-modal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.client-calendar-showmore {
  margin-left: 10px;
  cursor: pointer;
  color: #f3b11e;
}

.add-salary-pharase {
  font-size: 12px;
}

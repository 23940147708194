.candidate-calendar-invite-modal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.candidate-calendar-showmore {
  margin-left: 10px;
  cursor: pointer;
  color: #f3b11e;
}

.buttonHeight {
  height: 33px !important;
}

@import "../../../styles/variables";
@import "../../../styles/mixins";

.alert-danger-card {
  .alert {
    padding: 1.2rem 2.3rem;
    @include flexAlignCenter(flex);
    margin-bottom: 32px;
  }

  .alert-danger {
    color: $WHITE;
    background-color: $RED_FOUR;
    border-color: $RED_FIVE;
    border-radius: 0px;
  }

  .card-text {
    @include flexAlignCenter(flex);

    h6,
    label {
      margin: 0px;
      color: $RED_FIVE;
    }

    label {
      font-size: 14px;
    }
  }

  .btn {
    img {
      margin: 0px;
    }
  }
}

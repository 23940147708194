@import "../../../styles/variables";
@import "../../../styles/mixins";

.collapse-wrapper {
  padding-top: 32px;

  h5 {
    margin: 0px;
  }
}

.collapse-header {
  background: $WHITE;
  padding: 15px 20px;
  @include flexAlignCenter(flex);
  font-weight: bold;
  border: 1px solid #f0f1f2;
  border-radius: 4px 4px 0px 0px;

  img {
    width: 15px;
  }
}

.collapse {
  border: 1px solid #f0f1f2;
  border-top: 0px;
}

@import '../../styles/mixins';
@import '../../styles/variables';

.create-agreement {
  label {
    font-size: 14px;
    color: $PRIMARY_TEXT_COLOR;
  }
}

.email-body {
  min-height: 400px;
}

.subTextSpacing {
  margin-left: 24px;
  font-weight: 500;
  font-size: 14px;
  color: $PRIMARY_TEXT_COLOR;
  line-height: 19.2px;

  @include responsive(767px) {
    margin: 0px;
  }
}

.forMobileColumn {
  align-items: center;

  @include responsive(767px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0px !important;
  }
}

.escalation-protocol {
  @include flexAlignCenter(flex);
  background: $WHITE_LIGHT_ONE;
  border: 1px solid $WHITE_NINE;
  padding: 10px 32px;
  min-height: 60px;

  @include responsive(767px) {
    flex-wrap: wrap;
    padding: 15px;
  }

  .d-flex {
    @include responsive(767px) {
      margin-top: 15px;
    }
  }

  h5 {
    margin: 0px;
    font-size: 16px;
    color: $PRIMARY_TEXT_COLOR;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    color: $PRIMARY_TEXT_COLOR;
    margin: 0px;
  }
  .btn-danger {
    background: $RED_THREE;
    border-color: $RED_THREE;
  }
  .btn-success {
    background: $GREEN_ONE;
    border-color: $GREEN_ONE;
  }
  .btn-secondary {
    background-color: $PRIMARY_TEXT_COLOR;
    border-color: $PRIMARY_TEXT_COLOR;
  }
  .btn {
    text-transform: capitalize;
  }
}

.view-company {
  margin-bottom: 32px;
  @include flexAlignCenter(flex);
  flex-wrap: wrap;
  h4 {
    margin: 0px;
  }
  .badge {
    font-weight: 500;
    font-size: 14px;
    margin-left: 16px;
    min-width: 150px;
    padding: 9px;
    border-radius: 30px;
    background: transparent;

    @include responsive(767px) {
      margin: 10px 0px 0px;
    }
  }
  .badge-secondary {
    border: 2px solid $GREY_TEN;
    color: $GREY_TEN;
  }
  .badge-success {
    border: 2px solid $GREEN_ONE;
    color: $GREEN_ONE;
  }
  .badge-danger {
    border: 2px solid $RED_THREE;
    color: $RED_THREE;
  }
  .badge-warning {
    border: 2px solid $PRIMARY_BUTTON_BACKGROUND;
    color: $PRIMARY_BUTTON_BACKGROUND;
  }
  .badge-primary {
    color: $WHITE_NINE;
    border: 2px solid $WHITE_NINE;
  }
  .badge-info {
    border: 2px solid $BLUE_TEN;
    color: $BLUE_TEN;
  }
  .badge-dark {
    border: 2px solid $BLACK;
    color: $BLACK;
  }
}

.pt-no-label {
  padding-top: 26px;

  @include responsive(767px) {
    padding: 0px 0px 20px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding-bottom: 20px;
  }
}

.pt-no-label-agreement {
  padding-top: 20px;

  @include responsive(767px) {
    // padding: 0px 0px 20px;
    top: 0px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding-bottom: 20px;
  }
}

.pt-no-label-contact {
  padding-top: 25px;
  @include responsive(767px) {
    padding: 0px 0px 20px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding-bottom: 20px;
  }
}

.approval-content {
  p {
    font-size: 14px;
    color: $PRIMARY_TEXT_COLOR;
    margin-bottom: 16px;
  }
}

.disabled-link {
  pointer-events: none;
}

#agreementModal.modal-dialog,
#companySummaryModal.modal-dialog {
  max-width: 750px;
  margin: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

#agreementModal .modal-body {
  height: 400px !important;
  overflow-y: auto;
}

.nick-name {
  .form-group {
    margin: 0px;
  }
}

.agreement-download.btn-secondary {
  background-color: transparent !important;
  border: 0;
  margin-left: 10px;
  font-size: 12px;
  color: $PRIMARY_BUTTON_BACKGROUND;
  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    color: $PRIMARY_BUTTON_BACKGROUND;
  }
}

.agreementDownload {
  min-width: 102px;
  height: 31px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  color: #424242;

  &:disabled {
    cursor: not-allowed !important;
  }

  & img {
    margin-left: 7px;
  }
}

.agreementLabel {
  label {
    width: 100% !important;
  }
}

.addAgreementFlex {
  @include responsive(1150px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
#companySummaryModal pre {
  white-space: break-spaces;
  font-weight: 500;
  font-size: 12px;
  color: #424242;
  margin: 0px;
  font-family: 'Inter', sans-serif;
}
